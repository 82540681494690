/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavItem } from '../../models/nav-item';
import { SidebarService } from '../../services/sidebar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import lod from 'lodash';
import { Router } from '@angular/router';
import { SidebarHttpService } from '../../services/sidebar-http.service';

@UntilDestroy()
@Component({
  selector: 'fc-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})

export class MenuListComponent implements OnInit, OnChanges {
  @Input() menuItems: NavItem[];
  @Input() bookmarksItem: NavItem;
  menuItemsOrigin: NavItem[] = [];
  searchString = '';
  isSearchActive = false;
  expandedItem: number;
  selectedId: string;
  editMode: boolean;
  bookmarksItemOrigin: NavItem;
  constructor(public sidebarService: SidebarService,
              private router: Router,
              private sidebarHttpService: SidebarHttpService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit() {

    this.bookmarksItemOrigin = lod.cloneDeep(this.bookmarksItem);

    const url = this.router.routerState.snapshot.url;
    let link = url.split('/')[1];
    if (link !== 'wcf?name=general-view-aui-wrapper&viewId=system:administration_home.159') {
      let id = '';
      let filteredNavItems = [];
      if (url.includes('wcf?')) {
        filteredNavItems = this.filterItemsByLink(link, this.menuItemsOrigin);
        id = filteredNavItems[0]?.hasOwnProperty('id') ? filteredNavItems[0].id : '';
      } else {
          link = link.includes('-aui-') ? link.replace('-aui-',':') : link;          
          id = link;
          filteredNavItems = this.filterItemsById(id, this.menuItemsOrigin);
      }
      this.selectedId = id;
      sessionStorage.setItem('selectedItem', '' + this.selectedId);

    }
    this.sidebarService.currentSelectedId.pipe(untilDestroyed(this)).subscribe((itemId: string) => {
       this.selectedId = itemId;
       sessionStorage.setItem('selectedItem', '' + this.selectedId);

    });

    this.sidebarService.editMode$.pipe(untilDestroyed(this)).subscribe((mode: boolean) => {
      this.editMode = mode;
    });

    this.checkAuiBookmarks();

    this.sidebarService.bookmarkAdded.pipe(untilDestroyed(this)).subscribe((added) => {
      if (added) {
        this.checkAuiBookmarks();
      }
    });

    this.sidebarService.deletedBookmark.pipe(untilDestroyed(this)).subscribe(id => {

      this.sidebarService.bookmarksItem$
      .pipe(untilDestroyed(this))
      .subscribe((items) => {
          if(!this.bookmarksItem && items && items.children[0]){
              // after delete and add book mark action, only this subscriber get invoked so need to update book mark reference.
              this.bookmarksItem = items;
              this.updateReference();
          }else{
              this.bookmarksItem.children = this.bookmarksItem?.children?.filter(bookmark => bookmark.id !== id);
              //update only bookmark delete action performed
              if(items && this.bookmarksItem.children.length !== items.children.length)
                  this.updateReference();
          }
        })
      this.ref.detectChanges();
    });

  }

  updateReference(){
    this.sidebarService.updateBooksmarksItem(this.bookmarksItem);
    this.ref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.menuItems) {
      this.menuItems.forEach(val => this.menuItemsOrigin.push(Object.assign({}, val)));
    }
  }

  checkAuiBookmarks() {
    this.sidebarHttpService.getBookmarks().pipe(untilDestroyed(this)).subscribe(bookmarks => {
      bookmarks.forEach(bookmark => {
        this.bookmarksItem?.children.forEach(bookmarkItem => {
          if (bookmarkItem.id === bookmark.id) {
            bookmarkItem.tooltip = '';
            bookmarkItem.source = 'aui';
          }
        });
      });
    });
  }

  public resetMenuItems() {
    this.menuItems = lod.cloneDeep(this.menuItemsOrigin);
    this.bookmarksItem = lod.cloneDeep(this.bookmarksItemOrigin);
  }

  onSearchCancel() {
    this.searchString = '';
    this.resetMenuItems();
    this.isSearchActive = false;
  }

  onExpandEventTrigger($event) {
    this.expandedItem = $event;
  }

  filterItemsById(searchData, target, accum=[]) {
    target.forEach((f)=>{
      if(f.children){
        this.filterItemsById(searchData, f.children, accum);
      }
        if(f.id.includes(searchData)){
          accum.push(f);
       }
    });
    return accum;
  }

  filterItemsByLink(searchData, target, accum=[]) {
    target.forEach((f)=>{
      if(f.children){
        this.filterItemsByLink(searchData, f.children, accum);
      }
      if(f.link === searchData){
        accum.push(f);
      }
    });
    return accum;
  }

  public onSidebarSearch() {

    this.isSearchActive = true;
    // For every search, we will reset the array to it's original state
    this.resetMenuItems();

    if (this.searchString?.length > 0) {
      const filtered = [];

      this.menuItems.forEach(item => {

        const children = item.children;

        // Step 1 - First check if the "main" item match the search input
        if (this.isSearchInputMatch(item, this.searchString)) {
          filtered.push(item);
        }

        // Step 2 - Traverse item's children to find matched sub-items
        if (children && children.length > 0) {

          const indexesToRemove = [];
          for (let i = 0; i < children.length; i++) {

            const isItemChildMatch = this.findChildrenMatches(children[i], this.searchString);

            // In case we got true for child,
            // we will mark the item as expanded and push it (in case it's not exist already in the filtered list)
            if (isItemChildMatch) {
              item.isExpanded = true;
              if (!this.containsObject(item, filtered)) {
                filtered.push(item);
              }
            } else {
              indexesToRemove.push(i);
            }
          }

          if (indexesToRemove.length > 0) {
            for (let j = (indexesToRemove.length - 1); j >= 0; j--) {
              item.children.splice(indexesToRemove[j], 1);
            }
          }
        }
      });


      let filteredBookmarks: NavItem[] = [];
      this.bookmarksItem?.children.forEach(bookmark => {
        if (this.isSearchInputMatch(bookmark, this.searchString)) {
          filteredBookmarks.push(bookmark);
        }
      })

      if (filteredBookmarks.length > 0) {
        this.bookmarksItem.children = filteredBookmarks;
        this.bookmarksItem.isExpanded = true;
      } else {
        this.bookmarksItem ? this.bookmarksItem.children = [] : '';
      }


      this.menuItems = filtered;
    } else {
      this.isSearchActive = false;
  }
  }

  // This function traverse nav-item recursively since each item has different depth
  private findChildrenMatches(navItem: NavItem, searchInput: string) {

    // markedAsExpanded changes to true in case where there at least one child (or child of child and so on) that matches the search input
    let markedAsExpanded = false;
    let hasChildrenMatch = false;
    // Stop condition - when there are no children
    if (navItem && (navItem.children == null || navItem.children.length === 0)) {
      return this.isSearchInputMatch(navItem, searchInput);
    }

    const indexesToRemove = [];
    for (let i = 0; i < navItem.children.length; i++) {

      const c = navItem.children[i];

      hasChildrenMatch = this.findChildrenMatches(c, searchInput);

      if (hasChildrenMatch) {
        markedAsExpanded = true;
        navItem.isExpanded = true;
      } else {
        indexesToRemove.push(i);
      }
    }

    if (indexesToRemove.length > 0) {
      for (let j = (indexesToRemove.length - 1); j >= 0; j--) {
        navItem.children.splice(indexesToRemove[j], 1);
      }
    }

    return this.isSearchInputMatch(navItem, searchInput) || markedAsExpanded;
  }

  private isSearchInputMatch(item: NavItem, searchInput: string): boolean {
    return item.displayName.toLowerCase().includes(searchInput.toLowerCase()) && (item.visible || (!item.visible && this.editMode));
  }

  // Checks if object is exist in the list\array
  private containsObject(obj, list): boolean {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }
    return false;
  }
}
