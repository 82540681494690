<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->
<div style="width: 100%" [ngClass]="theme">
  <div class="subscription-pending-container">
    <div class="header">
      <div class="header-title">Subscription is missing</div>
      <div class="header-info">
        We're sorry, but in order to see
        <span class="wt-700">{{ monitorDomain }} </span> you need to activate
        <span class="wt-700">Foglight Cloud for {{ instanceType }}</span>
        subscription which is currently inactive.
      </div>
    </div>
    <div class="addition-details">
      <div class="row">
        <span class="addition-title">What does this mean?</span>
      </div>
      <div class="row" style="margin-top: 5px">
        <span class="addition-info"
          >When active, Foglight Cloud for {{ instanceType }} subscription
          offers a comprehensive array of advanced tools to elevate performance
          monitoring and cost optimization. Specifically tailored for hybrid
          environments.
        </span>
      </div>
    </div>
    <div class="contact">What can you do?</div>
    <div class="contact-details">
      <div class="active-subscription">
        <div class="contact-header">
          <div class="circle">1</div>
          <div class="active-label">Activate your Subscription</div>
        </div>
        <div class="row mr-8 active-detail">
          Consider activating Foglight Cloud for {{ instanceType }}
          subscription. This will allow you to monitor your infrastructure.
        </div>
        <div class="row mr-8">
          <a class="link-style" tabindex="0" (click)="goToAccountSettings()">
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span> Go to Account Settings</span>
          </a>
        </div>
      </div>
      <div class="contact-sale">
        <div class="contact-header">
          <div class="circle">2</div>
          <div class="active-label">Contact Support</div>
        </div>
        <div class="row mr-8 active-detail">
          If you believe there's been a mistake or if you need assistance, our
          support team is here to help. Feel free to reach out for personalized
          guidance.
        </div>
        <div class="row mr-8">
          <a class="link-style" tabindex="0" (click)="supportOnClick()">
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span> Contact Support</span>
          </a>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="close-btn">
        <fac-buttons
          [color]="'secondary'"
          [size]="'medium'"
          [buttonText]="'Close'"
          (click)="closeDialog()"
        ></fac-buttons>
      </div>
      <div class="account-btn">
        <fac-buttons
          [size]="'medium'"
          [buttonText]="'Go to Account Settings'"
          (click)="goToAccountSettings()"
        ></fac-buttons>
      </div>
    </div>
  </div>
</div>
