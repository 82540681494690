/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { Injectable } from '@angular/core';
import { ListDataRefresher } from '@foglight/angular-common';
import { Observable, of } from 'rxjs';
import { LegalNoticeItem } from './legal-notice.component';
import * as jsonData from './third-party-licenses.json';

@Injectable()
export class LegalNoticeListDataRefresherService extends ListDataRefresher<LegalNoticeItem[], LegalNoticeItem, string>{
  protected parsedData(data: LegalNoticeItem[]): LegalNoticeItem[] {
    return data ?? [];
  }
  protected sourceData(): Observable<LegalNoticeItem[]> {
    const aa = of(JSON.parse(JSON.stringify(jsonData)).default);
    // aa.subscribe(aaa => alert(aaa[0].componentName));

    return of(JSON.parse(JSON.stringify(jsonData)).default);
  }
  protected mockSourceData(): Observable<LegalNoticeItem[]> {
    return of([]);
  }

  protected get isMockData(): boolean {
    return false;
  }

  protected filterRowDataHandler(data: LegalNoticeItem, filters: string): boolean {
    if (!filters) {
      return true;
    }
    return data?.componentName?.toLowerCase()?.includes(filters?.toLowerCase()) ||
    data?.componentVersion?.toLowerCase()?.includes(filters?.toLowerCase()) ||
    data?.license?.toLowerCase()?.includes(filters?.toLowerCase());
  }

  constructor() {
    super();
  }
}
