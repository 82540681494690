/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers/index';
import permissions from '../../assets/json/permissions.json';

export const appSelector = createFeatureSelector<AppState>('appReducer');

export const serverInfoSelector = createSelector(
  appSelector,
  (state: AppState) => state?.serverInfo
);

export const isCloudFlagSelector = createSelector(
  appSelector,
  (state: AppState) => state?.serverInfo['isCloud'] === true
);

export const isSaasAcceptedSelector = createSelector(
  appSelector,
  (state: AppState) => state?.staacceptance
);

export const userInfo = createSelector(
  appSelector,
  (state: AppState) => state?.userInfo
);
export const subscriptionInfo = createSelector(
  appSelector,
  (state: AppState) => state?.subscriptionInfo
);

export const userInfoRoles = createSelector(
  appSelector,
  (state: AppState) => state?.userInfo.roles
);

export const isCloudUser = createSelector(userInfoRoles, (roles) => {
  return permissions['saas-acceptance'].some((role) => {
    if (roles && roles.includes(role)) {
      return true;
    }
  });
});

export const productInfo = createSelector(
  appSelector,
  (state: AppState) => state?.productCofig
);
