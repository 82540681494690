import { environment } from './environment';
import { ICON_REGISTRY_CONFIG } from '../app/icon-registry-config';

const assets = `${environment.angularBaseUrl}/assets`;
const images = `${assets}/images`;
const icons = `${images}/icons`;
export const pathForAssets = (path: string): string => {
  return `${assets}/${path}`;
};

export const pathForImages = (path: string): string => {
  return `${images}/${path}`;
};

export const pathForIcons = (path: string): string => {
  return `${icons}/${path}`;
};

export const isIconExisted = (iconName: string): boolean => {
  return ICON_REGISTRY_CONFIG.findIndex(it => it.name === iconName) >= 0;
};
