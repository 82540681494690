/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { FacNavbarService } from './navbar.service';
import { environment } from './../../../environments/environment';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  FacAuthService,
  FacDropdownComponent,
  FacDropDownSearchItem,
  FacDropDownSearchResponse,
  FacUserService,
} from '@foglight/angular-common';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, tap, map, switchMap } from 'rxjs/operators';
import { AboutDialogComponent } from './about-dialog/about-dialog.component';
import { Router } from '@angular/router';
import { getDomainIconName } from 'src/app/views/alarm-template/alarm-template-content/domain-mapping.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  isCloudFlagSelector,
  serverInfoSelector,
} from 'src/app/store/app.selectors';
import { SidebarService } from './../sidebar/services/sidebar.service';
import lod from 'lodash';
import { LayoutService } from '@services/layout.service';
import * as _ from 'lodash';
import { UtilService } from '@services/util.service';
import { AUIPages } from '../sidebar/models/nav-item';
import { MatMenuTrigger } from '@angular/material/menu';
import { Title } from '@angular/platform-browser';

@UntilDestroy()
@Component({
  selector: 'fc-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  userInfo$: Observable<any>;
  isUserInfoOpen = false;
  isActionMenuOpen = false;
  hoveringOverMSI = false;
  MSIIconHOver = true;
  EditMSI = false;
  serverNameExists = false;
  enterKeyPressed = false;
  MgmtServerIdFormControl = new FormControl('');
  startEditValue = '';
  userIsAdmin = false;
  showRegularUserTooltip = false;
  visible = false;
  isCloudFlag = false;
  isSaasAccepted = false;
  userIsAdminOrCloudOwner = false;
  cloudAppStatus = 'none-status';
  cloudAppDescription = '';

  // Global Search
  searchFormControl = new FormControl('');
  filteredResponse$: Observable<FacDropDownSearchResponse>;
  loading$ = new Subject<boolean>();
  serverNameExists$ = new BehaviorSubject<boolean>(false);
  isUserAnAdmin$ = new BehaviorSubject<boolean>(false);

  //Dropdown items
  userInfoItems;
  menuItemList: Array<any>;
  selectedItem: Object;
  headerMenuIcon: boolean = true;
  userLogoIcon: boolean = true;
  showLogo: boolean = false;
  defaultHover = false;
  isMatMenuOpen = false;
  enteredButton = false;
  prevButtonTrigger: any;
  statusTooltip = '';
  @Output() mouseEnterEmitter = new EventEmitter<HTMLElement>();
  @Output() mouseLeaveEmitter = new EventEmitter<HTMLElement>();
  @Output() displayOptionChange = new EventEmitter<string>();

  //Theme Variables
  lightTheme = 'light-theme';
  darkTheme = 'dark-theme';

  @ViewChild('menu') userMenu: FacDropdownComponent;
  @ViewChild('headerMenu') headerMenu: FacDropdownComponent;

  constructor(
    private elementRef: ElementRef,
    private authService: FacAuthService,
    private userService: FacUserService,
    private aboutDialog: MatDialog,
    private navbarService: FacNavbarService,
    private router: Router,
    private renderer: Renderer2,
    private store: Store,
    private sidebarService: SidebarService,
    private cdRef: ChangeDetectorRef,
    private layoutService: LayoutService,
    private utilService: UtilService,
    private titleService: Title
  ) {
    this.router.errorHandler = (error: Error) => {
      console.log('Error occur when navigate. ' + error.message);
    };
  }

  ngOnInit(): void {
    this.checkApplicationStatus();

    window.addEventListener('status-notification-event', () => {
      this.checkApplicationStatus();
    });

    this.navbarService.userSelectedTheme$.subscribe((theme) => {
      if (theme === 'light-theme') {
        this.statusTooltip = 'light-status-tooltip';
      } else {
        this.statusTooltip = 'dark-status-tooltip';
      }
    });

    this.userInfoItems = [
      { label: 'User Preferences', class: 'user_preferences_qa' },
      {
        label: 'Theme Selector',
        class: 'theme_selector_qa',
        showSelected: false,
        items: [
          { label: 'Light Theme', class: 'light_theme_qa' },
          { label: 'Dark Theme', class: 'dark_theme_qa' },
        ],
      },
      { label: 'Sign Out', class: 'sign_out_qa', icon: 'fac-trash' },
    ];

    this.menuItemList = [
      { label: 'Documentation', showSelected: false },
      { label: 'Contact Support', showSelected: false },
      { label: 'Ask Foglight Community', showSelected: false },
      { label: 'Send Feedback', showSelected: false },
      { label: 'About', showSelected: false },
    ];

    this.MgmtServerIdFormControl.disable();

    // Get server info from the store to check for init server instance name
    this.store
      .select(serverInfoSelector)
      .pipe(untilDestroyed(this))
      .subscribe((serverInfo): any => {
        if (
          serverInfo?.serverInstance?.name &&
          serverInfo?.serverInstance?.name != ''
        ) {
          this.serverNameExists$.next(true);
          this.MgmtServerIdFormControl.setValue(serverInfo.serverInstance.name);
          this.cdRef.markForCheck();
        }
      });

    this.store
      .select(isCloudFlagSelector)
      .pipe(untilDestroyed(this))
      .subscribe((isCloud) => {
        this.isCloudFlag = isCloud;
        if (this.isCloudFlag) {
          let index = this.userInfoItems.findIndex(
            (x) => x.label === 'User Preferences'
          );
          let menuItemIndex = this.menuItemList.findIndex(
            (x) => x.label === 'Contact Support'
          );
          this.userInfoItems.splice(index, 0, {
            label: 'Account Settings',
            class: '',
          });
          this.menuItemList.splice(menuItemIndex, 0, {
            label: "What's New",
            showSelected: false,
          });
        }
        this.cdRef.markForCheck();
      });

    this.layoutService.verifyCloudAndSaasAcceptance();
    this.layoutService.showSideBar
      .pipe(untilDestroyed(this))
      .subscribe((showNavbar) => {
        this.isSaasAccepted = showNavbar;
        this.cdRef.detectChanges();
      });

    if (this.userService) {
      this.userInfo$ = this.userService.getUserInfo();
    }

    if (this.userInfo$) {
      this.userInfo$.pipe(untilDestroyed(this)).subscribe((res) => {
        if (this.isCloudFlag) {
          this.userIsAdminOrCloudOwner =
            res.roles.includes('Foglight Cloud Owner') ||
            res.roles.includes('Foglight Cloud Administrator');
        } else {
          this.userIsAdminOrCloudOwner = res.roles.includes('Administrator');
        }
        this.isUserAnAdmin$.next(this.userIsAdminOrCloudOwner);
        this.userIsAdmin = this.userIsAdminOrCloudOwner;
        let currentTheme;
        if (res.userSettings?.themes.main) {
          currentTheme =
            res.userSettings.themes.main == 'application_default'
              ? this.lightTheme
              : this.darkTheme;
        } else {
          currentTheme = this.lightTheme;
          this.updateTheme(currentTheme);
        }
        localStorage.setItem('theme', currentTheme);

        const event = new CustomEvent('themeChanged', { detail: currentTheme });
        window.dispatchEvent(event);

        this.navbarService.userSelectedTheme$.next(currentTheme);
        this.setSelectedItem(currentTheme);
        this.userInfoItems.splice(0, 0, {
          label: 'Hi,' + ' ' + res.name + ' ! ',
          class: 'hi_user_qa',
          separator: true,
        });
      });
    }
    this.filteredResponse$ = this.searchFormControl.valueChanges.pipe(
      debounceTime(300),
      tap(() => this.loading$.next(true)),
      switchMap((value) => {
        if (value?.length === 0) {
          this.loading$.next(false);
          return of(new FacDropDownSearchResponse());
        }
        return this._getGlobalSearchResult(value);
      })
    );

    setTimeout(() => {
      this.showLogo = true;
      this.cdRef.markForCheck();
    }, 100);
  }

  checkApplicationStatus()
  {
    try {
      const status = sessionStorage.getItem('status');
      const description = sessionStorage.getItem('description');
  
      if (status && status !== 'none') {
        this.cloudAppStatus = `${status}-status`;
        this.cloudAppDescription = description;
      } else {
        this.cloudAppStatus = 'none-status';
        this.cloudAppDescription = 'All Systems Operational';
      }
  
      this.cdRef.detectChanges();
    }
    catch(e) {
      console.log(e);
    }
  }

  get element(): HTMLElement {
    return this.elementRef.nativeElement as HTMLElement;
  }

  buttonEnter(trigger: MatMenuTrigger) {
    this.defaultHover = true;
    this.mouseEnterEmitter.emit(this.element);
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        trigger.openMenu();
      } else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    }, 200);
  }

  buttonLeave(trigger: MatMenuTrigger) {
    this.defaultHover = false;
    this.mouseLeaveEmitter.emit(this.element);
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
      } else if (!this.isMatMenuOpen) {
        trigger.closeMenu();
      } else {
        this.enteredButton = false;
      }
    }, 500);
  }

  menuEnter(event: MouseEvent) {
    this.isMatMenuOpen = true;
    this.displayOptionChange.emit('');
  }

  menuLeave(trigger: MatMenuTrigger) {
    setTimeout(() => {
      if (!this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
      } else {
        this.isMatMenuOpen = false;
      }
    }, 300);
  }

  blurEventMethod(event) {
    if (!this.enterKeyPressed) {
      this.EditMSI = false;
      this.serverNameExists$
        .pipe(untilDestroyed(this))
        .subscribe((nameExists) => {
          !!nameExists
            ? this.MgmtServerIdFormControl.setValue(this.startEditValue)
            : this.MgmtServerIdFormControl.setValue('');

          const ctx = this.renderer.createElement('canvas').getContext('2d');
          const { fontStyle, fontVariant, fontWeight, fontSize, fontFamily } =
            document.getElementById('MSI-input').style;
          ctx.font =
            fontStyle +
            ' ' +
            fontVariant +
            ' ' +
            fontWeight +
            ' ' +
            fontSize +
            ' ' +
            fontFamily;
          document.getElementById('MSI-input').style.width =
            ctx!.measureText(this.startEditValue).width + 50 + 'px';

          document.getElementById('MSI-input').style.maxWidth = '220px';
          document.getElementById('MSI-input').blur();
        });
    } else {
      this.EditMSI = false;
      document.getElementById('MSI-input').style.maxWidth = '220px';
      this.serverNameExists$
        .pipe(untilDestroyed(this))
        .subscribe((nameExists) => {
          !!nameExists
            ? this.MgmtServerIdFormControl.setValue(this.startEditValue)
            : this.MgmtServerIdFormControl.setValue('');
        });
    }
  }

  focusEventMethod(event) {
    this.enterKeyPressed = false;
    this.startEditValue = this.MgmtServerIdFormControl.value;
    document.getElementById('MSI-input').style.maxWidth = 'none';
  }

  onMSIInputClickEditButton(input: string) {
    this.navbarService
      .updateServerInstanceName(input)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {});
    if (input !== '') {
      this.serverNameExists$.next(true);
    }
    this.EditMSI = true;
  }

  onMSIInputClickEnter(input: string) {
    this.enterKeyPressed = true;
    this.startEditValue = input;
    this.navbarService
      .updateServerInstanceName(input)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {});
    if (input !== '') {
      this.serverNameExists$.next(true);
    }
    this.EditMSI = false;
    document.getElementById('MSI-input').blur();
  }

  onDeleteMSIInput() {
    this.MgmtServerIdFormControl.setValue('');
    this.navbarService
      .updateServerInstanceName('')
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.serverNameExists$.next(false);
      });
    this.EditMSI = false;
    document.getElementById('MSI-input').blur();
  }

  focusOnMSIINPUT(num: Number) {
    if (num === 0) {
      this.EditMSI = !this.EditMSI;
    }
    setTimeout(() => {
      this.MgmtServerIdFormControl.enable();
      if (num === 1) {
        this.onMSIInputClickEditButton(this.MgmtServerIdFormControl.value);
        this.EditMSI = !this.EditMSI;
      }
      document.getElementById('MSI-input').focus();
      document.getElementById('MSI-input').click();
    }, 0);
  }

  onLoginClick() {
    this.authService.login();
  }

  onLogoutClick() {
    this.authService.logout();
    window.sessionStorage.removeItem('dates');
    window.sessionStorage.removeItem('search_value');
    window.sessionStorage.removeItem('page_limit');
    window.sessionStorage.removeItem('direction');
    window.sessionStorage.removeItem('lastIframeURLBeforeLogout');
    window.sessionStorage.removeItem('selectedItem');
    window.sessionStorage.removeItem('itemLink');
    window.sessionStorage.removeItem('breadcrumbHistory');
  }

  helpOnClick() {
    if (this.isCloudFlag) {
      window.open('https://docs.foglightcloud.com/');
    } else {
      window.open(
        `${environment.foglightBaseUrl}/console/page/help?_action=preSelect&id=NMDobL7CRZ0_435422`
      );
    }
  }

  supportOnClick() {
    if (this.isCloudFlag) {
      window.open('https://support.quest.com/foglight-cloud');
    } else
      window.open(
        `${environment.foglightBaseUrl}/console/page/system:administration_setupsupport_supportbundles.supportDashboard`
      );
  }

  whatsNewOnClick() {
    window.open(
      'https://docs.foglightcloud.com/gettingstartedfoglightcloud/whats-new/'
    );
  }

  feedbackOnClick() {
    window.open('https://www.quest.com/company/contact-us.aspx');
  }

  aboutOnClick() {
    const dialogRef = this.aboutDialog.open(AboutDialogComponent, {
      panelClass: 'about-dialog-custom-style',
    });
  }

  communityOnClick() {
    window.open('https://www.quest.com/community/foglight/');
  }

  userInfoOpen() {
    this.isUserInfoOpen = true;
  }

  userInfoClose() {
    this.isUserInfoOpen = false;
  }

  actionMenuOpen() {
    this.isActionMenuOpen = true;
  }

  actionMenuClose() {
    this.isActionMenuOpen = false;
  }

  resultSelected(item: FacDropDownSearchItem) {
    this.utilService.clearBreadCrumbHistory();
    this.router.navigateByUrl(item.viewPath);
  }

  private _getGlobalSearchResult(
    searchKey: string
  ): Observable<FacDropDownSearchResponse> {
    return this.navbarService.getGlobalSearchResult(searchKey).pipe(
      map((res: any) => {
        this.loading$.next(false);
        res.resultItems?.forEach((item) => {
          item.iconName = getDomainIconName(item.iconName);
        });
        return res;
      })
    );
  }
  getUserPreferencesLink() {
    let userPreferencesLink;
    this.sidebarService.configItems$
      .pipe(untilDestroyed(this))
      .subscribe((items) => {
        let data = lod.cloneDeep(items);
        userPreferencesLink = data.filter(
          (item) => item.displayName === 'User Preferences'
        );
      });
    return userPreferencesLink;
  }

  onItemSelect(event) {
    switch (event.label) {
      case 'Sign Out':
        this.onLogoutClick();
        break;

      case 'User Preferences':
        this.setSelectedItem(localStorage.getItem('theme'));
        let userPreferencesData = this.getUserPreferencesLink();
        this.router.navigateByUrl(userPreferencesData[0].link);
        break;

      case 'Light Theme':
        this.updateTheme(this.lightTheme);
        break;
      case 'Dark Theme':
        this.updateTheme(this.darkTheme);
        break;
      case 'Account Settings':
        this.loadAccountSettings();
        break;
      default:
        break;
    }
  }

  updateTheme(theme) {
    let fullPath: string = window.location.href;
    const AUIPagesWithTheme = AUIPages.some((page) => fullPath.includes(page));
    let selectedTheme =
      theme == this.lightTheme ? 'application_default' : 'monitoring_default';
    this.navbarService
      .setUserSelectedTheme(selectedTheme)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        localStorage.setItem('theme', theme);
        this.navbarService.userSelectedTheme$.next(theme);

        const event = new CustomEvent('themeChanged', { detail: theme });
        window.dispatchEvent(event);
        if (fullPath.includes('/wcf')) {
          const currentUrl = this.router.url;
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigateByUrl(currentUrl);
              this.cdRef.detectChanges();
            });
        }
      });
  }

  setSelectedItem(theme) {
    if (theme == this.lightTheme) {
      this.selectedItem = { label: 'Light Theme' };
    } else if (theme == this.darkTheme) {
      this.selectedItem = { label: 'Dark Theme' };
    }
  }

  onMenuItemSelect(event) {
    switch (event.label) {
      case 'Documentation':
        this.helpOnClick();
        break;
      case "What's New":
        this.whatsNewOnClick();
        break;
      case 'Contact Support':
        this.supportOnClick();
        break;
      case 'Send Feedback':
        this.feedbackOnClick();
        break;
      case 'About':
        this.aboutOnClick();
        break;
      case 'Ask Foglight Community':
        this.communityOnClick();
        break;
    }
  }

  moveToHomePage() {
    let homeLink ='home';    
    this.titleService.setTitle(`Welcome - Foglight`);
    this.router.navigateByUrl(homeLink);
  }

  onFocusOut(e, menuName) {
    if (e.relatedTarget == null) {
      let elementName =
        menuName == 'userMenu' ? this.userMenu : this.headerMenu;
      elementName.hide();
    }
    if (menuName == 'userMenu') {
      this.userLogoIcon = true;
    } else if (menuName == 'headerMenu') {
      this.headerMenuIcon = true;
    }
  }

  loadAccountSettings() {
    this.router.navigate(['/settings/account-settings']);
  }
}
