<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->

<div class="header">
  <div class="third-width">
    <ng-container *ngIf="showLogo">
      <fc-svg-icon
      class="logo"
      (click)="moveToHomePage()"
      [svgName]="isCloudFlag ? 'cloud_2' : 'branding_logo'"
    ></fc-svg-icon>
    </ng-container>

    <ng-container *ngIf="isSaasAccepted">
      <!-- show add icon -->
      <span *ngIf="!EditMSI && (serverNameExists$ | async) === false && (isUserAnAdmin$ | async)">
        <span
          (mouseover)="MSIIconHOver = false"
          (mouseout)="MSIIconHOver = true"
          tabindex="0"
        >
          <fc-svg-icon
            (click)="focusOnMSIINPUT(0)"
            *ngIf="MSIIconHOver"
            svgName="MSI-name"
            class="msi-input"
            [matTooltip]="'Add Management Server Identifier'"
            [matTooltipPosition]="'right'"
            matTooltipClass="fc-property-tooltip"
          ></fc-svg-icon>
          <fc-svg-icon
            (click)="focusOnMSIINPUT(0)"
            *ngIf="!MSIIconHOver"
            svgName="MSI-name-full"
            class="msi-input"
            [matTooltip]="'Add Management Server Identifier'"
            [matTooltipPosition]="'right'"
            matTooltipClass="fc-property-tooltip"
          ></fc-svg-icon>
        </span>
      </span>

      <!-- show name and if admin also editIcon -->
      <span
        class="mgmt-server-id"
      >
        <div class="vl" *ngIf="!!EditMSI || (serverNameExists$ | async)"></div>
        <div
          class="msi-input-container"
          (mouseover)="showRegularUserTooltip = true"
          (mouseout)="showRegularUserTooltip = false"
        >
          <input
            (click)="$event.target.select()"
            id="MSI-input"
            matInput
            fcAutoWidth
            class="msi-input-class"
            type="text"
            [formControl]="MgmtServerIdFormControl"
            disabled="true"
            placeholder="MSI name"
            maxlength="30"
            (focus)="focusEventMethod($event)"
            (keyup.enter)="onMSIInputClickEnter($event.target.value)"
            (keyup.escape)="blurEventMethod($event)"
            *ngIf="!!EditMSI || (serverNameExists$ | async)"
          />
          <span class="icons-wrapper" *ngIf="(!!EditMSI || (serverNameExists$ | async)) && (isUserAnAdmin$ | async)">
            <button
              mat-button
              class="msi-edit-icon"
              (click)="focusOnMSIINPUT(1)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-button
              class="msi-delete-icon"
              (click)="onDeleteMSIInput()"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </span>
        </div>
        <div
          *ngIf="!!showRegularUserTooltip && !userIsAdmin"
          class="tooltiping"
        >
          <span class="tootlip-1st-line"></span>Management Server Identifier
          &nbsp;<span class="tootlip-2nd-line"
            >Only administrator can change it</span
          >
        </div>
      </span>
    </ng-container>
  </div>
  <ng-container *ngIf="isSaasAccepted">
    <div class="global-search search-width">
      <fac-search-dropdown-input
        matAutocompleteOrigin
        [itemTemplate]="itemTmpl"
        (searchResultSelected)="resultSelected($event)"
        [filteredResponse$]="filteredResponse$"
        [loading$]="loading$"
        [formControl]="searchFormControl"
        width="100%"
        placeholder="Search"
      ></fac-search-dropdown-input>
      <ng-template #itemTmpl let-resultItem>
        <div class="global-search-dropdown-result-content-container">
          <div class="global-search-dropdown-result-content">
            <fc-icon
              class="global-search-dropdown-item-icon"
              [svgIcon]="resultItem.iconName | setDefaultsIcon"
            ></fc-icon>
            {{
              (resultItem.name | slice : 0 : 50) +
                (resultItem.name?.length > 50 ? "..." : "")
            }}
          </div>
          <div class="global-search-dropdown-result-type-name">
            {{ resultItem.typeDisplayName }}
          </div>
        </div>
      </ng-template>
    </div>
    <div layout="row" class="row-width">
      <ng-container *ngIf="this.userInfo$ | async as userInfo; else loginRef">
        <div class="user">
          <div *ngIf="isCloudFlag && cloudAppStatus !== 'none-status'">
            <fac-buttons
            [matMenuTriggerFor]="setDefault"
            #setDefaultTrigger="matMenuTrigger"
            (mouseenter)="buttonEnter(setDefaultTrigger)"
            (mouseleave)="buttonLeave(setDefaultTrigger)"
            [icon]="'fac-' + cloudAppStatus"
            [buttonText]="cloudAppDescription"
            [style]="{
              'border-radius':'16px',
              'padding': '0 8px',
              'font-size': '11px',
              'background-color': '#555555',
              'color': '#fff',
              'min-height': '22px'
            }"></fac-buttons>
          <mat-menu [hasBackdrop]="false" #setDefault="matMenu" xPosition="after" yPosition="below" [class]="statusTooltip">
            <div (mouseenter)="menuEnter($event)" (mouseleave)="menuLeave(setDefaultTrigger)">
              <div>{{cloudAppDescription}}</div>
              <a href="https://status.foglightcloud.com/" target="_blank">Go to System Status page</a>
            </div>
          </mat-menu>
          </div>
          <button
            mat-button
            (click)="menu.toggle($event)"
            (mouseenter)="userLogoIcon = false"
            (mouseleave)="userLogoIcon = true"
            (focus)="userLogoIcon = false"
            (focusout)="onFocusOut($event, 'userMenu')"
          >
            <fc-svg-icon
              [svgName]="userLogoIcon ? 'user-logo' : 'user-logo-full'"
              class="user-icon"
            ></fc-svg-icon>
            <fc-svg-icon
              *ngIf="isCloudFlag && cloudAppStatus !== 'none-status'"
              [svgName]="cloudAppStatus"
              class="application-status"
          ></fc-svg-icon>
          </button>
          <fac-dropdown
            #menu
            [popup]="true"
            [model]="userInfoItems"
            [selectedItem]="selectedItem"
            (onSelectedItem)="onItemSelect($event)"
          ></fac-dropdown>
        </div>
      </ng-container>
      <div class="header-menu-container">
        <button
          mat-button
          (click)="headerMenu.toggle($event)"
          (mouseover)="headerMenuIcon = false"
          (mouseout)="headerMenuIcon = true"
          (focus)="headerMenuIcon = false"
          (focusout)="onFocusOut($event, 'headerMenu')"
        >
          <fc-svg-icon
            [svgName]="headerMenuIcon ? 'header-menu' : 'header-menu-full'"
            class="action-menu-icon"
          ></fc-svg-icon>
        </button>
        <fac-dropdown
          #headerMenu
          [popup]="true"
          [model]="menuItemList"
          (onSelectedItem)="onMenuItemSelect($event)"
        ></fac-dropdown>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isSaasAccepted">
    <div class="sign-out-label" (click)="onLogoutClick()">Sign Out</div>
  </ng-container>
</div>
<div class="header-red-line"></div>
<ng-template #loginRef>
  <button mat-button (click)="onLoginClick()">Login</button>
</ng-template>
<ng-template #expandMore>
  <mat-icon class="expand-icon" [inline]="true">expand_more</mat-icon>
</ng-template>
