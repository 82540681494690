/**
 * QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 * This software is confidential. Quest Software Inc., or one of its
 * subsidiaries, has supplied this software to you under terms of a
 * license agreement, nondisclosure agreement or both.
 *
 * You may not copy, disclose, or use this software except in accordance with
 * those terms.
 *
 *
 * Copyright 2023 Quest Software Inc.
 * ALL RIGHTS RESERVED.
 *
 * QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 * WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 * EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 * TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR
 * NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 * LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 * AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 * THIS SOFTWARE OR ITS DERIVATIVES.
 */

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { AccountSettingHttpService } from '../_service/account-setting-http.service';
import {
  SubscriptionDisplayDetails,
  SubscriptionsInfo,
  SubscriptionsPayLoad,
  UsageInfo,
  expiredSoonSubscription,
} from '../_models/subscriptions-details';
import { SubscriptionTypes } from '../_models/subscriptions-details';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubscriptionsDetailsService } from './_service/subscriptions-details.service';
import { SubscriptionSaleService } from '../_service/subscription-sale.service';
import moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'fc-subscriptions-details',
  templateUrl: './subscriptions-details.component.html',
  styleUrls: ['./subscriptions-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionsDetailsComponent implements OnInit {
  @Input() username: string;
  @Input() isOwnerOrAdmin: boolean;
  @Input() theme: string;
  subscriptionDisplayDetails: SubscriptionDisplayDetails[];
  expiredSubscriptions: string[];
  expiredSoonSubscriptions: expiredSoonSubscription[];
  activeSubscriptions: string = '';
  constructor(
    private httpService: AccountSettingHttpService,
    private subscriptionsDetailsService: SubscriptionsDetailsService,
    public subscriptionSaleService: SubscriptionSaleService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptionsDetails();
  }

  /**
   * Call api/v1/foglight-cloud/subscriptions
   */
  subscriptionsDetails() {
    const payLoad: SubscriptionsPayLoad = {};

    this.httpService
      .getSubscriptionsDetails(payLoad)
      .pipe(untilDestroyed(this))
      .subscribe((subscriptionResult) => {
        if (subscriptionResult) {
          let subscriptionList = subscriptionResult.subscriptions;

          this.subscriptionDisplayDetails =
            this.subscriptionsDetailsService.modifySubscriptionData(
              subscriptionList
            );
          this.updateActiveSubscriptions();
          this.expiredSubscriptions =
            this.subscriptionsDetailsService.getExpiredSubscriptions;
          this.expiredSoonSubscriptions =
            this.subscriptionsDetailsService.getExpiredSoonSubscriptions;

          this.cd.markForCheck();
        }
      });
  }

  /**
   * Update Subscription Type
   * Use Subscription Types list in generation of mail
   */
  updateActiveSubscriptions() {
    let activeSubscriptionsList =
      this.subscriptionsDetailsService.findActiveSubscriptions(
        this.subscriptionDisplayDetails
      );

    this.activeSubscriptions = activeSubscriptionsList.join(',');
    this.subscriptionSaleService.updateSubscriptionType(
      this.activeSubscriptions
    );
  }

  /**
   * Open The default Email client of the user with predefine text
   */
  sendRequest() {
    this.subscriptionSaleService.generateMailToSupportTeam(
      this.activeSubscriptions,
      this.username
    );
  }
}
