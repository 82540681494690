/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { AfterViewChecked, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[matTooltip][fcShowIfTruncated]'
})
export class ShowIfTruncatedDirective implements AfterViewChecked {
  @Input() childElementSelector: string;

  constructor(
    private matTooltip: MatTooltip,
    private elementRef: ElementRef<HTMLElement>
  ) {
  }

  ngAfterViewChecked() {
    this.updateTooltipState();
  }

  updateTooltipState() {
    const element = this.childElementSelector ?
      this.elementRef.nativeElement.querySelector(this.childElementSelector) : this.elementRef.nativeElement;
    this.matTooltip.disabled = element.scrollWidth <= element.clientWidth;
  }
}
