/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnInit,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { IconRegistryInitService } from './services/icon-registry-init-service';
import { ICON_REGISTRY_CONFIG } from './icon-registry-config';
import { combineLatest } from 'rxjs';
import { delay, filter, retry, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { skipEmpty } from './shared/utils/skip-empty.operator';
import { SidebarService } from './layout/sidebar/services/sidebar.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { appModuleActions } from './views/users-management/store/action-types';
import { DOCUMENT } from '@angular/common';
import { FacAuthService } from '@foglight/angular-common';
import { FacNavbarService } from './layout/navbar/navbar.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { IdleUserService } from './services/idle-user/idle-user.service';
import { serverInfoSelector } from './store/app.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'fc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Foglight';
  isKeepAliveIntervalExists = false;
  isLoading = false;
  lightTheme = 'light-theme';
  darkTheme = 'dark-theme';
  currentTheme: any = this.lightTheme;
  jwtTokenInterval = null;
  worker!: Worker;

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const focusableEle = document.querySelectorAll(
      'button:not([disabled]):not([tabindex="-1"]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]):not(div.fac-tree-navigation-invisible-section > cdk-nested-tree-node):not(div.mat-sort-header-container), details:not([disabled]), tr:not(.secondary-table-height):not(.fac-table-header-row)'
    );

    // find active element
    let elementIndex: number;
    let element: HTMLElement;
    for (let i = 0; i < focusableEle.length - 1; i++) {
      if (focusableEle[i] == document.activeElement) {
        elementIndex = i;
        element = focusableEle[i] as HTMLElement;
      }
    }

    //Change keyboard key press behaviour to allow accessibility through keyboard only
    if (
      element?.tagName != 'TEXTAREA' ||
      ((element as HTMLInputElement)?.selectionStart ==
        (element as HTMLInputElement)?.value?.length &&
        event.key == 'ArrowRight') ||
      ((element as HTMLInputElement)?.selectionStart == 0 &&
        event.key == 'ArrowLeft')
    ) {
      switch (event.key) {
        case 'ArrowLeft':
          event.preventDefault();
          break;
        case 'ArrowUp':
          if (
            elementIndex <= 2 ||
            focusableEle[elementIndex].classList.contains(
              'mat-autocomplete-trigger'
            )
          ) {
            break;
          }
          (focusableEle[elementIndex - 1] as HTMLElement).focus();
          break;
        case 'ArrowRight':
          event.preventDefault();
          break;
        case 'ArrowDown':
          //if (event.target['tagName'].toLowerCase() !== 'input') {
          if (
            elementIndex === focusableEle.length - 1 ||
            focusableEle[elementIndex].classList.contains(
              'mat-autocomplete-trigger'
            )
          ) {
            break;
          }
          (focusableEle[elementIndex + 1] as HTMLElement).focus();
          break;
        case 'Enter':
          if (
            (document.activeElement.nextElementSibling?.classList.contains(
              'side-menu'
            )) || (this.document.activeElement.tagName.toLowerCase() == 'button')
          ) {
            break;
          }
        case ' ':
          if (this.document.activeElement.getAttribute('type') !== 'text') {
            (document.activeElement as HTMLElement).click();
            break;
          }
          break;
      }
    }
  }

  constructor(
    iconRegistryInit: IconRegistryInitService,
    private router: Router,
    private sidebarService: SidebarService,
    private http: HttpClient,
    private store: Store,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private navbarService: FacNavbarService,
    private authService: FacAuthService,
    private idleUserService: IdleUserService
  ) {
    // apply the registry to icon registry service for dynamic svg icon loading
    iconRegistryInit.init(ICON_REGISTRY_CONFIG);
  }

  handleMessage(event: MessageEvent): void {
    if (event.data && event.data.url) {
      const fullUrl = event.data.url;
      // Extract the part of the URL after 'aui/'
      const extractedPath = this.extractPath(fullUrl);
      // Ensure the extracted path is correct and navigate if path is AUI page
      if (extractedPath) {
        this.router.navigateByUrl(`/${extractedPath}`);
        this.isLoading = false;
      }
    }
  }

  extractPath(fullUrl: string): string | null {
    const basePath = '/aui/';
    const index = fullUrl.indexOf(basePath);
    if (index !== -1) {
      return fullUrl.substring(index + basePath.length);
    }
    return null;
  }

  ngOnInit() {
    this.store.select(serverInfoSelector);
    if (window.self !== window.top) {
      this.isLoading = true;
      window.top.postMessage({ url: window.location.href }, '*');
    } else {
      window.addEventListener('message', this.handleMessage.bind(this));
    }

    this.store
      .select(serverInfoSelector)
      .pipe(untilDestroyed(this))
      .subscribe(({ isCloud }) => {
        if (isCloud) {
          this.dispatchSubscriptionInfo();
        }
      });

    //Get server info
    setTimeout(() => {
      this.store.dispatch(appModuleActions.getServerInfo());
      this.store.dispatch(appModuleActions.getUserInfo());
    }, 300);

    combineLatest([
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd && event.url === '/')
      ),
      this.sidebarService.redirectLink$.pipe(skipEmpty()),
    ]).subscribe(([nav, link]) => {
      this.router.navigateByUrl(link);
    });

    this.checkKeepAliveAui();

    this.getSelectedTheme();

    this.worker = new Worker(new URL('./app.worker', import.meta.url));
    this.worker.onmessage = ({ data }) => this.keepAliveAuiAPI(data);
  }

  ngOnDestroy(): void {
    this.idleUserService.stopWatching();
  }

  dispatchSubscriptionInfo() {
    this.store.dispatch(appModuleActions.getSubscriptionInfo());
  }

  checkKeepAliveAui() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe((res: NavigationEnd) => {
        if (res.url.indexOf('wcf') === -1) {
          // on angular
          if (res.url.indexOf('auth') === -1 && !this.jwtTokenInterval) {
            this.jwtTokenInterval = setInterval(() => {
              this.refreshJwtToken();
            }, 240000);
          }
          if (!this.isKeepAliveIntervalExists) {
            this.isKeepAliveIntervalExists = true;
            this.http
            .get<any>('/console/page', { observe: 'response' })
            .subscribe(
              (resp) => {
                const location = resp.headers.get('Location');
                this.postLocationToWebWorker(location);
              },
              (e) => {
                let url = e.url + '';
                this.postLocationToWebWorker(url);
              }
            );
          }
        } else {
          // on wcf
          if (this.isKeepAliveIntervalExists) {
            this.isKeepAliveIntervalExists = false;
            clearInterval(this.jwtTokenInterval);
            this.jwtTokenInterval = null;
            this.worker.postMessage({ killInterval: true });
          }
        }
      });
  }

  keepAliveAuiAPI(bookId): void {
    const sub = this.http
      .get<any>(`/console/events${bookId}`, { observe: 'response' })
      .pipe(retry(3), delay(100))
      .subscribe(
        () => {
          sub.unsubscribe();
        },
        (error: HttpErrorResponse) => {
          if (error.status === 410 || error.status === 502) {
            this.authService.logout();
          }

          sub.unsubscribe();

          console.log('Keep alive error');
        }
      );
  }

  refreshJwtToken() {
    this.authService
      .renewJwtToken()
      .pipe(
        tap((it) => {
          this.authService.storeAccessAndRefreshToken(
            it?.access_token,
            it?.refresh_token
          );
        })
      )
      .subscribe();
  }

  getSelectedTheme() {
    this.navbarService.userSelectedTheme$.pipe().subscribe((res) => {
      localStorage.setItem('theme', res);
      this.setThemeClass(res);
    });
  }

  setThemeClass(theme) {
    if (theme == this.darkTheme) {
      this.renderer.removeClass(this.document.body, this.lightTheme);
      this.renderer.addClass(this.document.body, this.darkTheme);
    } else {
      this.renderer.removeClass(this.document.body, this.darkTheme);
      this.renderer.addClass(this.document.body, this.lightTheme);
    }
  }

  private postLocationToWebWorker(url: string): void {
    const book = url.slice(url.lastIndexOf('/'));
    this.worker.postMessage({ book, killInterval: false });
  }
}
