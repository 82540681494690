/**
 * QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 * This software is confidential. Quest Software Inc., or one of its
 * subsidiaries, has supplied this software to you under terms of a
 * license agreement, nondisclosure agreement or both.
 *
 * You may not copy, disclose, or use this software except in accordance with
 * those terms.
 *
 *
 * Copyright 2023 Quest Software Inc.
 * ALL RIGHTS RESERVED.
 *
 * QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 * WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 * EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 * TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR
 * NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 * LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 * AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 * THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class SubscriptionSaleService {
  private _subscriptionType = new Subject<any>();
  subscriptionType$ = this._subscriptionType.asObservable();

  constructor() {}

  /**
   * Use to Update SubscriptionType 
   * @param subscriptionType 
   */
  updateSubscriptionType(subscriptionType: string) {
    this._subscriptionType.next(subscriptionType);
  }

  /**
   * Open The default Email client of the user with predefine text
   * @param subscriptionType subscriptionType
   * @param username application user
   */
  generateMailToSupportTeam(subscriptionType, username) {
    if(!subscriptionType){
      subscriptionType = "Subscription not found";
    }
    const toEmailAddress = 'sales@foglightcloud.com';
    const url = new URL(window.location.href);
    const subdomain = url.hostname.split('.')[0];
    const subject =
      'Foglight Cloud - Request for Sales Team Assistance - ' + subdomain;
    const body = `Dear Sales Team,\n\nSubscription type : ${subscriptionType} \n\nUsername : ${username}\n\nAccount name : ${subdomain}\n\nI\'m looking for assistance with the renewal and/or new purchase of Foglight Cloud.\n\nTo provide you with further information and discuss the details,Please contact me at your earliest\nconvenience via the following email address and/or phone number:\n\nEmail: [Email Address]\n\nPhone: [Phone Number]\n\nSincerely,\n[Your name]\n\n`;
    window.location.href =
      'mailto:' +
      encodeURIComponent(toEmailAddress) +
      '?subject=' +
      encodeURIComponent(subject) +
      '&body=' +
      encodeURIComponent(body);
  }
}
