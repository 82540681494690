/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  FacAuthModule,
  FacCommonLibModule,
  FacEnvConfigService,
} from '@foglight/angular-common';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { APP_BASE_HREF } from '@angular/common';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { SharedModule } from './shared/shared.module';
import { NotificationChannelsListService } from './views/notification-management/services/notification-channels-list.service';
import { NotificationChannelsHttpService } from './views/notification-management/services/notification-channels-http.service';
import { AlarmTemplateHttpService } from './views/alarm-template/_services/alarm-template-http.service';
import { AlarmTemplateDataRefresherService } from './views/alarm-template/_services/alarm-template-data-refresher.service';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NotificationManagementResolver } from './views/notification-management/notification-manegement-resolver.service';
import { RoleGuard } from './shared/guards/Role.guard';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { UnavailableComponent } from './views/unavailable/unavailable.component';
import { SnackBarService } from './services/snack-bar.service';
import { SnackBarMultiSelectComponent } from './shared/snack-bar-multi-select/snack-bar-multi-select.component';
import { SnackBarDeleteComponent } from './shared/snack-bar-delete/snack-bar-delete.component';
import { SnackBarErrorComponent } from './shared/snack-bar-error/snack-bar-error.component';
import { SnackBarSuccessComponent } from './shared/snack-bar-success/snack-bar-success.component';
import { MatIconModule } from '@angular/material/icon';
import { ContactsListService } from './views/notification-management/services/contacts-list.service';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { UsersManagementService } from './views/users-management/users-management.service';
import { GraphQLModule } from './graphql.module';
import { appModuleEffects } from './store/app.effects';
import { AvailabilityGuard } from './shared/guards/availability.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PageTemplateComponent } from './views/page-template/page-template.component';
import { HomePageComponent } from './views/home-page/home-page.component';
import { AdminPageComponent } from './views/admin-page/admin-page.component';
import { SaasAdendumPageComponent } from './views/home-page/saas-adendum-page/saas-adendum-page.component';
import { WelcomePageComponent } from './views/home-page/welcome-page/welcome-page.component';
import { StaSaasAcceptanceScreenResolver } from '@services/sta-saas-acceptance-screen.resolver';
import { SaasAcceptanceGuard } from './shared/guards/saas.acceptance.guard';
import { LayoutService } from '@services/layout.service';
import { AccountSettingsModule } from './views/account-settings/account-settings.module';
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SingleSpaAppLoaderComponent } from './views/single-spa-app-loader/single-spa-app-loader.component';
import { AdminPageGuard } from './shared/guards/admin-page.guard';

const appInitializerFn = (env: FacEnvConfigService) => {
  return () => {
    env.registerEnvConfig({
      foglightBaseUrl: environment.foglightBaseUrl,
      angularBaseUrl: environment.angularBaseUrl,
      networkTimeout: environment.networkTimeout,
    });
    return env;
  };
};

@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    UnauthorizedComponent,
    UnavailableComponent,
    SnackBarMultiSelectComponent,
    SnackBarDeleteComponent,
    SnackBarErrorComponent,
    SnackBarSuccessComponent,
    PageTemplateComponent,
    HomePageComponent,
    WelcomePageComponent,
    SaasAdendumPageComponent,
    AdminPageComponent,
    SingleSpaAppLoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FacAuthModule,
    HttpClientModule,
    LayoutModule,
    FacCommonLibModule,
    BrowserAnimationsModule,
    SharedModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
    AccountSettingsModule,
    EffectsModule.forRoot([appModuleEffects]),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictActionSerializability: true,
        strictStateSerializability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    GraphQLModule,
    NgIdleModule,
    NgIdleKeepaliveModule,
  ],
  providers: [
    FacEnvConfigService,
    NotificationChannelsListService,
    NotificationChannelsHttpService,
    AlarmTemplateHttpService,
    AlarmTemplateDataRefresherService,
    UsersManagementService,
    NotificationManagementResolver,
    StaSaasAcceptanceScreenResolver,
    LayoutService,
    SnackBarService,
    ContactsListService,
    RoleGuard,
    AvailabilityGuard,
    SaasAcceptanceGuard,
    AdminPageGuard,
    {
      provide: APP_BASE_HREF,
      useValue: environment.baseHref,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [FacEnvConfigService],
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
