import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  open<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    data?: D, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
    return this.dialog.open<T, D>(
      componentOrTemplateRef,
      {
        panelClass: 'fc-border-dialog-panel',       
        autoFocus: false,
        data,
        ...config
      }
    );
  }
}
