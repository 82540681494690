<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->

<form [formGroup]="formGroup">
  <div class="details-header">
    Add to Bookmark
    <button
      mat-icon-button
      type="button"
      class="dismiss-button"
      tabindex="-1"
      (click)="dismissDialog('')"
    >
      <mat-icon class="dismiss-icon">clear</mat-icon>
    </button>
  </div>

  <ng-container>
    <section>
      <fac-input
        label="Name*"
        placeholder="Name*"
        [(ngModel)]="data.name"
        class="bookmark-name"
        formControlName="name"
      >
      </fac-input>
      <mat-checkbox
      class="checkbox-comp"
      color="primary"
      [checked]="false"
      (change)="sendEmailLink()">
      Send an email with a link to this bookmark
      </mat-checkbox>
    </section>
    <footer>
      <button
        mat-stroked-button
        type="button"
        class="round-corner"
        (click)="dismissDialog('')"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        class="round-corner"
        (click)="onAddClick()"
        [disabled]="!formGroup.valid"
      >
        Add
      </button>
    </footer>
  </ng-container>
</form>
