<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->

<div class="about-dialog-main">
  <div mat-dialog-title class="about-dialog-title">
    About Foglight
    <button class="close" mat-button (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="about-dialog-tab">
    <mat-tab-group>
      <mat-tab label="About">
        <div class="about-container">
          <div class="aboutLogo">
            <fc-svg-icon svgName="logo_about"></fc-svg-icon>
          </div>
          <div class="produce-name">
            Foglight®
          </div>
          <div *ngIf="!isCloudFlag" class="produce-version">
           {{version | serverVersionFormat}}
          </div>
          <div class="about-content">
            Quest and the Quest logo are trademarks and registered trademarks of Quest Software Inc. For a complete list
            of
            Quest marks, visit www.quest.com/legal/trademark-information.aspx. All other trademarks and registered
            trademarks are property of their respective owners.
            <br><br>
            This product is protected by pending and issued U.S. Patents. For details, see
            https://www.quest.com/legal/patents.aspx.
            <br><br>
            © 2024 Quest Software Inc. ALL RIGHTS RESERVED.
          </div>
        </div>
      </mat-tab>
      <mat-tab  class="legal-notices-tab" label="Legal Notices">
        <fc-legal-notice></fc-legal-notice>
      </mat-tab>
      <mat-tab class="contact" label="Contact">
        <div class="contact-container">
          <div class="contact-content">
            Quest listens to customers worldwide and delivers innovative technology, business solutions, and services
            they
            trust and value. We look forward to hearing from you.
          </div>
          <div>
            <button class="support-button" mat-stroked-button (click)="supportClick()">Technical Support</button>
          </div>
          <div class="contact-phone">
            <mat-icon [inline]="true" class="phone">phone</mat-icon>1 (949) 754-8000
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
