<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->

<header id="pageHeader">
  <fc-navbar [ngClass]="theme"></fc-navbar>
</header>
<section>
  <ng-container *ngIf="showSidebar">
    <fc-sidebar
      id="fc-sidebar"
      [folded]="true"
      [foldedWidth]="40"
      lockedOpen="gt-xs"
      fcResizableWidth
      [pinnedState]="pinnedState$ | async"
      [ngClass]="theme"
      (temporaryFold)="foldTemporarily()"
      (temporaryUnfold)="unfoldTemporarily()"
    >
    </fc-sidebar>
  </ng-container>
  <div
    id="layout"
    class="container"
    [ngClass]="{
      'dark-theme-background': theme_content_background === 'dark',
      'light-theme-background': theme_content_background === 'light',
      'pinned-padding-left': (pinnedState$ | async) === false
    }"
    [@routingAnimation]="o.isActivated ? o.activatedRoute : ''"
  >
    <router-outlet #o="outlet"></router-outlet>
    <!-- use to render micro frontend application-->
    <div id="angular-center" #angularCenter></div>
  </div>
</section>
<footer></footer>
