/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSettingComponent } from './account-setting.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccountSettingsRoutingModule } from './account-settings.routing.module';
import { UsageStatisticsComponent } from './usage-statistics/usage-statistics.component';
import { SubscriptionsDetailsComponent } from './subscriptions-details/subscriptions-details.component';
import { SaleSupportComponent } from './sale-support/sale-support.component';
import { FacChartModule, FacTooltipModule } from '@foglight/angular-common';
import { AccountSettingHttpService } from './_service/account-setting-http.service';
import { SubscriptionsDetailsService } from './subscriptions-details/_service/subscriptions-details.service';
import { SubscriptionSaleService } from './_service/subscription-sale.service';

@NgModule({
  declarations: [
    AccountSettingComponent,
    UsageStatisticsComponent,
    SubscriptionsDetailsComponent,
    SaleSupportComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AccountSettingsRoutingModule,
    FacTooltipModule,
    FacChartModule,
  ],
  providers: [
    AccountSettingHttpService,
    SubscriptionsDetailsService,
    SubscriptionSaleService,
  ],
})
export class AccountSettingsModule {}
