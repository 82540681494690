/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';
import { NavItem } from '../../models/nav-item';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import lod from 'lodash';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { sidebarIcons } from '../../config/sidebar-icons-mapping';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { AddBookmarkDialogComponent } from './../add-bookmark-dialog/add-bookmark-dialog.component';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { SnackBarSuccessComponent } from 'src/app/shared/snack-bar-success/snack-bar-success.component';
import { Store } from '@ngrx/store';
import { isCloudFlagSelector } from 'src/app/store/app.selectors';

@UntilDestroy()
@Component({
  selector: 'fc-nav-menu-panel',
  templateUrl: './nav-menu-panel.component.html',
  styleUrls: ['./nav-menu-panel.component.scss'],
})
export class NavigationMenuPanelComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() isError = false;
  @Input() isPinned: boolean;
  @Output() pinClick = new EventEmitter();
  @Output() editModeClick = new EventEmitter();
  @Output() tooltipHover = new EventEmitter();

  classicFgPath = '/console';
  configurationItems: NavItem[] = [];
  showConfigMenu = false;
  navItems: NavItem[] = [];
  bookmarksItem: NavItem;
  bookmarkUrl = '';
  selectedTabIndex: number;
  navItem = '';
  currentNavItem: NavItem;
  isCloudFlag: boolean = false;
  isCloud;

  constructor(
    public sidebarService: SidebarService,
    private router: Router,
    private titleService: Title,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private snackService: SnackBarService,
    private store: Store,
    private route: ActivatedRoute
  ) {}

  filterOutNavItem(navItems: NavItem[], targetName: string): NavItem[] {
    const filteredItems: NavItem[] = [];

    for (const item of navItems) {
      if (item.displayName !== targetName) {
        if (item.children && item.children.length > 0) {
          item.children = this.filterOutNavItem(item.children, targetName);
          filteredItems.push(item);
        } else {
          filteredItems.push(item);
        }
      }
    }

    return filteredItems;
  }

  ngOnInit() {
    this.store.select(isCloudFlagSelector).subscribe((isCloud) => {
      isCloud
        ? localStorage.setItem('isCloud', 'true')
        : localStorage.setItem('isCloud', 'false');
      this.isCloudFlag = isCloud;
      this.hideFeeds();
      this.ref.detectChanges();
    });

    if (sessionStorage.getItem('isPinned')) {
      sessionStorage.setItem('isPinned', sessionStorage.getItem('isPinned'));
    } else {
      sessionStorage.setItem('isPinned', '' + false);
    }
    this.sidebarService.navItems$
      .pipe(untilDestroyed(this))
      .subscribe((items) => {
        this.navItems = lod.cloneDeep(items);

        if (this.isCloudFlag) {
          this.navItems = this.filterOutNavItem(this.navItems, "What's New");
          this.ref.detectChanges();
        }

        this.setSidebarItemsIcons();

        const url = this.router.routerState.snapshot.url;
        const link = url.split('/')[1] || '';
        if (
          url !== '/' &&
          link !==
            'wcf?name=general-view-aui-wrapper&viewId=system:administration_home.159'
        ) {
          let id = '';
          let filteredNavItems = [];
          if (url.includes('wcf?')) {
            filteredNavItems = this.filterItemsByLink(link, this.navItems);
            id = filteredNavItems[0]?.hasOwnProperty('id')
              ? filteredNavItems[0].id
              : '';
          } else if (url.includes('foglight-sql-profiler/query-insights')) {
            filteredNavItems = this.filterItemsByLink(
              'foglight-sql-profiler/query-insights',
              this.navItems
            );
            id = filteredNavItems[0]?.hasOwnProperty('id')
              ? filteredNavItems[0].id
              : '';
          } else {
            id =
              link.indexOf('-aui-') != -1 ? link.replace('-aui-', ':') : link;
            filteredNavItems = this.filterItemsById(id, this.navItems);
          }
          filteredNavItems.forEach((item) => {
            if (item.id === id) {
              this.currentNavItem = item;
              this.navItem = this.currentNavItem?.displayName;
            }
          });
          this.bookmarkUrl = url;
          let title = this.currentNavItem?.displayName || '';
          if (title !== '') {
            this.titleService.setTitle(`${title} - Foglight`);
          }
          if (url.indexOf('/home') != -1) {
            this.titleService.setTitle(`Welcome - Foglight`);
          }
          if (this.currentNavItem?.link && link.indexOf('-aui-') == -1) {
            let alarmTemplateId = sessionStorage.getItem('alarmTemplateId');
            if (!alarmTemplateId) {
              if (url.indexOf('wcf') === -1)
                this.router.navigate([this.currentNavItem?.link], {
                  relativeTo: this.route,
                  queryParamsHandling: 'merge',
                });
              else this.router.navigateByUrl(this.currentNavItem?.link);
              this.sidebarService.selectedNavItem.next(true);
            }
          }
        }
      });

    this.sidebarService.bookmarksItem$
      .pipe(untilDestroyed(this))
      .subscribe((items) => {
        //removed undfined data on delete action.
        if (this.bookmarksItem && !this.bookmarksItem.children[0]) {
          this.bookmarksItem.children =
            this.bookmarksItem.children.filter(Boolean);
          return;
        }
        if (!this.bookmarksItem) {
          this.bookmarksItem = lod.cloneDeep(items);
        } else {
          !this.bookmarksItem.children.includes(items.children[0]) ||
          this.bookmarksItem?.children?.length === 0
            ? this.bookmarksItem.children.push(items.children[0])
            : null;
        }
        this.bookmarksItem.iconName = 'fg-bookmarks';
        this.bookmarksItem.children.forEach((bookmarkItem) => {
          bookmarkItem.tooltip =
            'This bookmark was created using the classic web console, please navigate to the classic web console to remove it';
          bookmarkItem.source = 'console';
        });
        this.sidebarService.bookmarkAdded.next(true);
      });

    this.sidebarService.configItems$
      .pipe(untilDestroyed(this))
      .subscribe((items) => {
        this.configurationItems = lod.cloneDeep(items);
        this.hideFeeds();
      });

    this.router.events.pipe(untilDestroyed(this)).subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const url = this.router.routerState.snapshot.url;
        const link = url.split('/')[1];
        if (
          link !==
          'wcf?name=general-view-aui-wrapper&viewId=system:administration_home.159'
        ) {
          let id = '';
          let filteredNavItems = [];
          if (url.includes('wcf?')) {
            filteredNavItems = this.filterItemsByLink(link, this.navItems);
            id = filteredNavItems[0]?.id;
          } else {
            id = link;
            filteredNavItems = this.filterItemsById(id, this.navItems);
          }
          filteredNavItems.forEach((item) => {
            if (item.id === id) {
              this.navItem = item.displayName;
            }
          });
          this.bookmarkUrl = url;
        }
      }
    });

    this.sidebarService.deletedBookmark
      .pipe(untilDestroyed(this))
      .subscribe((id) => {
        this.bookmarksItem.children = this.bookmarksItem.children?.filter(
          (bookmark) => bookmark.id !== id
        );
        this.sidebarService.updateBooksmarksItem(this.bookmarksItem);
        this.ref.detectChanges();
      });
  }

  hideFeeds() {
    if (this.isCloudFlag) {
      this.configurationItems = this.configurationItems.filter((item) => {
        return item.displayName !== 'Feeds';
      });
    }
  }

  setSidebarItemsIcons() {
    if (this.navItems) {
      const icons = sidebarIcons;
      this.navItems.forEach((item) => {
        const itemId = item.id.replace('_', '-');
        item.iconName = icons[itemId] ? icons[itemId] : icons['system:default'];
      });
    }
  }

  filterItemsById(searchData, target, accum = []) {
    target.forEach((f) => {
      if (f.children) {
        this.filterItemsById(searchData, f.children, accum);
      }
      if (f.id.includes(searchData)) {
        accum.push(f);
      }
    });
    return accum;
  }

  filterItemsByLink(searchData, target, accum = []) {
    target.forEach((f) => {
      if (f.children) {
        this.filterItemsByLink(searchData, f.children, accum);
      }
      if (f.link === searchData) {
        accum.push(f);
      }
    });
    return accum;
  }

  ngOnDestroy() {
    this.sidebarService.updateNavItems(this.navItems);
  }

  pinClicked() {
    this.pinClick.emit();
  }

  toggleConfigMenu(event) {
    if (event.toElement) {
      if (
        event.toElement.nodeName !== 'MAT-TOOLTIP-COMPONENT' &&
        event.toElement.classList[0] !== 'mat-tooltip'
      ) {
        this.showConfigMenu = !this.showConfigMenu;
      } else {
        this.tooltipHover.emit();
      }
    } else {
      this.showConfigMenu = !this.showConfigMenu;
    }
  }

  isDisabled(e) {
    return e.scrollWidth <= e.clientWidth;
  }

  onErrorPageRefresh(): void {
    location.reload();
  }

  onAddBookmark() {
    this.dialog
      .open(AddBookmarkDialogComponent, {
        panelClass: 'fc-border-dialog-panel',
        height: '310px',
        width: '505px',
        data: {
          name: this.navItem,
          link: this.bookmarkUrl,
        },
        autoFocus: false,
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        if (response) {
          if (response.message === 'success') {
            this.snackBar();
            let newBookmarks = {
              children: this.bookmarksItem?.children || [],
              description: null,
              displayName: 'Bookmarks',
              icon: null,
              id: 'dummy-parent',
              link: null,
              type: 'WCF',
              visible: true,
              iconName: null,
            };
            newBookmarks.children.push(response.bookmark);

            this.sidebarService.updateBooksmarksItem(newBookmarks);
            this.ref.detectChanges();
            if (response.email) {
              let url = this.router.routerState.snapshot.url;
              if (url.includes('wcf')) {
                url = encodeURIComponent(url);
              }
              window.location.href = `mailto:?subject=${response.bookmark.displayName}&body=${document.location.origin}/aui${url}`;
            }
            setTimeout(() => {
              this.sidebarService.sidebarWidth$.next(
                parseInt(
                  this.sidebarService.sidebarWidth$.value.slice(
                    0,
                    this.sidebarService.sidebarWidth$.value.indexOf('p')
                  )
                ) -
                  1 +
                  'px'
              );
              this.sidebarService.sidebarWidth$.next(
                parseInt(
                  this.sidebarService.sidebarWidth$.value.slice(
                    0,
                    this.sidebarService.sidebarWidth$.value.indexOf('p')
                  )
                ) +
                  1 +
                  'px'
              );
            }, 1);
            this.ref.detectChanges();
          }
        }
      });
  }

  onEditModeClick() {
    this.editModeClick.emit();
  }

  openClassicFoglight() {
    window.open(environment.foglightBaseUrl.concat(this.classicFgPath));
  }

  onItemClick(item: NavItem, event) {
    if (item.link) {
      this.titleService.setTitle(`${item.displayName} - Foglight`);
      this.router.navigateByUrl(item.link);
    }
  }

  snackBar() {
    this.snackService.openSnackBar(
      SnackBarSuccessComponent,
      'success-snack-bar',
      'right',
      'top',
      3000,
      { text: 'Bookmark was created' }
    );
  }
}
