<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->
<div class="subscriptions-type-container"  [ngClass]="theme">
<div class="content-heading">Subscriptions</div>
<div class="expired-subscriptions" *ngIf="expiredSubscriptions?.length">
 
  <ng-container *ngFor="let  expired of expiredSubscriptions">
    Your Foglight® for  {{expired}} subscription has expired.
</ng-container>
 Therefore you don't have access to the Foglight App. In thirty (30) days, your environment and its data will be permanently deleted with no option to restore it.
</div>
<div  class="aboutexpired-subscriptions" *ngIf="expiredSoonSubscriptions?.length">
  <ng-container *ngFor="let  expiredSoon of expiredSoonSubscriptions">
   <span class="bold-text"> Your Foglight® for {{expiredSoon.type}} subscription will expire in {{expiredSoon.dayLeft}} days.</span> 
  </ng-container>
  At that time, if you have not renewed your subscription, Foglight Cloud services will cease at that time. If you subsequently renew within the next 30 days, the trends analysis will have a gap for the period your subscription was deactivated. 30 days after your subscription expires (if you do not renew), your environment and its data will be permanently deleted.
</div>
<ng-container *ngFor="let subscription of subscriptionDisplayDetails">
  <div class="subscriptions-type">
  <div class="sub-heading">

    <span class="db-title"> {{subscription.name}} </span>
    <span class="status-title">
      <div class="subscriptions-status">  
        <ng-container *ngIf=" subscription.activeStatus === 'Active'"> <div class="active-icon"> </div>  <span class="status-lable">Active</span> </ng-container>
        <ng-container *ngIf=" subscription.activeStatus === 'InActive'"><div class="inactive-icon"> </div><span class="status-lable">Inactive</span> </ng-container>
       
      
      </div>
    </span>
   
  </div>
  <div class="user-horizontal-line-subscription"></div>
  
   <ng-container *ngIf="subscription.isSubscribe else nonSubscriptionBlock">
    <div class="subscription-details">
      <diV class="details">
        Expires on:&nbsp;{{subscription.expirationDate}}
      </diV>
      <diV class="details">
        Days left:&nbsp;
        <ng-container *ngIf="subscription.dayLeft<1 else dayExpired">
          <mat-icon
        
          [svgIcon]="'fac-red-warning'"
          class="mat-icon"
        ></mat-icon>
        <span class="dayLeft"> {{subscription.dayLeft}}</span>
        </ng-container>
        <ng-template #dayExpired>
          <ng-container  *ngIf="(subscription.dayLeft>0 && subscription.dayLeft<=60) else active">
          <mat-icon
         
          [svgIcon]="'fac-yellow-warning'"
          class="mat-icon"
        ></mat-icon>
        <span class="dayLeft"> {{subscription.dayLeft}}</span>
      </ng-container>
        </ng-template>
        <ng-template #active>
          <span> {{subscription.dayLeft}}</span>
        </ng-template>

      
      </diV>
      <diV class="details">
        Committed Quantity:&nbsp;{{subscription.committedQuantity}}
      </diV>
      <diV class="details">
        Overage quantity this month:&nbsp;{{subscription.overageQuantity}}
      </diV>
      </div>
      <div class="send-request">
        <div  class="fit-content-width" [facTooltip]="(!isOwnerOrAdmin) ? 'Only Cloud Owner or Cloud Administrator can purchase':''">
        <fac-buttons [disabled]="!isOwnerOrAdmin"  (click)="sendRequest()" [buttonText]="'Purchase More'" ></fac-buttons>
      </div>
      </div>
   </ng-container>

   <ng-template #nonSubscriptionBlock>
    <div class="description-container">
    <span class="description-content">
      <ng-container *ngIf="subscription.type==='Databases'">
        Includes advanced set of tools designed to enhance the performance and cost monitoring and optimization for the hybrid environments.
        <a  class="link-style" href="https://docs.foglightcloud.com/monitoringdatabases" target="_blank">Learn more</a>
      </ng-container>
      <ng-container *ngIf="subscription.type==='Infrastructure'">
        Offers a comprehensive array of advanced tools to elevate performance monitoring and cost optimization. Specifically tailored for hybrid environments.
        <a  class="link-style" href="https://docs.foglightcloud.com/monitoringinfrastructure" target="_blank">Learn more</a>      </ng-container>
    </span>
  </div>
  <div class="send-request">
    <div  class="fit-content-width" [facTooltip]="(!isOwnerOrAdmin) ? 'Only Cloud Owner or Cloud Administrator can send request':''">

   
    <fac-buttons [buttonText]="'Send Request'" (click)="sendRequest()" [disabled]="!isOwnerOrAdmin" ></fac-buttons>
  </div>
  </div>
  </ng-template>

</div>

</ng-container>
</div>