/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { NgModule } from '@angular/core';
import { DialogsModule } from './dialogs/dialogs.module';
import { CommonModule } from '@angular/common';
import { LoadingLayerComponent } from './loading-layer/loading-layer.component';
import { MatButtonModule } from '@angular/material/button';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { FcIconModule } from './icon';
import {
  FacLoadingModule,
  FacLoadingDotsComponent,
  FacTableModule,
  FacFormModule,
  FacPageLayoutModule,
  FacTooltipModule,
  FacDropdownModule,
  FacInputMultiselectModule,
  FacCheckboxesModule,
  FacFilterRangeModule,
  FacFiltersModule,
  FacTextboxModule,
  FacSearchInputboxModule,
  FacSplitterModule,
  FacMultiselectModule,
  FacTextAreaModule,
  FacBreadcrumbModule,
  FacTableAtomsModule,
  FacPipesModule,
  FacSkeletonModule,
  FacProgressbarModule,
  FacChipsModule,
  FacButtonsModule,
  FacActionLinkModule,
  FacBreadcrumbWrapperModule,
  FacCollapsePanelModule,
  FacTableMetricModule,
  FacInputDropdownModule,
  FacTabLineModule
} from '@foglight/angular-common';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShowIfTruncatedDirective } from './directives/show-if-truncated.directive';
import { SetDefaultsIconPipe } from './pipe/set-defaults-icon.pipe';
import { FcAutoWidthDirective } from './directives/auto-width.directive';
import { ChipDropdownComponent } from './chip-dropdown/chip-dropdown.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { ResizableWidthDirective } from './directives/resizable-width.directive';
import { NotificationManagementContactsComponent } from '../views/notification-management/notification-management-contacts/notification-management-contacts.component';
import { MatMenuModule } from '@angular/material/menu';
import { InputKeydownEventsBehaviourDirective } from './directives/input-keydown-events-behaviour.directive';
import { SortByPipe } from './pipe/sort-by.pipe';
import { DebounceClickDirective } from './directives/debounce-on-click';
import { ServerVersionFormatPipe } from './pipe/server-version-format.pipe';
// import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

const MATERIAL_MODULES = [
  // material modules
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatTooltipModule,
  MatMenuModule,
  MatSelectModule,
  MatFormFieldModule,
];

const FOGLIGHT_COMMON_MODULES = [
  FacPageLayoutModule,
  FacTooltipModule,
  FacDropdownModule,
  FacInputMultiselectModule,
  FacCheckboxesModule,
  FacFilterRangeModule,
  FacFiltersModule,
  FacTextboxModule,
  FacSearchInputboxModule,
  FacSplitterModule,
  FacMultiselectModule,
  FacTextAreaModule,
  FacBreadcrumbModule,
  FacTableAtomsModule,
  FacPipesModule,
  FacSkeletonModule,
  FacProgressbarModule,
  FacChipsModule,
  FacButtonsModule,
  FacActionLinkModule,
  FacBreadcrumbWrapperModule,
  FacCollapsePanelModule,
  FacTableMetricModule,
  FacInputDropdownModule,
  FacTabLineModule
];

@NgModule({
  declarations: [
    LoadingLayerComponent,
    ChipDropdownComponent,
    LoadingButtonComponent,
    SvgIconComponent,
    ShowIfTruncatedDirective,
    SetDefaultsIconPipe,
    SortByPipe,
    ServerVersionFormatPipe,
    FcAutoWidthDirective,
    ResizableWidthDirective,
    NotificationManagementContactsComponent,
    InputKeydownEventsBehaviourDirective,
    DebounceClickDirective,
  ],
  imports: [
    MATERIAL_MODULES,
    FOGLIGHT_COMMON_MODULES,
    DialogsModule,
    CommonModule,
    FcIconModule,
    FacLoadingModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatChipsModule,
    FacTableModule,
    FacFormModule,
  ],
  exports: [
    MATERIAL_MODULES,
    FOGLIGHT_COMMON_MODULES,
    DialogsModule,
    FcIconModule,
    FacLoadingDotsComponent,
    LoadingLayerComponent,
    LoadingButtonComponent,
    ChipDropdownComponent,
    SvgIconComponent,
    ShowIfTruncatedDirective,
    FcAutoWidthDirective,
    SetDefaultsIconPipe,
    ServerVersionFormatPipe,
    SortByPipe,
    ResizableWidthDirective,
    InputKeydownEventsBehaviourDirective,
    NotificationManagementContactsComponent,
    DebounceClickDirective,
  ],
})
export class SharedModule {}
