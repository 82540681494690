<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->

<div class="account-settings-container" [ngClass]="theme">
  <fac-breadcrumb-wrapper
    [addNewBreadcrumbItem]="breadcrumbItems"
  ></fac-breadcrumb-wrapper>
  <div class="user-info">
    <div class="user-icon-div">
      <span class="user-icon">
        <fc-svg-icon class="logo" svgName="account-settings-user"></fc-svg-icon>
      </span>
    </div>
    <div class="user-details">
      <span class="user-email">{{ userName }}&nbsp;&nbsp;</span>

      <span>
        <a class="link-style" (click)="onLogoutClick()">
          <mat-icon
            [svgIcon]="'fac-arrow-path'"
            class="mat-path-icon"
          ></mat-icon>
          <span> Sign out</span>
        </a>
      </span>

      <div class="user-role">
        Role:
        {{ userRoles }}
      </div>
    </div>
  </div>
  <div class="horizontal-line">
    <div class="user-horizontal-line"></div>
  </div>
  <div class="subscriptions-usage-container">
    <div class="subscriptions-sale-container">
      <fc-subscriptions-details
        [isOwnerOrAdmin]="isOwnerOrAdmin"
        [username]="userName"
        [theme]="theme"
      ></fc-subscriptions-details>

      <fc-sale-support
        [isOwnerOrAdmin]="isOwnerOrAdmin"
        [username]="userName"
        [theme]="theme"
      ></fc-sale-support>
    </div>
    <div class="usage-statistics-container" >
      <fc-usage-statistics  [theme]="theme"></fc-usage-statistics>
      </div>
  </div>
