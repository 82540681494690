/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

 import { Injectable, OnInit } from '@angular/core';
 import {
   CanActivate,
   Route,
   UrlSegment,
   ActivatedRouteSnapshot,
   RouterStateSnapshot,
   UrlTree,
   Router,
 } from '@angular/router';
 import { FacUserService } from '@foglight/angular-common';
 import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
 import { Store } from '@ngrx/store';
 import { Observable, of } from 'rxjs';
 import { catchError, map } from 'rxjs/operators';
 import { FacNavbarService } from 'src/app/layout/navbar/navbar.service';
 import {
   isCloudFlagSelector,
   serverInfoSelector,
 } from 'src/app/store/app.selectors';
 import permissions from '../../../assets/json/permissions.json';
 import { appModuleActions } from 'src/app/views/users-management/store/action-types';
 
 interface Permission {
   url: string;
   roles: string[];
 }
 @UntilDestroy()
 @Injectable({
   providedIn: 'root',
 })
 export class RoleGuard implements CanActivate {
   unauthorizedMessage = '';
   constructor(
     private router: Router,
     private userService: FacUserService,
     private navbarService: FacNavbarService,
     private store: Store
   ) {}
 
   canActivate(
     route: ActivatedRouteSnapshot,
     state: RouterStateSnapshot
   ): Observable<boolean> {
     return this.userService.getUserInfo().pipe(
       map((userInfo) => {
         this.store.dispatch(
           appModuleActions.setUserInfo({ userInfo: userInfo })
         );
         let canLoad = false;
 
         //notification-management permissions
         if (route.url.toString().includes('notification-management')) {
           permissions['notification-management'].forEach((role) => {
             if (userInfo.roles.includes(role)) {
               canLoad = true;
             }
           });
           if (!canLoad) {
             this.unauthorizedMessage =
               'Accessing notification channels requires Alarm Management Administrator role.';
             sessionStorage.setItem(
               'lastErrorMessage',
               this.unauthorizedMessage
             );
             this.router.navigateByUrl('unauthorized');
           }
         }
 
         // alarm-template permissions
         if (route.url.toString().includes('alarm-template')) {
           permissions['alarm-template'].forEach((role) => {
             if (userInfo.roles.includes(role)) {
               canLoad = true;
             }
           });
           if (!canLoad) {
             this.unauthorizedMessage =
               'Accessing alarm template requires Alarm Management Administrator role.';
             sessionStorage.setItem(
               'lastErrorMessage',
               this.unauthorizedMessage
             );
             this.router.navigateByUrl('unauthorized');
           }
         }
 
         // Audit Log permissions
         if (route.url.toString().includes('audit-log')) {
           permissions['audit-log'].forEach((role) => {
             if (userInfo.roles.includes(role)) {
               canLoad = true;
             }
           });
           if (!canLoad) {
             this.unauthorizedMessage =
               'Accessing Audit Log requires Monitoring Administrator role.';
             sessionStorage.setItem(
               'lastErrorMessage',
               this.unauthorizedMessage
             );
             this.router.navigateByUrl('unauthorized');
           }
         }
 
         // users management permissions
         if (route.url.toString().includes('manage-users')) {
           permissions['users-management'].forEach((role) => {
             if (userInfo.roles.includes(role)) {
               canLoad = true;
             }
           });
           if (!canLoad) {
             this.unauthorizedMessage =
               'You do not have the required role for users management.';
             sessionStorage.setItem(
               'lastErrorMessage',
               this.unauthorizedMessage
             );
             this.router.navigateByUrl('unauthorized');
           }
         }
 
 
 
         // admin permissions
         this.store
       .select(isCloudFlagSelector)
       .pipe(untilDestroyed(this))
       .subscribe((isCloud) => {
         if (!isCloud) 
           {
             canLoad = true;
           } 
         else {
           if (route.url.toString().includes('system-aui-administration')) {
             permissions['saas-acceptance'].forEach((role) => {
               if (userInfo.roles.includes(role)) {
                 canLoad = true;
               }
             });
             if (!canLoad) {
               this.unauthorizedMessage =
                 'You do not have the required roles to access this page';
               sessionStorage.setItem(
                 'lastErrorMessage',
                 this.unauthorizedMessage
               );
               this.router.navigateByUrl('unauthorized');
             }
           }
         }
 
       });
 
         return canLoad;
       }),
       catchError((error) => of(false))
     );
   }
 }
 