<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->

<div class="navbar-scroll-container">
  <div class="menu-extra" *ngIf="!(sidebarService.foldedState$ | async)">
    <input type="text" class="search-input search_bar_leftnav_qa" placeholder="Search"
           [(ngModel)]="searchString"
           (keyup)="onSidebarSearch()">
    <span class="material-icons search-icons-search" *ngIf="!isSearchActive">search</span>
    <span class="material-icons search-icons-cancel" *ngIf="isSearchActive" (click)="onSearchCancel()">cancel</span>
  </div>

  <mat-nav-list>
    <div class="nav-items" [ngClass]="!(sidebarService.foldedState$ | async) ? 'expanded' : 'collapsed'">
      <div class="bookmarks-container" *ngIf="bookmarksItem?.children && bookmarksItem?.children.length > 0">
        <fc-menu-list-item *ngIf="bookmarksItem" [item]="bookmarksItem" [selectedId]="selectedId"></fc-menu-list-item>
      </div>
      <fc-menu-list-item *ngFor="let item of menuItems; let i=index" [item]="item"
                         [itemIndex]="i"
                         [menuItems]="menuItems"
                         [isSearchActive]="isSearchActive"
                         (expandEvent)="onExpandEventTrigger($event)"
                         [expandedItemIndex]="expandedItem"
                         [selectedId]="selectedId">
      </fc-menu-list-item>
    </div>
  </mat-nav-list>
</div>
