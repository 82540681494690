/*
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { Injectable } from '@angular/core';
import { FacHttpClientService, ListDataRefresher } from '@foglight/angular-common';
import {Observable, of, Subject} from 'rxjs';
import { Contact } from './contacts-list.service';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { AlarmTemplateHttpService } from '../../alarm-template/_services/alarm-template-http.service';
import { AlarmTemplateData } from '../../alarm-template/_models/alarm-template';

export interface NotificationChannel {
  type: 'EmailNotificationChannelConfig';
  alarmTemplates? : any[];
  alarmTemplatesCount? : number;
  domains? :any[];
  name: string;
  description: string;
  messageTitle: string;
  messageBody: string;
  lastUpdatedTimestamp: string;
  messageBodyMediaType: 'text/plain';
  recipients: Contact[];
  disabled: boolean;
  id: string;
}


export interface Target {
  name: string;
  topologyTypeName: string;
  domainName: string;
}

@Injectable()
export class NotificationChannelsListService extends ListDataRefresher<NotificationChannel[], NotificationChannel, string | string[]> {
  notificationListChanged = new Subject<boolean>();
  notificationChannelsDeleted: string[] = [];
  checkboxes = new Subject<boolean>();
  domainCheckboxes = new Subject<any>();
  sliders = new Subject<any>();
  domainSliders = new Subject<any>();
  create = new Subject<any>();
  allAlarmTemplates: AlarmTemplateData[] = [];

  constructor(private http: FacHttpClientService, private alarmTemplateService: AlarmTemplateHttpService) {
    super();
    this.alarmTemplateService.getAlarmTemplateData().subscribe((alarmTemplates) => {
      this.allAlarmTemplates = alarmTemplates;
    });
  }

  get isMockData(): boolean {
    return false;
  }

  mockSourceData(): Observable<NotificationChannel[]> {
    return of([]);
  }

  parsedData(data: NotificationChannel[]): NotificationChannel[] {
    return data;
  }

  sourceData(): Observable<NotificationChannel[]> {
    return this.getAllNotificationChannels();
  }

  getAllNotificationChannels(): Observable<NotificationChannel[]> {
    return this.http.get('/api/v1/notification-channels').pipe(map((notificationChannels: any) => {
      const notificationChannelList = [];
      notificationChannels.forEach((notificationChannel) => {
        notificationChannel.alarmTemplates = [];
        this.allAlarmTemplates.forEach((alarmTemplate) => {
          alarmTemplate.notificationSettings.forEach((notificationSetting) => {
            if (notificationSetting.channelId == notificationChannel.id) {
              notificationChannel.alarmTemplates.push(alarmTemplate.name);
            }
          })
        })
        notificationChannel.alarmTemplatesCount = notificationChannel.alarmTemplates.length;
        notificationChannelList.push(notificationChannel);
      });
      return notificationChannelList;
    }));
  }

  createNotificationChannel(notificationChannel): Observable<NotificationChannel> {
    return this.http.post('/api/v1/notification-channels', notificationChannel);
  }

  protected filterRowDataHandler(data: NotificationChannel, filters: string | string[]): boolean {
    if (!filters) {
      return true;
    }
    if (filters.length === 1 && filters[0] === 'All') {
      return true;
    }
    if (typeof filters === 'string') {
      return data?.name?.toLocaleLowerCase().includes(filters?.toLocaleLowerCase()) || data?.description?.toLocaleLowerCase().includes(filters?.toLocaleLowerCase()) || moment(data.lastUpdatedTimestamp).format('DD MMM YYYY')
        .toLocaleLowerCase()
        .includes(filters.toLocaleLowerCase()) || data?.alarmTemplatesCount?.toString().includes(filters?.toLocaleLowerCase());
    }
    return filters.indexOf(data.name) >= 0;
  }
}
