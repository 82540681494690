<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->
<div class="sale-support-container" [ngClass]="theme">
  <div class="support-text">Sales & Support</div>
  <div class="support-options-display">
    <div class="request-support-width">
      <a class="link-style" (click)="supportOnClick()">
        <mat-icon [svgIcon]="'fac-arrow-path'" class="mat-path-icon"></mat-icon>
        <span> Request Support</span>
      </a>
    </div>
    <div class="conatct-sales-width">
      <div
        class="fit-content-width"
        [facTooltip]="
          !isOwnerOrAdmin
            ? 'Only Cloud Owner or Cloud Administrator can purchase a subscription'
            : ''
        "
      >
        <a
          [class.disabled]="!isOwnerOrAdmin"
          class="link-style"
          (click)="salesOnClick()"
        >
          <mat-icon
            [svgIcon]="'fac-arrow-path'"
            class="mat-path-icon"
          ></mat-icon>
          <span> Contact Sales</span>
        </a>
      </div>
    </div>
  </div>
</div>
