/**
 * QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 * This software is confidential. Quest Software Inc., or one of its
 * subsidiaries, has supplied this software to you under terms of a
 * license agreement, nondisclosure agreement or both.
 *
 * You may not copy, disclose, or use this software except in accordance with
 * those terms.
 *
 *
 * Copyright 2023 Quest Software Inc.
 * ALL RIGHTS RESERVED.
 *
 * QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 * WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 * EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 * TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR
 * NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 * LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 * AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 * THIS SOFTWARE OR ITS DERIVATIVES.
 */

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  HostListener,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { AccountSettingHttpService } from '../_service/account-setting-http.service';
import {
  SubscriptionTypes,
  SubscriptionsInfo,
} from '../_models/subscriptions-details';
import { TabItem } from '@foglight/angular-common';
import Highcharts from 'highcharts';

@UntilDestroy()
@Component({
  selector: 'fc-usage-statistics',
  templateUrl: './usage-statistics.component.html',
  styleUrls: ['./usage-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsageStatisticsComponent implements OnInit {
  @Input() theme: string;

  tabList: TabItem[] = [];
  activeTab: TabItem;
  usageGraphDetails: any;
  monthsList: any;
  selectedMonth: any;
  isSubscribe: boolean = true;
  showUsage: boolean = false;
  isLargeScreen: boolean = false;
  monthsName: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  chartLine: any;
  daysInMonthAxis: string[];
  daysInMonthTooltip;
  constructor(
    private cd: ChangeDetectorRef,
    private httpService: AccountSettingHttpService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.isSubscribe) {
      this.checkForScreenResolution();
    }
  }

  ngOnInit() {
    this.setTheme();
    this.setMonthsListData();
    this.setDaysInMonth();
    this.usageTimeStatisticsForCurrentMonth(false);
    this.checkForScreenResolution();
    this.cd.markForCheck();
  }

  reflowChart() {
    Highcharts.charts?.forEach(chart => {
      window.setTimeout(() => { chart?.reflow(); });
    });
  }

  checkForScreenResolution() {
    let screenWidth = window.innerWidth;
    if (screenWidth >= 1900) {
      this.isLargeScreen = true;
    } else {
      this.isLargeScreen = false;
    }
    this.cd.markForCheck();
  }

  setTheme() {
    if (localStorage.getItem('theme')) {
      this.theme = localStorage.getItem('theme');
    }
  }

  usageTimeStatisticsForCurrentMonth(activeTab) {
    const today = new Date();
    const fullYear = today.getFullYear();
    const month = today.getMonth();
    const payLoad = this.getStartAndEndDate(fullYear, month);

    this.getUsageTimeDetails(payLoad, activeTab);
  }

  setMonthsListData() {
    this.monthsList = this.getLast12months();
    if (this.monthsList && this.monthsList.length) {
      this.selectedMonth = this.monthsList[0];
    }
  }

  setDaysInMonth() {
    const today = new Date();
    const fullYear = today.getFullYear();
    const month = today.getMonth();
    this.calDaysInMonth(month, fullYear);
  }

  calDaysInMonth(month: number, year: number) {
    this.daysInMonthAxis = [];
    this.daysInMonthTooltip = {};
    const endDate = moment([year, month]).endOf('month');
    const lastDay = parseInt(endDate.format('DD'));
    for (let i = 1; i <= lastDay; i++) {
      let day = moment([year, month, i]).format('DD MMM');
      this.daysInMonthAxis.push(day);
      this.daysInMonthTooltip[day] = moment([year, month, i]).format(
        'MMMM DD , YYYY'
      );
    }
  }

  getLast12months(): any {
    const today = new Date();
    const months = [];

    for (let i = 0; i < 12; i++) {
      const fullYear = today.getFullYear();
      const month = today.getMonth() + 1;
      let monthsName = this.monthsName[today.getMonth()];
      let displayName = monthsName + ' ' + fullYear;
      if (i == 0) {
        displayName = 'This Month';
      }
      months.push({
        month: month,
        year: fullYear,
        label: displayName,
      });
      today.setDate(28);
      today.setMonth(today.getMonth() - 1);
    }

    return months;
  }

  onTabClick(event: TabItem) {
    this.activeTab = event;
    if (this.monthsList && this.monthsList.length) {
      this.selectedMonth = this.monthsList[0];
    }
    this.setDaysInMonth();

    this.usageTimeStatisticsForCurrentMonth(true);
    this.cd.markForCheck();
  }

  onMonthChange(event) {
    this.selectedMonth = event;
    const month = event.month;
    const year = event.year;
    const usageTime = this.getStartAndEndDate(year, month - 1);
    this.calDaysInMonth(month - 1, year);
    this.getUsageTimeDetails(usageTime, true);
    this.cd.markForCheck();
  }

  getStartAndEndDate(year: number, month: number) {
    const startDate = moment([year, month]);
    if (month === 11) {
      month = 0;
      year = year + 1;
    } else {
      month = month + 1;
    }
    const endDate = moment([year, month]).startOf('month');
    return {
      usageStartTime: startDate.format('YYYY-MM-DDThh:mm:ss[Z]'),
      usageEndTime: endDate.format('YYYY-MM-DDThh:mm:ss[Z]'),
    };
  }

  /**
   * Call api/v1/foglight-cloud/subscriptions
   */
  getUsageTimeDetails(usageTime: any, activeTab: boolean) {
    this.httpService
      .getSubscriptionsDetails(usageTime)
      .pipe(untilDestroyed(this))
      .subscribe((subscriptionsInfo) => {
        if (subscriptionsInfo) {
          let subscriptions = subscriptionsInfo.subscriptions;

          if (subscriptions.length > 0) {
            this.modifyUsageTimeDetails(subscriptions);
            this.arrangeTabList();
            if (!activeTab) {
              this.setActiveTab();
            } else {
              this.setDateToChart(this.activeTab.label);
            }
          } else {
            this.isSubscribe = false;
          }
          this.showUsage = true;
          this.cd.markForCheck();
        }
      });
  }

  modifyUsageTimeDetails(subscriptionList: SubscriptionsInfo[]) {
    this.tabList = [];
    this.usageGraphDetails = {};
    SubscriptionTypes.forEach((subscriptionType) => {
      let subscriptionsInfo = subscriptionList.find((subscription) =>
        subscription.name.toUpperCase().includes(subscriptionType.toUpperCase())
      );

      if (subscriptionsInfo) {
        let usageList = subscriptionsInfo.usage;
        let usageLineData = {};

        usageList.forEach((usage) => {
          usageLineData[moment(usage.date).format('DD MMM')] = usage.quantity;
        });
        this.usageGraphDetails[subscriptionType] = usageLineData;
        this.tabList.push({
          label: subscriptionType,
          id: subscriptionType,
          status: 'Active',
          disabled: false,
        });
      } else {
        this.tabList.push({
          label: subscriptionType,
          id: subscriptionType,
          status: 'InActive',
          disabled: true,
        });
      }
    });
  }

  arrangeTabList() {
    if (this.tabList.length > 0) {
      const isActive = this.tabList.find((tab) => tab.status === 'Active');
      if (isActive) {
        this.tabList.sort((a, b) => a.status.localeCompare(b.status));
      }
    }
  }

  setActiveTab() {
    if (this.tabList.length > 0) {
      this.activeTab = this.tabList[0];
      this.setDateToChart(this.activeTab.label);
    }
  }

  setDateToChart(subscriptionType: string) {
    const usageDetail = this.usageGraphDetails[subscriptionType];
    if (usageDetail) {
      let quantityList = [];
      this.daysInMonthAxis.forEach((day) =>
        quantityList.push({
          y: usageDetail[day] || 0,
          className: 'highcharts-color-2',
        })
      );
      let yaxisTitle = 'Number of DB Instances';
      let toolTitle = 'DB Instances';
      if (subscriptionType == 'Infrastructure') {
        yaxisTitle = 'Number of  Hosts';
        toolTitle = 'Hosts';
      }
      const xAxisData = this.daysInMonthAxis;
      const daysInMonthTooltip = this.daysInMonthTooltip;
      const yAxisData = quantityList;
      this.chartLine = {
        chart: {
          type: 'column',
          styledMode: true,
          events: {
            render: this.reflowChart.bind(this)
          }
        },
        title: {
          text: '',
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {
            return (
              '<span class="tooltip-header">' +
              daysInMonthTooltip[this.x] +
              '</span>' +
              `</br> <span class="highcharts-color-0">\u25CF</span>&nbsp;` +
              `<span class="tooltip-body">${toolTitle}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${this.y}</span>`
            );
          },
        },

        yAxis: {
          title: {
            text: '<span class="yaxis-title-style">' + yaxisTitle + '</span>',
            enabled: true,
            margin: 20,
          },
          labels: {
            enabled: true,
          },
        },
        xAxis: {
          categories: xAxisData,
          tickInterval: 4,
        },
        plotOptions: {
          column: {
            fillOpacity: 0.7,
          },
        },
        series: [
          {
            marker: {
              enabled: false,
            },
            showInLegend: false,

            data: yAxisData,
          },
        ],
      };

      this.cd.markForCheck();
    }
  }
}
