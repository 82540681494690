import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, InMemoryCache, createHttpLink, ApolloLink} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { onError } from '@apollo/link-error';
import { Apollo } from 'apollo-angular';
import { setContext } from '@apollo/client/link/context';
import { environment } from './../environments/environment';

    const timeoutLink = new ApolloLinkTimeout(60 * 1000);
    const uri = `${environment.foglightBaseUrl}/graphql`;
    const devOptions = {
      withCredentials: false,
    };

    const error = onError(({networkError, graphQLErrors, operation, forward}) => {
      if (networkError) {
        console.log('[Network error]', networkError.message);
        const status = 'status';
        switch (networkError[status]) {
          case 401: {

            break;
          }
        }
      }
      if (graphQLErrors) {
        graphQLErrors.map(({message, locations, path}) => {
            console.log('[GraphQL error]', message, locations, path);
          },
        );
      }
    });

    // const timeoutHttpLink = timeoutLink.concat(http);
    // const errorLink = error.concat(timeoutHttpLink);
    // const apollo: Apollo = new Apollo;
    // apollo.create({
    //   link: errorLink,
    //   cache: new InMemoryCache({
    //     // fragmentMatcher,
    //     addTypename: false,
    //   }),
    // });

// const uri = ''; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {

  const http = httpLink.create({
    uri,
    ...devOptions,
  });
  
  const accessToken = localStorage.getItem('access_token');

  const basic = setContext((operation, context) => ({
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }));

  const link = ApolloLink.from([basic, http]);
  const cache = new InMemoryCache({
    // fragmentMatcher,
    addTypename: false,
  })
  
  return {
    link,
    cache
  }
};

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
