/*
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { Injectable } from '@angular/core';
import {
  FacHttpClientService,
  ListDataRefresher,
} from '@foglight/angular-common';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import moment from 'moment';

export interface Contact {
  id?: string;
  type: 'EmailContactConfig' | 'EmailContact';
  name: string;
  position?: string;
  email: string;
  lastUpdatedTimestamp?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContactsListService extends ListDataRefresher<
  Contact[],
  Contact,
  string | string[]
> {
  checkboxes = new Subject<boolean>();
  contactListChanged = new Subject<boolean>();
  contactsDeleted: string[] = [];
  contactListUpdated = new Subject<boolean>();


  constructor(private http: FacHttpClientService) {
    super();
  }

  protected get isMockData(): boolean {
    return false;
  }

  protected mockSourceData(): Observable<Contact[]> {
    return of([]);
  }

  protected parsedData(data: Contact[]): Contact[] {
    return data;
  }

  protected sourceData(): Observable<Contact[]> {
    return this.getContactList();
  }

  getContact(id: string): Observable<Contact> {
    return this.http.get('/api/v1/contacts/' + id);
  }

  getContactList(): Observable<Contact[]> {
    return this.http.get('/api/v1/contacts');
  }

  createContact(data): Observable<Contact> {
    return this.http.post('/api/v1/contacts', data);
  }

  editContact(id: string, data: Contact): Observable<Contact> {
    return this.http.put('/api/v1/contacts/' + id, data);
  }

  deleteContact(id: string): Observable<void> {
    return this.http.delete('/api/v1/contacts/' + id);
  }

  protected filterRowDataHandler(data: Contact, filters: string): boolean {
    if (!filters) {
      return true;
    }
    return (
      data.email.toLocaleLowerCase().includes(filters.toLocaleLowerCase()) ||
      data.position?.toLocaleLowerCase().includes(filters.toLocaleLowerCase()) ||
      data.name.toLocaleLowerCase().includes(filters.toLocaleLowerCase()) ||
      moment(data.lastUpdatedTimestamp).format('DD MMM YYYY')
        .toLocaleLowerCase()
        .includes(filters.toLocaleLowerCase())
    );
  }
}
