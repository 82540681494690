<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->

<div class="sidebar-container" *ngIf="!isError">
  <mat-icon class="more" *ngIf="sidebarService.foldedState$ | async"
    >more_horiz</mat-icon
  >
  <div class="button-container" *ngIf="!(sidebarService.foldedState$ | async)">
    <button
      mat-mini-fab
      (click)="onAddBookmark()"
      facTooltip="Add to bookmark"      
    >
      <mat-icon>bookmark_outline</mat-icon>
    </button>
    <button
      *ngIf="isCloudFlag == false"
      mat-mini-fab
      (click)="openClassicFoglight()"
      class="open_old_console_qa"
      facTooltip="Open Foglight classic web console"      
    >
      <mat-icon>launch</mat-icon>
    </button>
    <button
      mat-mini-fab
      (click)="onEditModeClick()"
      class="Edit_button_qa"
      facTooltip="Edit mode"      
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
    mat-mini-fab
    (click)="pinClicked()"
    class="pin-button-qa"
    facTooltip="{{ isPinned ? 'Unpin panel' : 'Pin Panel' }}"       
  >
  <mat-icon
  [svgIcon]="isPinned ? 'fac-pin-slash' : 'fac-pin-icon'"
  class="mat-pin-icon"
></mat-icon>
  </button>
  </div>

  <fac-loading-dots
    *ngIf="!(sidebarService.foldedState$ | async) && isLoading"
    [color]="'white'"
    [size]="'medium'"
  >
  </fac-loading-dots>
  <fc-menu-list
    *ngIf="!isLoading"
    [menuItems]="navItems"
    [bookmarksItem]="bookmarksItem"
  ></fc-menu-list>

  <div
    class="sidebar-bottom"
    *ngIf="!(sidebarService.foldedState$ | async)"
  >
    <div
      class="configuration-link-container"
      (click)="toggleConfigMenu($event)"
      [ngClass]="{ 'hidden-config-menu': !(configurationItems?.length > 0) }"
    >
      <mat-icon>settings_applications</mat-icon>
      <div class="configuration-link">Configuration</div>
      <span
        class="material-icons configuration-link-arrow"
        [class.config-menu-arrow-up]="showConfigMenu"
        >keyboard_arrow_down</span
      >
    </div>
    <div
      class="configuration-menu"
      *ngIf="showConfigMenu"
      (mouseleave)="toggleConfigMenu($event)"
    >
      <ul>
        <li
          *ngFor="let item of configurationItems"
          matTooltip="{{ item.displayName }}"
          matTooltipClass="custom-nav-tooltip"
          #toolTip
          [matTooltipDisabled]="isDisabled(toolTip)"
          (click)="onItemClick(item, $event)"
        >
          <span>{{ item.displayName }}</span>
        </li>
      </ul>
    </div>    
  </div>  
</div>
<div
  *ngIf="isError"
  [ngClass]="
    !(sidebarService.foldedState$ | async)
      ? 'error-message-expanded'
      : 'error-message-collapsed'
  "
>
  <span class="material-icons error-icon">warning</span>
  <div class="message" *ngIf="!(sidebarService.foldedState$ | async)">
    We are unable to load navigation<br />
    panel at the moment. We hope<br />
    that this will be solved soon,<br />
    meanwhile try to
    <a class="refesh-page" (click)="onErrorPageRefresh()">refresh</a> the <br />
    page.
  </div>
</div>
