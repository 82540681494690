

/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/

import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'fc-page-template',
  templateUrl: './page-template.component.html',
  styleUrls: ['./page-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplateComponent implements OnInit , OnDestroy {
  
  templateLayout : string = "";
  paramsSubscription;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.paramsSubscription  = this.route.paramMap.subscribe((params: ParamMap) => {
      this.templateLayout = params.get('templateLayout');
    })
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }
}
