/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */


import {Injectable} from '@angular/core';
import {FacHttpClientService} from '@foglight/angular-common';
import { Observable } from 'rxjs';
import { NotificationManagementModel } from '../models/notification-management.model';

@Injectable()
export class NotificationChannelsHttpService {
  constructor(
    private http: FacHttpClientService
  ) {}

  getNotificationChannel(id: string): Observable<NotificationManagementModel> {
    return this.http.get(`/api/v1/notification-channels/${id}`);
  }

  updateNotificationChannel(id: string, notificationChannel: NotificationManagementModel): Observable<NotificationManagementModel> {
    return this.http.put(`/api/v1/notification-channels/${id}`, notificationChannel);
  }

  deleteNotificationChannel(id: string): Observable<void> {
    return this.http.delete(`/api/v1/notification-channels/${id}`);
  }
}
