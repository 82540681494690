/*
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

 import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
 import { Bookmark, SidebarHttpService } from './../../services/sidebar-http.service';
 import { FormControl, FormGroup, Validators } from '@angular/forms';
 import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
 import { NavItem } from '../../models/nav-item';
 import { SnackBarService } from 'src/app/services/snack-bar.service';
 import { SnackBarDeleteComponent } from 'src/app/shared/snack-bar-delete/snack-bar-delete.component';
 import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
 import { SidebarService } from '../../services/sidebar.service';
 @UntilDestroy()
 @Component({
   selector: 'fc-add-bookmark-dialog',
   templateUrl: './add-bookmark-dialog.component.html',
   styleUrls: ['./add-bookmark-dialog.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
   viewProviders: [
     SnackBarService
   ]
 })
 export class AddBookmarkDialogComponent {
   emailChecked = false;
   formGroup: FormGroup;
   constructor(
     @Inject(MAT_DIALOG_DATA) public data,
     private dialogRef: MatDialogRef<AddBookmarkDialogComponent>,
     private sidebarHttpService: SidebarHttpService,
     private snackService: SnackBarService,
     private sidebarService: SidebarService,
     private ref: ChangeDetectorRef
   ) {
     this.formGroup = new FormGroup({
       name: new FormControl(data?.data?.name || '', [
         Validators.maxLength(45),
       ])
     });
   }
 
   onAddClick() {
     this.sidebarHttpService.getBookmarks().pipe(untilDestroyed(this)).subscribe((bookmarks) => {
       const existedData = bookmarks.find((bookmark) => bookmark.link === this.data.link);
       if (!existedData) {
         this.sidebarHttpService.createBookmark(this.data).pipe(untilDestroyed(this)).subscribe((bookmarkData) => {
 
           this.dismissDialog('success', this.emailChecked, bookmarkData);
         },
         (error) => {
           this.dismissDialog('error', false, null);
         });
       } else {
         this.snackBarError();
       }
     });
   }
 
   sendEmailLink() {
     this.emailChecked = true;
   }
 
   snackBarError() {
     this.snackService.openSnackBar(
       SnackBarDeleteComponent,
       'error-snack-bar',
       'right',
       'top',
       3000,
       { text: "Duplicate link" }
     );
   }
 
   dismissDialog(message: string, email?: boolean, bookmarkData?) {
     this.dialogRef.close({
       message: message,
       email: email,
       bookmark: bookmarkData,
     });
   }
 }
 