import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createReducer,
  createSelector,
  MetaReducer,
  on,
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { routerReducer } from '@ngrx/router-store';
import * as fromUsersManagement from '../views/users-management/store/users-management.reducer';
import {
  usersManagementActions,
  appModuleActions,
} from '../views/users-management/store/action-types';
//import * as fromAuditLogs from '../views/audit-log/store/audit-log.reducer';

export interface SubscriptionsInfo {
  name?: string;
  expirationDate?: string;
  committedQuantity?: number;
}
export interface AppState {
  serverInfo: any;
  staacceptance: any;
  userInfo: any;
  subscriptionInfo: SubscriptionsInfo[];
  productCofig:any
}

export const initialAppState: AppState = {
  serverInfo: {},
  staacceptance: null,
  userInfo: {},
  subscriptionInfo: [],
  productCofig:{}
};

export const appReducer = createReducer<AppState>(
  initialAppState,

  // Get Server Info
  on(
    appModuleActions.getServerInfo,
    (state, action): AppState => ({
      ...state,
    })
  ),

  // Set Server Info
  on(appModuleActions.setServerInfo, (state, action): AppState => {
    return { ...state, serverInfo: action.serverInfo };
  }),

  on(
    appModuleActions.getSaasAddendumAcceptance,
    (state, action): AppState => ({
      ...state,
    })
  ),

  on(appModuleActions.setSaasAddendumAcceptance, (state, action): AppState => {
    return { ...state, staacceptance: action.saasAccepted };
  }),

  on(appModuleActions.getUserInfo, (state, action): AppState => {
    return { ...state };
  }),

  on(appModuleActions.setUserInfo, (state, action): AppState => {
    return { ...state, userInfo: action.userInfo };
  }),

  on(appModuleActions.getSubscriptionInfo, (state, action): AppState => {
    return { ...state };
  }),

  on(appModuleActions.setSubscriptionInfo, (state, action): AppState => {
    return { ...state, subscriptionInfo: action.subscriptionInfo };
  }),

  on(appModuleActions.setProductConfiguration, (state, action): AppState => {
    return { ...state, productCofig: action.productCofig };
  }),
);

export const reducers: ActionReducerMap<any> = {
  router: routerReducer,
  appReducer: appReducer,
  // auditLogs: fromAuditLogs.auditLogReducer
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [logger]
  : [];
