/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { Injectable } from '@angular/core';
import {
  FacGraphqlService,
  FacHttpClientService,
  FacQueryParameter,
  FacQueryStatement,
  FacTimeRange,
  FacGraphqlResponse,
} from '@foglight/angular-common';
import { Observable } from 'rxjs';
import moment from 'moment';
import { map, switchMap } from 'rxjs/operators';

export interface TopologyDomains {
  uniqueId: string;
  name: string;
  displayName: string;
  fullyQualifiedName: string; 
}
@Injectable({
  providedIn: 'root',
})
export class HomePageHttpService {
  constructor(
    private http: FacHttpClientService,
    private graphqlService: FacGraphqlService
  ) {}

  getFglamClientInstance(): Observable<any[]> {
    const queryParameter = this._createQueryParameter();
    const domainStatement = FacQueryStatement.standard()
      .withField('FglamClientInstance')
      .withSimpleSubFields('uniqueId', 'name','internal')
      .withQueryParameter(queryParameter);

    return this.graphqlService
      .query<any>(domainStatement)
      .pipe(map((it) => it?.instances));
  }

  private _createQueryParameter(): FacQueryParameter {
    const queryParameter = new FacQueryParameter(
      new FacTimeRange(
        moment().subtract(1, 'hour').toISOString(),
        moment().add(1, 'hour').toISOString()
      )
    );
    return queryParameter;
  }
  
  checkIsExternalFglamClientInstanceExists(): Promise<boolean> {
    return this.getFglamClientInstance().toPromise().then((instances) => {
      let externalFglam = instances.find(i => i.internal === false);
      if (externalFglam === undefined)
        return false;
      else
        return true;
    });
  }
}
