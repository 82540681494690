/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SidebarHttpService } from './sidebar-http.service';
import { NavItem } from '../models/nav-item';
import { skipEmpty } from '../../../shared/utils/skip-empty.operator';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  currentSelectedId = new Subject<string>();
  foldedState$: Observable<boolean>;
  editMode$: Observable<boolean>;
  isHover$: Observable<boolean>;
  navItems$: Observable<NavItem[]>;
  bookmarksItem$: Observable<NavItem>;
  configItems$: Observable<NavItem[]>;
  redirectLink$ = new BehaviorSubject<string>(null);
  deletedBookmark = new Subject<string>();
  tabsSelectIndex$ = new BehaviorSubject(0);
  sidebarWidth$ = new BehaviorSubject('240px');
  selectedNavItem = new Subject<boolean>();
  pinnedState$ = new BehaviorSubject<boolean>(false);
  bookmarkAdded = new Subject<boolean>();
  menuShouldBeFolded$ = new BehaviorSubject(true);
  menuItems$: Observable<NavItem> = new BehaviorSubject<any>(null);

  private iconsListMap: Map<string,string>;
  private foldedState$$ = new BehaviorSubject<boolean>(true);
  private editMode$$ = new BehaviorSubject<boolean>(false);
  private isHover$$ = new BehaviorSubject<boolean>(false);
  private navItems$$ = new BehaviorSubject<NavItem[]>(undefined);
  private bookmarksItem$$ = new BehaviorSubject<NavItem>(undefined);
  private configItems$$ = new BehaviorSubject<NavItem[]>(undefined);
  private menuItems$$ = new BehaviorSubject<NavItem>(undefined);

  _configurationItems: NavItem[] = [];
  /**
   * Constructor
   */
  constructor(private sidebarHttpService: SidebarHttpService) {
    this.foldedState$ = this.foldedState$$.asObservable().pipe(skipEmpty());
    this.editMode$ = this.editMode$$.asObservable().pipe(skipEmpty());
    this.isHover$ = this.isHover$$.asObservable().pipe(skipEmpty());
    this.navItems$ = this.navItems$$.asObservable().pipe(skipEmpty());
    this.bookmarksItem$ = this.bookmarksItem$$.asObservable().pipe(skipEmpty());
    this.menuItems$ = this.menuItems$$.asObservable().pipe(skipEmpty());
    this.configItems$ = this.configItems$$.asObservable().pipe(skipEmpty());
    this.sidebarWidth$.next(localStorage.getItem("sidebarWidth") ? localStorage.getItem("sidebarWidth") : '240px');
  }

  updateMenuItems(newData: NavItem) {
    this.menuItems$$.next(newData);
  }

  updateNavItems(items: NavItem[]) {
    this.navItems$$.next(items);
  }

  updateBooksmarksItem(item) {
    this.bookmarksItem$$.next(item);
  }

  updateConfigItems(items: NavItem[]) {
    this.configItems$$.next(items);
  }

  updateHoverState(state: boolean){
    this.isHover$$.next(state);
  }

  get configurationItems(): NavItem[] {
    return this._configurationItems;
  }

  set configurationItems(items: NavItem[]) {
    this._configurationItems = items;
  }

  getSidebarItems() {
    return this.sidebarHttpService.getLeftSideMenu();
  }

  disableMenus(menuIds: string[]) {
    return this.sidebarHttpService.disableMenus(menuIds);
  }

  changeSelected(selectedId: string) {
    this.currentSelectedId.next(selectedId);
  }

  setFoldedState(state: boolean) {
    this.foldedState$$.next(state);
  }

  setEditState(state: boolean) {
    this.editMode$$.next(state);
  }
}
