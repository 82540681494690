/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { Injectable } from '@angular/core';
import { AlarmTemplateData, TargetObject } from '../_models/alarm-template';

@Injectable({
  providedIn: 'root'
})
export class MockDataStoreService {
  static alarmTemplateDataKey = 'alarm-template';
  static setDefaultKey = 'alarm-template-set-default';

  constructor() {
    // this.cleanAllAlarmTemplates();
  }

  private getData<T>(key: string): T | null {
    const data = localStorage.getItem(key);
    if (data !== 'undefined') {
      return JSON.parse(data);
    }
    return null;
  }

  private saveData(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getAlarmTemplates(): AlarmTemplateData[] {
    return this.getData<AlarmTemplateData[]>(MockDataStoreService.alarmTemplateDataKey);
  }

  getConnections(domain: string): TargetObject[] {
    return this.getData<TargetObject[]>(domain);
  }

  addAlarmTemplate(data: AlarmTemplateData) {
    const list = [...this.getAlarmTemplates(), data];
    this.saveData(MockDataStoreService.alarmTemplateDataKey, list);
  }

  addConnections(domain: string, items: TargetObject[]) {
    const list = this.getConnections(domain);
    if (list != null && list.length > 0) {
      items.forEach(it => list.push(it));
      this.saveData(domain, list);
    } else {
      this.saveData(domain, items);
    }
  }

  deleteAlarmTemplate(data: AlarmTemplateData) {
    const result = this.getAlarmTemplates().filter(it => it.id !== data.id);
    this.saveData(MockDataStoreService.alarmTemplateDataKey, result);
  }

  deleteConnection(domain: string, data: TargetObject) {
    const result = this.getConnections(domain).filter(it => it.objectId !== data.objectId);
    this.saveData(domain, result);
  }

  addAlarmTemplates(data: AlarmTemplateData[]) {
    this.saveData(MockDataStoreService.alarmTemplateDataKey, data);
  }

  updateAlarmTemplate(data: AlarmTemplateData) {
    const list = this.getAlarmTemplates();
    const index = list.findIndex(it => it.id === data.id);
    list[index] = data;
    this.saveData(MockDataStoreService.alarmTemplateDataKey, list);
  }

  setDefualt(id: string) {
    const list = this.getAlarmTemplates().map(it => ({...it, isDefault: id === it.id}));
    this.saveData(MockDataStoreService.alarmTemplateDataKey, list);
  }

  cleanAllAlarmTemplates() {
    this.saveData(MockDataStoreService.alarmTemplateDataKey, undefined);
  }

  getDefaultData(): DefaultData[] {
    return this.getData<DefaultData[]>(MockDataStoreService.setDefaultKey);
  }

  saveDefaultData(data: DefaultData[]) {
    const list = this.getDefaultData() ?? [];
    data.forEach(it => {
      const object = list?.find(obj => obj?.domainName === it?.domainName);
      if (object) {
        object.isDefault = it?.isDefault;
        object.alarmTemplateId = it?.alarmTemplateId;
      } else {
        list.push(it);
      }

    });
    this.saveData(MockDataStoreService.setDefaultKey, list);
  }
}

// for mock data
export interface DefaultData {
  alarmTemplateId: string;
  isDefault: boolean;
  domainName: string;
}


