/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { EmptyComponent } from './empty.component';
import { SharedModule } from '../shared/shared.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { FacFormModule, FacTableModule,FacActionLinkModule, FacDropdownModule } from '@foglight/angular-common';
import { AboutDialogComponent } from './navbar/about-dialog/about-dialog.component';
import { FacNavbarService } from './navbar/navbar.service';
import { LayoutComponent } from './layout.component';
import { LegalNoticeComponent } from './navbar/about-dialog/legal-notice/legal-notice.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    NavbarComponent,
    EmptyComponent,
    AboutDialogComponent,
    LayoutComponent,
    LegalNoticeComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatTabsModule,
    RouterModule,
    SharedModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
    FacFormModule,
    FacTableModule,
    FacActionLinkModule,
    FacDropdownModule
  ],
  exports: [
    SidebarModule,
    NavbarComponent,
    EmptyComponent,
  ],
  providers: [
    FacNavbarService
  ]
})
export class LayoutModule {
}
