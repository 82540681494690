<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->

<a
  mat-list-item
  (contextmenu)="onContextMenu($event, item)"
  *ngIf="editModeInput || (!editModeInput && item?.visible)"
  [disableRipple]="true"
  [ngStyle]="{ 'padding-left': depth * 20 + 'px' }"
  [ngClass]="{
    selected:
      (selectedId === item.id && item.link && !editModeInput) || selected,
    'folded-sidebar': hiddenText,
    'parent-collapsed': !(
      isParentExpanded == 'expanded' || isParentExpanded == null
    ),
    'expanded-parent-child-item': parent?.isExpanded
  }"
>
  <div
    class="list-item"
    [class.list-item-mini]="hiddenText"
    [class.hidden-item]="!item.visible"
  >
    <div
      appDebounceClick
      [debounceTime]="700"
      class="list-item-display"
      (debounceClick)="!editModeInput && onItemSelected(item, $event)"
    >
      <!-- <fc-icon svgIcon="{{item.iconName}}"  class="route-icon"></fc-icon> -->
      <div
        class="list-item-expand-icon"
        (click)="onExpandCollapseClick(item); $event.stopPropagation()"
      >
        <span fxFlex *ngIf="!hiddenText">
          <span fxFlex></span>

          <mat-icon
            id="arrow"
            [ngClass]="{
              'hidden-expand': !(item.children && item.children.length)
            }"
            [@indicatorRotate]="
              item.isExpanded && itemExpandedOrSearchActive
                ? 'expanded'
                : 'collapsed'
            "
          >
            keyboard_arrow_right
          </mat-icon>
        </span>
      </div>
      <span class="item-icon" *ngIf="depth == 0 && !editModeInput"
        ><fc-icon [svgIcon]="item.iconName" class="route-icon"></fc-icon
      ></span>
      <div
        (mouseleave)="toggleMenu()"
        name="itemDisplay"
        [hidden]="hiddenText"
        #toolTipMenu
        [matTooltipDisabled]="isDisabled(toolTipMenu)"
        class="route-name left_munu_elements_qa"
        [class.edit-mode]="editModeInput"
        matTooltip="{{ item.description || item.displayName }}"
        matTooltipPosition="below"
        matTooltipClass="custom-tooltip"
        aria-label="Button that displays a tooltip in various positions"
      >
        {{ item.displayName }}
        <span *ngIf="item.displayName == 'Bookmarks'"
          >({{ item.children.length }})</span
        >
      </div>
    </div>
    <div class="show-hide-icon hide_icon_qa">
      <mat-icon
        class="visibility"
        *ngIf="
          editModeInput &&
          item.parent?.displayName !== 'Bookmarks' &&
          item.displayName !== 'Bookmarks'
        "
        (click)="toggleVisibility(item)"
        matTooltip="{{ getTooltip(item) }}"
        matTooltipPosition="below"
        matTooltipClass="custom-tooltip"
        >visibility_off
      </mat-icon>
      <mat-icon
        class="delete"
        *ngIf="item.parent?.displayName === 'Bookmarks'"
        (click)="onDeleteClick(item)"
        [matTooltip]="item.tooltip"
        [matTooltipPosition]="'right'"
        >delete</mat-icon
      >
    </div>
  </div>
</a>
<div
  *ngIf="editModeInput || (!editModeInput && item?.visible)"
  [@childrenExpandCollapse]="
    item.isExpanded && itemExpandedOrSearchActive
      ? 'childrenExpanded'
      : 'childrenCollapsed'
  "
  [ngClass]="{
    'children-collapsed-sidebar':
      hiddenText && (expandedItemIndex === itemIndex || isSearchActive)
  }"
>
  <fc-menu-list-item
    *ngFor="let child of item.children; let i = index"
    [item]="child"
    [depth]="depth + 1"
    [parent]="item"
    [ngStyle]="{ height: item.isExpanded ? 'auto' : '0px' }"
    [selectedId]="selectedId"
    [isParentExpanded]="item.isExpanded ? 'expanded' : 'collapsed'"
  >
  </fc-menu-list-item>
</div>
