<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->

<span class="snack-bar-container" *ngIf="data.line">
    <mat-icon class="info-icon">info</mat-icon>
    {{data.line.length}} {{ data.text }}
    <span class="dismiis-button" (click)="dismissSnack()"><mat-icon>clear</mat-icon></span>
</span>

<span class="snack-bar-container" *ngIf="!data.line">
  <mat-icon class="info-icon">info</mat-icon>
  {{ data.text }}
  <span class="dismiis-button" (click)="dismissSnack()"><mat-icon>clear</mat-icon></span>
</span>
