/*
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  NavItem,
  NoWcfLinkToAuiLinkMapping,
  WcfLinkToAuiLinkMapping,
  PagesNotInLeftMenu,
} from '../../../models/nav-item';
import { SidebarService } from '../../../services/sidebar.service';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { DeleteBookmarkDialogComponent } from './../../delete-bookmark-dialog/delete-bookmark-dialog.component';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import lod from 'lodash';
import { Store } from '@ngrx/store';
import { isCloudFlagSelector, isCloudUser } from 'src/app/store/app.selectors';
import { UtilService } from '@services/util.service';
import { FacUserService } from '@foglight/angular-common';
import { appModuleActions } from 'src/app/views/users-management/store/action-types';

@UntilDestroy()
@Component({
  selector: 'fc-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state(
        'collapsed',
        style({ transform: 'rotate(0deg), transform-origin: center center ' })
      ),
      state('expanded', style({ transform: 'rotate(90deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ]),
    trigger('childrenExpandCollapse', [
      state(
        'childrenCollapsed',
        style({ height: '0', opacity: '0', visibility: 'hidden' })
      ),
      transition(
        'childrenExpanded <=> childrenCollapsed',
        animate('0.3s ease-in')
      ),
    ]),
  ],
})
export class MenuListItemComponent implements OnInit, OnChanges {
  hiddenText = false;
  editModeInput = false;
  itemExpandedOrSearchActive = false;
  tooltip = '';
  selected = false;
  @Output() expandEvent = new EventEmitter();
  @Input() expandedItemIndex: number;
  @Input() itemIndex: number;
  @Input() item: NavItem;
  @Input() menuItems: NavItem[];
  @Input() parent: NavItem;
  @Input() depth: number;
  @Input() selectedId: string;
  @Input() isSearchActive: boolean;
  @Input() isParentExpanded: string;
  @Input() currentId: string;
  itemLink: any;
  navItems: NavItem[] = [];
  link: any;
  backForward = false;

  @HostBinding('class.expanded-item') get expandedItem() {
    return (
      (this.item.isExpanded && this.item.children?.length > 0) ||
      this.isParentExpanded
    );
  }

  constructor(
    private sidebarService: SidebarService,
    private router: Router,
    private titleService: Title,
    private dialog: MatDialog,
    private location: Location,
    private store: Store,
    private utilService: UtilService,
    private userService: FacUserService
  ) {
    this.sidebarService.sidebarWidth$.subscribe((res) => {
      const elems = document.getElementsByName('itemDisplay');
      for (let i = 0; i < elems.length; i++) {
        if (parseInt(res.slice(0, res.indexOf('p'))) < 200) {
          res = '200px';
        }
        if (parseInt(res.slice(0, res.indexOf('p'))) > 400) {
          res = '400px';
        }
        elems[i].style.width = 'calc(' + res + ' - 100px)';
      }
    });
    this.location.subscribe((event) => {
      let filteredNavItem = [];
      let link = event.url.split('/')[1];
      this.selectedId = '';
      this.selected = false;
      this.item.isExpanded = false;
      const pagesNotinLeftMenu = PagesNotInLeftMenu.some((page) =>
        event.url.includes(page)
      );
      if (pagesNotinLeftMenu) {
        sessionStorage.setItem('selectedItem', '');
      }
      if (this.item.link) {
        if (event.url.includes('wcf?')) {
          if (event.url.split('wcf?')[1] === this.item.link.split('wcf?')[1]) {
            sessionStorage.setItem('itemLink', '' + this.item.link);
            sessionStorage.setItem('selectedItem', '' + this.item.id);
            const itemLink = sessionStorage.getItem('itemLink');
            const item = this.filterItemsByLink(this.item.link, this.item);
            if (this.hiddenText) {
              this.item.isExpanded = false;
              if (this.item.link === itemLink) {
                this.selectedId = sessionStorage.getItem('selectedItem');
                this.selected = true;
                this.sidebarService.changeSelected(this.selectedId);
                this.sidebarService.selectedNavItem.next(true);
              } else {
                if (this.item.children.length > 0) {
                  if (item.length > 0) {
                    this.selected = true;
                  } else {
                    this.selected = false;
                  }
                }
              }
              const parent = this.recurse(this.item.link, this.item);
              if (parent) {
                this.selectedId = parent[0].id;
                this.selected = true;
              }
            } else {
              if (this.item.link === itemLink) {
                this.selectedId = sessionStorage.getItem('selectedItem');
                this.selected = true;
                this.sidebarService.changeSelected(this.selectedId);
                this.sidebarService.selectedNavItem.next(true);
              } else {
                this.selected = false;
              }
              if (item.length > 0) {
                this.expandParent(this.item);
                this.item.isExpanded = true;
              } else {
                this.item.isExpanded = false;
              }
            }
          }
        } else {
          if (NoWcfLinkToAuiLinkMapping[this.item.id]) {
            this.item.link = NoWcfLinkToAuiLinkMapping[this.item.id];
          }         

          if (event.url.includes(this.item.link)) {
            sessionStorage.setItem('itemLink', '' + this.item.link);
            sessionStorage.setItem('selectedItem', '' + this.item.id);
            const itemLink = sessionStorage.getItem('itemLink');
            const item = this.filterItemsByLink(this.item.link, this.item);
            if (this.hiddenText) {
              this.item.isExpanded = false;
              if (this.item.link === itemLink) {
                this.selectedId = sessionStorage.getItem('selectedItem');
                this.selected = true;
                this.sidebarService.changeSelected(this.selectedId);
                this.sidebarService.selectedNavItem.next(true);
              } else {
                if (this.item.children.length > 0) {
                  if (item.length > 0) {
                    this.selected = true;
                  } else {
                    this.selected = false;
                  }
                }
              }
            } else {
              if (this.item.link === itemLink) {
                this.selectedId = sessionStorage.getItem('selectedItem');
                this.selected = true;
                this.sidebarService.changeSelected(this.selectedId);
                this.sidebarService.selectedNavItem.next(true);
              } else {
                this.selected = false;
              }
              if (item.length > 0) {
                this.expandParent(this.item);
                this.item.isExpanded = true;
              } else {
                this.item.isExpanded = false;
              }
            }
          }
        }
        if (this.item.children) {
          filteredNavItem = this.filterChildItemsByUrlLink(
            link,
            this.item.children
          );
          if (filteredNavItem.length > 0) {
            if (this.expandedItemIndex !== this.itemIndex) {
              this.onExpandCollapseClick(this.item);
            }
          }
        }
      }
    });
  }

  ngOnInit() {
    this.sidebarService.navItems$
      .pipe(untilDestroyed(this))
      .subscribe((items) => {
        this.navItems = lod.cloneDeep(items);
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: any) => {
        sessionStorage.setItem(
          'browserRefresh',
          JSON.stringify(!this.router.navigated)
        );

        if (event && event.navigationTrigger === 'popstate') {
          if (event.restoredState) {
            sessionStorage.setItem('backForward', 'true');
            this.backForward = true;
          }

          this.selectedId = '';
          this.selected = false;
          let id = '';
          let filteredNavItem = [];
          let link = event.url.split('/')[1];
          this.setPageTitleOnBackClick(link);
          if (!this.item.parent) {
            this.navItems.forEach((item) => {
              if (event.url.includes('wcf?')) {
                filteredNavItem = this.filterChildItemByLink(
                  link,
                  item.children
                );
                if (filteredNavItem.length > 0) {
                  id = filteredNavItem[0].id;
                  sessionStorage.setItem('selectedItem', '' + id);
                }
              } else {
                link = link.includes('-aui-')
                  ? link.replace('-aui-', ':')
                  : link;
                id = link;
                filteredNavItem = this.filterChildItemByLink(
                  link,
                  item.children
                );
                sessionStorage.setItem('selectedItem', '' + id);
              }
            });
            let currentlySelectedId = sessionStorage.getItem('selectedItem');
            if (currentlySelectedId) {
              if (
                this.hasNestedChild(currentlySelectedId, this.item.children)
              ) {
                this.item.isExpanded = true;
              } else {
                this.item.isExpanded = false;
              }
            }
          }
          if (
            link ===
            'wcf?name=general-view-aui-wrapper&viewId=system:administration_home.159'
          ) {
            sessionStorage.removeItem('selectedItem');
            this.item.isExpanded = false;
          }
          if (this.item.id === sessionStorage.getItem('selectedItem')) {
            this.selectedId = sessionStorage.getItem('selectedItem');
            this.selected = true;
            this.sidebarService.changeSelected(this.selectedId);
            this.sidebarService.selectedNavItem.next(true);
          } else {
            this.selectedId = '';
            this.selected = false;
          }
        } else {
          this.backForward = false;
          sessionStorage.setItem('backForward', 'false');
        }
      });

    if (this.depth === undefined) {
      this.depth = 0;
    }
    if (this.item.id !== sessionStorage.getItem('selectedItem')) {
      this.selected = false;
      this.selectedId = sessionStorage.getItem('selectedItem');
    } else {
      this.selected = true;
      this.selectedId = '';
    }
    this.item.parent = this.parent;
    this.sidebarService.foldedState$
      .pipe(untilDestroyed(this))
      .subscribe((foldedState: boolean) => {
        const itemId = sessionStorage.getItem('selectedItem');
        if (this.item.children) {
          const item = this.filterItemsById(itemId, this.item.children);
          this.hiddenText = foldedState;
          this.itemExpandedOrSearchActive = !(
            this.expandedItemIndex !== this.itemIndex &&
            this.isSearchActive === false &&
            foldedState
          );
          if (foldedState) {
            this.item.isExpanded = false;
            if (this.item.id === itemId) {
              this.sidebarService.changeSelected(itemId);
              this.selected = true;
            } else if (this.item.children.length > 0 && item.length > 0) {
              this.selected = true;
              if (item.findIndex((i) => i.id === itemId) === -1) {
                this.selected = false;
              }
            } else {
              this.selected = false;
            }
          } else {
            if (this.item.id === itemId) {
              this.selected = true;
            } else {
              this.selected = false;
            }
            if (item.length > 0) {
              this.item.isExpanded = true;
              if (
                item.findIndex((i) => i.id === itemId) === -1 &&
                this.item.id !== itemId
              ) {
                this.item.isExpanded = false;
                this.selected = false;
              }
            } else {
              // temporarily commented
              // this.item.isExpanded = false;
            }
          }
        } else {
          if (
            this.item.id === itemId &&
            this.item.parent.id === 'dummy-parent'
          ) {
            this.selected = true;
          } else {
            this.selected = false;
          }
        }
      });

    this.sidebarService.editMode$
      .pipe(untilDestroyed(this))
      .subscribe((editModeState: boolean) => {
        this.editModeInput = editModeState;
      });

    this.sidebarService.selectedNavItem
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res) {
          this.selected = false;
          this.selectedId = sessionStorage.getItem('selectedItem');
        }
      });
  }

  setPageTitleOnBackClick(link: string) {
    let id = '';
    if (link.indexOf('wcf?') != -1) {
      let viewId = link.split('viewId=');
      id = viewId[1];
    } else {
      id = link.indexOf('-aui-') != -1 ? link.replace('-aui-', ':') : link;
    }
    if (id) {
      id = id.replace('?bcClick=True', '');
    }
    let pageTitle = '';
    if (id == 'home') {
      pageTitle = 'Welcome';
    } else if (id != '') {
      let selectedItem = this.navItems.find(
        (items) => (items.link || '').indexOf(id) != -1
      );
      if (!selectedItem) {
        let childrenItems = [];
        this.navItems.forEach((items) => {
          if (items.children && items.children.length > 0) {
            childrenItems = childrenItems.concat(items.children);
          }
        });

        selectedItem = childrenItems.find(
          (items) => (items.link || '').indexOf(id) != -1
        );
        if (!selectedItem) {
          let level3childrenItems = [];

          childrenItems.forEach((childrenItem) => {
            if (childrenItem.children && childrenItem.children.length > 0) {
              level3childrenItems = level3childrenItems.concat(
                childrenItem.children
              );
            }
          });
          selectedItem = level3childrenItems.find(
            (items) => (items.link || '').indexOf(id) != -1
          );
        }
      }
      if (selectedItem) {
        pageTitle = selectedItem.displayName;
      }
    }
    if (pageTitle != '') {
      this.titleService.setTitle(`${pageTitle} - Foglight`);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.expandedItemIndex !== this.itemIndex &&
      this.isSearchActive === false &&
      !(this.expandedItemIndex === undefined && this.item.isExpanded) &&
      !this.backForward
    ) {
      this.item.isExpanded = false;
    }
    if (changes.selectedId) {
      this.selected = false;
    }
  }

  hasNestedChild(searchData, target, accum = []) {
    target.forEach((f) => {
      if (f.children) {
        this.hasNestedChild(searchData, f.children, accum);
      }
      if (f.id.includes(searchData)) {
        accum.push(f);
      }
    });
    if (accum.length > 0) {
      return true;
    }
    return false;
  }

  recurse(searchData, target, accum = []) {
    if (target.parent === undefined) {
      accum.push(target);
    } else {
      this.recurse(searchData, target.parent, accum);
    }
    return accum;
  }

  filterItemsById(searchData, target, accum = []) {
    target.forEach((f) => {
      if (f.children && f.children.length > 0) {
        this.filterItemsById(searchData, f.children, accum);
      }
      if (f.id.includes(searchData)) {
        accum.push(f);
      }
    });
    return accum;
  }

  filterChildItemsByUrlLink(searchData, target, accum = []) {
    target.forEach((f) => {
      if (searchData.includes(f.link)) {
        accum.push(f);
      }
      if (f.children) {
        this.filterChildItemsByUrlLink(searchData, f.children, accum);
      }
    });
    return accum;
  }

  filterItemsByLink(searchData, target, accum = []) {
    if (target.parent) {
      this.filterItemsByLink(searchData, target.parent, accum);
    }
    if (target.link === searchData) {
      accum.push(target);
    }
    return accum;
  }

  expandParent(item) {
    if (item.parent) {
      item.parent.isExpanded = true;
      this.expandParent(item.parent);
    } else {
      this.expandEvent.emit(this.itemIndex);
    }
  }

  filterChildItemByLink(searchData, target, accum = []) {
    target.forEach((f) => {
      if (f.link === searchData) {
        accum.push(f);
      }
      if (f.children) {
        this.filterItemsByLink(searchData, f.children, accum);
      }
    });
    return accum;
  }
  onContextMenu(event, item: NavItem) {
    event.preventDefault();
    if (item.link) {
      window.open('aui/' + item.link, '_blank');
    }
  }
  onItemSelected(item: NavItem, event) {
    this.onExpandCollapseClick(item);
    let newNavItem;

    if (!item.link && item.children?.length > 0 && item.children[0].link) {
      newNavItem = item.children[0];
    } else if (item.link) {
      newNavItem = item;
    } else if (!item.link && NoWcfLinkToAuiLinkMapping[item.id]) {
      this.store
        .select(isCloudFlagSelector)
        .pipe(untilDestroyed(this))
        .subscribe((isCloudEnv) => {
          if (isCloudEnv) {
            this.userService
              .getUserInfo()
              .pipe(untilDestroyed(this))
              .subscribe((userInfo) => {
                this.store.dispatch(
                  appModuleActions.setUserInfo({ userInfo: userInfo })
                );
                this.store
                  .select(isCloudUser)
                  .pipe(untilDestroyed(this))
                  .subscribe((isCloudUser) => {
                    if (isCloudUser) {
                      item.link = NoWcfLinkToAuiLinkMapping[item.id];
                      this.selectNavItem(item);
                    }
                  });
              });
          }
          else{
            item.link = NoWcfLinkToAuiLinkMapping[item.id];
            this.selectNavItem(item);
          }
        });
    }
    this.selectNavItem(newNavItem);
  }

  onExpandCollapseClick(item: NavItem) {
    if (item && item.children && item.children.length > 0) {
      this.item.isExpanded = !this.item.isExpanded;
      this.expandEvent.emit(this.itemIndex);
    }
  }

  hasVisibleChildren() {
    return this.item.children.some((element) => element.visible);
  }

  getTooltip(item: NavItem) {
    return item.visible ? 'Hide' : 'Show';
  }

  toggleVisibility(item: NavItem) {
    const newState = !item.visible;
    item.visible = newState;

    this.changeChildrenVisibilityState(item, newState);

    if (newState) {
      this.changeParentVisibility(item);
    }

    this.sidebarService.updateMenuItems(item.parent ? item.parent : item);
  }

  onDeleteClick(item: NavItem) {
    if (item.source !== 'console') {
      this.dialog.open<DeleteBookmarkDialogComponent>(
        DeleteBookmarkDialogComponent,
        {
          panelClass: 'fc-border-dialog-panel',
          height: 'auto',
          width: 'auto',
          data: item,
        }
      );
    }
  }

  toggleMenu() {
    this.sidebarService.updateHoverState(true);
  }

  isDisabled(e) {
    return e.scrollWidth <= e.clientWidth;
  }

  reloadCurrentRoute(item: NavItem) {
    const currentURL = this.router.url.substring(1);
    if (item.link === currentURL || item.link.startsWith('wcf')) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl(item.link);
      });
    }
  }

  private selectNavItem(item: NavItem) {
    if (item) {
      if (
        item.parent &&
        item.parent.id === 'dummy-parent' &&
        item.link.split('/')[1] === 'notification-management'
      ) {
        localStorage.setItem(
          'currentTab',
          item.link.substring(item.link.lastIndexOf('/') + 1)
        );
      }
      let link = sessionStorage.getItem('selectedItem');
      sessionStorage.setItem('selectedItem', '' + item.id);
      this.sidebarService.changeSelected(item.id);
      this.titleService.setTitle(`${item.displayName} - Foglight`);
      if (item.link !== 'notification-management') {
        if (item.link.includes('wcf')) {
          if (this.link === item.link) {
            this.reloadCurrentRoute(item);
          } else {
            this.link = '';
            let viewId = item.link.split('viewId=');
            let auiLink = WcfLinkToAuiLinkMapping[viewId[1]] || '';
            if (auiLink !== '') {
              this.store
                .select(isCloudFlagSelector)
                .pipe(untilDestroyed(this))
                .subscribe((isCloudEnv) => {
                  if (isCloudEnv) {
                    this.store
                      .select(isCloudUser)
                      .pipe(untilDestroyed(this))
                      .subscribe((isCloudUser) => {
                        if (isCloudUser) {
                          this.router.navigateByUrl(
                            WcfLinkToAuiLinkMapping[viewId[1]]
                          );
                          this.link = WcfLinkToAuiLinkMapping[viewId[1]];
                        } else {
                          this.router.navigateByUrl(item.link);
                          this.link = item.link;
                        }
                      });
                  } else {
                    this.router.navigateByUrl(WcfLinkToAuiLinkMapping[viewId[1]]);
                    this.link = WcfLinkToAuiLinkMapping[viewId[1]];
                  }
                });
            } else {
              this.router.navigateByUrl(item.link);
              this.link = item.link;
            }
          }
        } else {
          if (link === item.link) {
            this.reloadCurrentRoute(item);
          } else {
            this.link = '';
            this.router.navigateByUrl(item.link);
            this.link = item.link;
          }
        }
      } else {
        this.router.navigate([
          '/notification-management/notification-channels',
        ]);
        localStorage.setItem('currentTab', 'notification-channels');
      }
      //The commented section is about the notification-management page
      this.sidebarService.selectedNavItem.next(true);
      this.utilService.clearBreadCrumbHistory();
    }
  }

  private changeChildrenVisibilityState(item: NavItem, isVisible: boolean) {
    item.visible = isVisible;
    if (item.children) {
      item.children.forEach((child) => {
        this.changeChildrenVisibilityState(child, isVisible);
      });
    }
  }

  private changeParentVisibility(item: NavItem) {
    if (item.parent) {
      item.parent.visible = true;
      this.changeParentVisibility(item.parent);
    }
  }
}
