/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/

import { 
    Directive, 
    EventEmitter, 
    HostListener, 
    Input, 
    OnDestroy, 
    OnInit, 
    Output 
  } from '@angular/core';
  import { Subject, Subscription } from 'rxjs';
  import { debounceTime } from 'rxjs/operators';
  
  @Directive({
    selector: '[appDebounceClick]'
  })
  export class DebounceClickDirective implements OnInit, OnDestroy {
    @Input() 
    debounceTime = 500;
  
    @Output() 
    debounceClick = new EventEmitter();
    
    private clicks = new Subject();
    private subscription: Subscription;
  
    constructor() { }
  
    ngOnInit() {
      this.subscription = this.clicks.pipe(
        debounceTime(this.debounceTime)
      ).subscribe(e => this.debounceClick.emit(e));
    }
  
    ngOnDestroy() {
      this.subscription.unsubscribe();
    }
  
    @HostListener('click', ['$event'])
    clickEvent(event) {
      event.preventDefault();
      event.stopPropagation();
      this.clicks.next(event);
    }
  }