/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'fc-single-spa-app-loader',
  templateUrl: './single-spa-app-loader.component.html',
  styleUrls: ['./single-spa-app-loader.component.scss'],
})
export class SingleSpaAppLoaderComponent implements OnInit, AfterViewInit {
  theme = 'light-theme';
  constructor(private ref: ChangeDetectorRef) {}

  ngAfterViewInit() {
    window.addEventListener('single-spa:routing-event', (evt) => {
      let loadingDivList = document.querySelectorAll('.loader-active-spa');
      if (loadingDivList && loadingDivList.length > 0) {
        loadingDivList.forEach((loadingDiv) => {
          if (loadingDiv) {
            (loadingDiv as HTMLElement).style.display = 'none';
            this.ref.detectChanges();
          }
        });
      }
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('theme')) {
      this.theme = localStorage.getItem('theme');
    }
  }
}
