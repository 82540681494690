/*
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

 import {Resolve} from "@angular/router";
 import { Observable, of } from "rxjs";
 import { Injectable } from "@angular/core";
 import { Store } from '@ngrx/store';
 import * as appModuleActions from '../store/app.actions'
import { isCloudUser} from "../store/app.selectors";
import { map, switchMap } from "rxjs/operators";
import { FacHttpClientService } from "@foglight/angular-common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
 
@UntilDestroy()
@Injectable()
 export class StaSaasAcceptanceScreenResolver implements Resolve<any> {
  isCloudUser = false;  
    constructor(private store: Store,private httpClient: FacHttpClientService) {}

  resolve(): Observable<boolean> {
    return this.httpClient.get(`/aui/api/v1/portal/product`).pipe(switchMap((serverInfo)=>{
      this.store.dispatch(appModuleActions.setServerInfo({ serverInfo: serverInfo }));
      let isCloud = serverInfo['isCloud'];        
      if (isCloud) {
        return this.httpClient.get(`/aui/api/v1/portal/user`).pipe(map((userInfo)=>{
          this.store.dispatch(appModuleActions.setUserInfo({userInfo : userInfo})); 
          this.store.select(isCloudUser).pipe(untilDestroyed(this)).subscribe((cloudUser)=>{               
          this.isCloudUser = cloudUser;
          })
          if(this.isCloudUser){     
            this.store.dispatch(appModuleActions.getSaasAddendumAcceptance());
            return true;
          }
          else{
            return false;
          }
        }))
      }
      else{         
        return of(true);
      }
    }))


  }


}
