<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->
<ng-container *ngIf="showWelcomePage">
<div class="welcome-container" [ngClass]="theme">
  <fac-breadcrumb-wrapper></fac-breadcrumb-wrapper>
  <div class="page-title">
    <label class="title-label">Welcome to Foglight<span *ngIf="isCloudEnv$ | async"> Cloud!</span></label>
  </div>
  <div class="external-fglam" *ngIf="isExternalfglamRequired">
    <label class="external-fglam-label">
      To start monitoring, download the Foglight Agent Manager and install it on
      your environment.
    </label>
    <button #agentBtn
      agent-button
      [type]="'button'"
      (click)="menu.toggle($event)"
      (keyup.enter)="menu.toggle($event)"
      (keydown.Tab)="menu.hide()"
    >
      <label class="agent-btn-label">
        Download
        <fc-svg-icon
          *ngIf="!arrowState"
          class="arrow-up"
          svgName="arrow-up"
        ></fc-svg-icon>
        <fc-svg-icon
          *ngIf="arrowState"
          class="arrow-down"
          svgName="arrow-down"
        ></fc-svg-icon>
      </label>
      <fac-dropdown
        #menu
        [popup]="'true'"
        [model]="items"
        (onSelectedItem)="onItemSelect($event, menu)"
        (keyup.enter)="onItemSelect($event, menu)"
        (onShow)="onDropdownShow()"
        (onHide)="onDropdownHide(menu)"
        [selectedItem]="selectedItem"
      ></fac-dropdown>
    </button>
    <button *ngIf="isCloudEnv$ | async" agent-button [type]="'button'" (click)="goToVideo()">
      <label class="agent-btn-label"> Watch Video </label>
    </button>
  </div>
  <div class="welcome-page-content">
    <div class="space1">&nbsp;</div>
    <div class="left-content">
      <div *ngIf="isExternalfglamRequired; else fglamExist">
        <label class="left-text-label">
          Begin discovering all of Foglight<span *ngIf="isCloudEnv$ | async"> Cloud</span> powerful capabilities by
          connecting your own data and start resolving issues today.
        </label>
        <div style="padding-top: 13px">
          <label class="left-secondary-text-label">
            To begin monitoring your environment:
          </label>
          <div class="steps">
            <div
              class="circle"
              [ngStyle]="{
                'background-color':
                  theme === 'light-theme'
                    ? 'rgba(127, 127, 127, 0.16)'
                    : 'rgba(204, 204, 204, 0.32)'
              }"
            >
              1
            </div>
            <label class="left-secondary-text-label text-steps">
              Download the Foglight Agent Manager
            </label>
          </div>
          <div class="steps">
            <div
              class="circle"
              [ngStyle]="{
                'background-color':
                  theme === 'light-theme'
                    ? 'rgba(127, 127, 127, 0.16)'
                    : 'rgba(204, 204, 204, 0.32)'
              }"
            >
              2
            </div>
            <label class="left-secondary-text-label text-steps">
              Install the Agent Manager on your system
            </label>
          </div>
          <div class="steps">
            <div
              class="circle"
              [ngStyle]="{
                'background-color':
                  theme === 'light-theme'
                    ? 'rgba(127, 127, 127, 0.16)'
                    : 'rgba(204, 204, 204, 0.32)'
              }"
            >
              3
            </div>
            <label class="left-secondary-text-label text-steps">
              Start monitoring your Databases and Servers
            </label>
          </div>
        </div>
        <div style="padding-top: 16px" *ngIf="((isCloudEnv$ | async) && !isCloudUser) || !(isCloudEnv$ | async)">
          <label *ngIf="isCloudEnv$ | async" class="left-secondary-text-label">
            Click the links below to get started!
          </label>
          <div *ngIf="isCloudEnv$ | async" class="download-layout">
            <a
              tabindex="0"
              class="monitor-link"
              href="https://docs.foglightcloud.com/gettingstartedfoglightcloud/installationoffoglightagentmanager/video"
              target="_blank"
            >
              <mat-icon
                [svgIcon]="'fac-arrow-path'"
                class="mat-path-icon"
              ></mat-icon>
              <span> Watch Video </span>
            </a>
          </div>
          <div class="download-layout">
            <span class="left-secondary-text-label"
              >Download agent manager:
            </span>
            <div class="monitor-link">
              <a
                tabindex="0"
                class="download-agent-styles"
                (click)="onItemSelect(items[0])"
              >
                <fc-svg-icon
                  class="icon-styles"
                  svgName="windows_icon"
                ></fc-svg-icon>
                <span class="left-secondary-text-label">For Windows</span>
              </a>
              <a
                tabindex="0"
                class="download-agent-styles"
                (click)="onItemSelect(items[1])"
              >
                <fc-svg-icon
                  class="icon-styles"
                  svgName="linux_icon"
                ></fc-svg-icon>
                <span class="left-secondary-text-label">For Linux</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ng-template #fglamExist>
        <label class="left-text-label">
          Discover more powerful capabilities of Foglight <span *ngIf="isCloudEnv$ | async">Cloud</span> by monitoring
          your Databases and Servers.
        </label>
        <a
          tabindex="0"
          class="monitor-link"
          *ngIf="isCloudEnv$ | async"
          style="padding-top: 20px"
          (click)="
            learnMoreClick(
              'https://docs.foglightcloud.com/gettingstartedfoglightcloud/'
            )
          "
        >
          <mat-icon
            [svgIcon]="'fac-arrow-path'"
            class="mat-path-icon"
          ></mat-icon>
          <span> Learn more </span>
        </a>
        <div class="download-layout" *ngIf="((isCloudEnv$ | async) && !isCloudUser) || !(isCloudEnv$ | async)">
          <span class="left-secondary-text-label"
            >Download agent manager:
          </span>
          <div class="monitor-link">
            <a
              tabindex="0"
              class="download-agent-styles"
              (click)="onItemSelect(items[0])"
            >
              <fc-svg-icon
                class="icon-styles"
                svgName="windows_icon"
              ></fc-svg-icon>
              <span class="left-secondary-text-label">For Windows</span>
            </a>
            <a class="download-agent-styles" (click)="onItemSelect(items[1])">
              <fc-svg-icon
                class="icon-styles"
                svgName="linux_icon"
              ></fc-svg-icon>
              <span class="left-secondary-text-label">For Linux</span>
            </a>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="space3">&nbsp;</div>
    <div class="midLine">&nbsp;</div>
    <div class="space3">&nbsp;</div>
    <div class="mid-content">
      <span class="mid-title">Monitor your Databases and Servers</span>

      <div class="monitor-content">        
        <div class="monitor" *ngIf="((isCloudEnv$ | async) || (!(isCloudEnv$ | async) && isDbcartridgeInstalled))">          
          <button
            tabindex="-1"
            monitor-btn
            [type]="'button'"
          >
            <mat-icon
              [svgIcon]="'fac-wc-database'"
              class="mat-monitor-icon"
            ></mat-icon>
          </button>
          <div class="monitor-title">Database</div>

          <a
            tabindex="0"
            class="monitor-link"
            (click)="redirectToMonitor('system:dbwc.16', 'Database', 'Database')"
          >
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span> Monitor Database</span>
          </a>
          <a
            tabindex="0"
            class="monitor-link"
            style="padding-top: 4px"
            (click)="redirectToMonitor('foglight-sql-profiler/query-insights', 'Database', 'Query Insights', true)"

          >
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span> Query Insights</span>
          </a>
        </div>
        <div class="monitor">
          <button
            tabindex="-1"
            monitor-btn
            [type]="'button'"
          >
            <mat-icon
              [svgIcon]="'fac-wc-hosts'"
              class="mat-monitor-icon"
            ></mat-icon>
          </button>

          <div class="monitor-title">Hosts</div>

          <a
            tabindex="0"
            class="monitor-link"
            (click)="
              redirectTo(
                'wcf?name=general-view-aui-wrapper&viewId=system:infrastructure.mainViewOfTheModule',
                'Infrastructure'
              )
            "
          >
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span> Monitor Hosts</span>
          </a>
          <a
          tabindex="0"
          class="monitor-link"
          style="padding-top: 4px"
          (click)="redirectToMonitor('system:selector.66', 'Infrastructure', 'Environment Overview')"        
        >
          <mat-icon
            [svgIcon]="'fac-arrow-path'"
            class="mat-path-icon"
          ></mat-icon>
          <span>Environment Overview</span>
        </a>
        </div>
      </div>
      <div class="monitor-content">
        <div class="monitor" *ngIf="((isCloudEnv$ | async) || (!(isCloudEnv$ | async) && isEvolveCartridgeInstalled))">
          <button
            tabindex="-1"
            monitor-btn
            [type]="'button'"
          >
            <mat-icon
              [svgIcon]="'fac-wc-vmware'"
              class="mat-monitor-icon"
            ></mat-icon>
          </button>
          <div class="monitor-title">VMware</div>
          <a
            tabindex="0"
            class="monitor-link"
            (click)=" 
              redirectToMonitor(
                'system:virtualvmw_monitoring.2',
                'Infrastructure',
                'VMware'
              )"
          >
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span> Monitor VMware</span>
          </a>
        </div>
        <div class="monitor" *ngIf="((isCloudEnv$ | async) || (!(isCloudEnv$ | async) && isEvolveCartridgeInstalled))">
          <button
            tabindex="-1"
            monitor-btn
            [type]="'button'"
          >
            <mat-icon
              [svgIcon]="'fac-wc-hyperv'"
              class="mat-monitor-icon"
            ></mat-icon>
          </button>
          <div class="monitor-title">HyperV</div>
          <a
            tabindex="0"
            class="monitor-link"
            (click)="
              redirectToMonitor(
                'system:HyperV_monitoring.4',
                'Infrastructure',
                'Hyper-V'
              )"
          >
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span> Monitor HyperV</span>
          </a>
        </div>
      </div>
      <div class="monitor-content">
        <div class="monitor" *ngIf="((isCloudEnv$ | async) || (!(isCloudEnv$ | async) && isEvolveCartridgeInstalled))">
          <button
            tabindex="-1"
            monitor-btn
            [type]="'button'"
          >
            <mat-icon
              [svgIcon]="'fac-wc-cloud'"
              class="mat-monitor-icon"
            ></mat-icon>
          </button>
          <div class="monitor-title">Cloud</div>
          <a
            tabindex="0"
            class="monitor-link"
            (click)="
              redirectToMonitor(
                'system:cloud_manager.cloud',
                'Infrastructure',
                'Cloud'
            )"
          >
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span> Monitor Cloud</span>
          </a>
        </div>
        <div class="monitor" *ngIf="((isCloudEnv$ | async) || (!(isCloudEnv$ | async) && isEvolveCartridgeInstalled))">
          <button
            tabindex="-1"
            monitor-btn
            [type]="'button'"
          >
            <mat-icon
              [svgIcon]="'fac-wc-container'"
              class="mat-monitor-icon"
            ></mat-icon>
          </button>
          <div class="monitor-title">Container</div>
          <a
            tabindex="0"
            class="monitor-link"
            (click)="
              redirectToMonitor(
                'system:docker.dockerHomePage',
                'Infrastructure',
                'Container'
              )"
          >
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span> Monitor Container</span>
          </a>
        </div>
      </div>
    </div>
    <div class="space3">&nbsp;</div>
    <div class="midLine">&nbsp;</div>
    <div class="space3">&nbsp;</div>
    <div class="right-content">
      <span *ngIf="isCloudEnv$ | async" class="right-title">Invite users and manage notifications</span>
      <span *ngIf="!(isCloudEnv$ | async)" class="right-title">Manage Users and Notifications</span>

      <div class="user-content">
        <div class="user">
          <mat-icon
            [svgIcon]="'fac-we-usermanage'"
            class="mat-user-icon"
          ></mat-icon>
          <div class="monitor-title">User Management</div>

          <a
            tabindex="0"
            class="monitor-link"
            (click)="isCloud ? redirectToAUI('/manage-users', 'Manage Users') : redirectTo(
              'wcf?name=general-view-aui-wrapper&viewId=system:administration_userssecurity.main',
              'Manage Users'
            )"
          >
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span *ngIf="isCloudEnv$ | async">Invite or manage user</span>
            <span *ngIf="!(isCloudEnv$ | async)">Manage Users & Security</span>
          </a>          
        </div>

        <div class="user">
          <mat-icon
            [svgIcon]="'fac-we-notification'"
            class="mat-user-icon"
          ></mat-icon>
          <div class="monitor-title">Notification channels</div>

          <a
            tabindex="0"
            class="monitor-link"
            (click)="setCurrentTab('contacts', 'Notification Management')"
          >
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span>Manage Contacts</span>
          </a>
          <a
            tabindex="0"
            class="monitor-link"
            (click)="
              setCurrentTab('notification-channels', 'Notification Management')
            "
            style="padding-top: 4px"
          >
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span>Manage Notification Channels </span>
          </a>
        </div>
      </div>
      <div class="user-content">
        <div class="user">
          <mat-icon
            [svgIcon]="'fac-we-alarmtemplate'"
            class="mat-user-icon"
          ></mat-icon>
          <div class="monitor-title">Alarms Templates</div>

          <a
            tabindex="0"
            class="monitor-link"
            (click)="redirectToAUI('/alarm-template', 'Alarms Templates')"
          >
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span>Setup your own alarm template</span>
          </a>
        </div>

        <div class="user">
          <mat-icon [svgIcon]="'fac-we-alarm'" class="mat-user-icon"></mat-icon>
          <div class="monitor-title">Alarms</div>

          <a
            tabindex="0"
            class="monitor-link"
            (click)="
              redirectTo(
                'wcf?name=general-view-aui-wrapper&viewId=system:core_alarms.alarmsMain',
                'Alarms'
              )
            "
          >
            <mat-icon
              [svgIcon]="'fac-arrow-path'"
              class="mat-path-icon"
            ></mat-icon>
            <span>Review outstanding alarms </span>
          </a>
        </div>
      </div>
    </div>
    <div class="space2">&nbsp;</div>
  </div>
</div>
</ng-container>
