<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->
<div class="saas-acceptance-screen">
<div class="saas-acceptance-title">Welcome to Foglight® Cloud</div>
<div class="saas-acceptance-details">
    <div class="saas-acceptance-message">
        To address the General Data Protection Regulations (GDPR) we've updated our
        <a href="https://www.quest.com/legal/sta.aspx">Software Transaction Agreement</a> and <a href="https://quest.com/legal/dpa.aspx">Data Processing Addendum</a>.
      
    </div> 
    <div class="saas-acceptance-message">Please review and agree to the updated agreements to continue.</div>   
</div>
<div *ngIf="isOwner">
<div class="saas-acceptance-checkbox">
   <span> <fac-checkboxes [indeterminate]="false" [checked]="isChecked" (onInputValue)="onCheckboxValueChange($event)" [disabled]="false"       
        [color]="'primary'"></fac-checkboxes></span>
        <span class="sass-checkbox-message">I have read, I understand and I accept the <a href="https://www.quest.com/legal/sta.aspx">Software Transaction Agreement</a> and <a href="https://quest.com/legal/dpa.aspx">Data Processing Addendum</a>. 
        </span>
    </div>
    <div class="saas-acceptance-button">  
        <fac-buttons [color]="'accent'"  [buttonText]="'Continue'"  [disabled]="!isChecked" (click)="loadWelcomePage($event)"></fac-buttons>
    </div>  

</div>
    
<div *ngIf="!isOwner">
    <div class="non-owners-message">Contact your Foglight Cloud Owner to accept the new agreements.</div>
</div>

</div>
    