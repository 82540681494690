/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { createAction, props } from '@ngrx/store';
import { SubscriptionsInfo } from '../reducers';

// get all users action

export const getServerInfo = createAction('[App Module] Get Server Info');

export const setServerInfo = createAction(
  '[App Module] Set Server Info',
  props<{ serverInfo: any }>()
);

export const getSaasAddendumAcceptance = createAction(
  '[Acceptance Screen] Get Saas Addendum Acceptance'
);

export const setSaasAddendumAcceptance = createAction(
  '[Acceptance Screen] Set Saas Addendum Acceptance',
  props<{ saasAccepted: boolean }>()
);

export const createSaasAddendumAcceptance = createAction(
  '[Acceptance Screen] Create Saas Addendum Acceptance'
);

export const getUserInfo = createAction('[App Module] Get User Info');

export const setUserInfo = createAction(
  '[App Module] Set User Info',
  props<{ userInfo: any }>()
);

export const getSubscriptionInfo = createAction(
  '[App Module] Get subscription Info'
);

export const setSubscriptionInfo = createAction(
  '[App Module] Set subscription Info',
  props<{ subscriptionInfo: SubscriptionsInfo[] }>()
);

export const setProductConfiguration = createAction(
  '[App Module] Set product config',
  props<{ productCofig: any }>()
);
