/**
 * QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 * This software is confidential. Quest Software Inc., or one of its
 * subsidiaries, has supplied this software to you under terms of a
 * license agreement, nondisclosure agreement or both.
 *
 * You may not copy, disclose, or use this software except in accordance with
 * those terms.
 *
 *
 * Copyright 2023 Quest Software Inc.
 * ALL RIGHTS RESERVED.
 *
 * QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 * WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 * EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 * TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR
 * NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 * LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 * AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 * THIS SOFTWARE OR ITS DERIVATIVES.
 */
import { Injectable } from '@angular/core';
import {
  SubscriptionData,
  SubscriptionsPayLoad,
} from '../_models/subscriptions-details';
import { Observable } from 'rxjs';
import { FacHttpClientService } from '@foglight/angular-common';
import { CloudUser } from '../_models/account-setting';

@Injectable()
export class AccountSettingHttpService {
  constructor(private http: FacHttpClientService) {}

  /**
   *
   * @param data subscriptions payload
   * @returns
   */
  getSubscriptionsDetails(
    data: SubscriptionsPayLoad
  ): Observable<SubscriptionData> {
    return this.http.post(`/api/v1/foglight-cloud/subscriptions`, data);
  }

  getCloudUserDetails():Observable<CloudUser[]> {
    return this.http.get<CloudUser[]>('/api/v1/foglight-cloud/users');
  }
}
