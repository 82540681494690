/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { Injectable } from '@angular/core';
import { FacHttpClientService, FacUserService } from '@foglight/angular-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { appModuleActions } from '../views/users-management/store/action-types';
import { getServerInfo } from './app.actions';
import { SnackBarDeleteComponent } from 'src/app/shared/snack-bar-delete/snack-bar-delete.component';
import { SnackBarService } from '@services/snack-bar.service';
import { SubscriptionPendingService } from '../shared/dialogs/subscription-pending/subscription-pending.service';
import { SubscriptionData } from '../shared/constants';

@Injectable()
export class appModuleEffects {
  constructor(
    private actions$: Actions,
    private http: FacHttpClientService,
    private snackService: SnackBarService,
    private userService: FacUserService,
    private subscriptionPendingService: SubscriptionPendingService
  ) {}

  getServerInfo = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(appModuleActions.getServerInfo),
      switchMap(() => {
        return this.http.get(`/aui/api/v1/portal/product`);
      }),
      map((serverInfo: any) => {
        if (serverInfo) {
          console.log('retriving server Info', serverInfo);
          return appModuleActions.setServerInfo({ serverInfo: serverInfo });
        }
      })
    );
  });

  getUserInfo = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(appModuleActions.getUserInfo),
      switchMap(() => {
        return this.userService.getUserInfo();
      }),
      map((userInfo: any) => {
        if (userInfo) {
          return appModuleActions.setUserInfo({ userInfo: userInfo });
        }
      })
    );
  });

  fetchSaasAcceptance = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(appModuleActions.getSaasAddendumAcceptance),
      switchMap(() => {
        return this.http
          .get<any>('/api/v1/foglight-cloud/legal/saas-addendum/acceptance')
          .pipe(
            catchError((error) => {
              if (error.error.status == 404) {
                return of(error.error);
              } else {
                this.snackService.openSnackBar(
                  SnackBarDeleteComponent,
                  'error-snack-bar',
                  'right',
                  'top',
                  3000,
                  { text: 'Something went wrong. Please try again later.' }
                );
              }

              return throwError(error);
            })
          );
      }),
      map((user) => {
        if (user) {
          if (user.userName) {
            return appModuleActions.setSaasAddendumAcceptance({
              saasAccepted: true,
            });
          } else if (user.status == 404) {
            return appModuleActions.setSaasAddendumAcceptance({
              saasAccepted: false,
            });
          }
        }
      })
    );
  });

  createSaasAccceptance = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(appModuleActions.createSaasAddendumAcceptance),
      switchMap(() => {
        return this.http
          .post<any>(
            '/api/v1/foglight-cloud/legal/saas-addendum/acceptance',
            null
          )
          .pipe(
            catchError((error) => {
              this.snackService.openSnackBar(
                SnackBarDeleteComponent,
                'error-snack-bar',
                'right',
                'top',
                3000,
                { text: 'Something went wrong. Please try again later.' }
              );
              return throwError(error);
            })
          );
      }),
      map((acceptanceResp) => {
        return appModuleActions.setSaasAddendumAcceptance({
          saasAccepted: true,
        });
      })
    );
  });

  getSubscriptionInfo = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(appModuleActions.getSubscriptionInfo),
      switchMap(() => {
        return this.subscriptionPendingService.getSubscriptionsDetails();
      }),
      map((subscriptionData: SubscriptionData) => {
        if (subscriptionData) {
          return appModuleActions.setSubscriptionInfo({
            subscriptionInfo: subscriptionData.subscriptions,
          });
        }
      })
    );
  });
}
