/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/

import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, timer } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { pathForIcons } from '../../../../environments/path-utils';

@Component({
  selector: 'fc-idle-user-dialog',
  templateUrl: './idle-user-dialog.component.html',
  styleUrls: ['./idle-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdleUserDialogComponent implements OnInit {
  private readonly IMAGE_PATH = 'info_icon.svg';
  private readonly warnTime: number;
  private destroy$ = new Subject<void>();
  theme: string;
  timer$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<IdleUserDialogComponent>
  ) {
    this.warnTime = data.warnTime;
  }

  get svgPath(): string {
    return pathForIcons(this.IMAGE_PATH);
  }

  ngOnInit(): void {
    if (localStorage.getItem('theme')) {
      this.theme = localStorage.getItem('theme');
    }

    this.timer$ = timer(0, 1000)
      .pipe(
        take(this.warnTime + 1),
        map(secondsElapsed => this.warnTime - secondsElapsed),
        takeUntil(this.destroy$)
      );

    this.timer$.subscribe(value => {
      if (value == 0) {
        this.close('disconnect');
      }
    })
  }

  close(reason: 'restart' | 'disconnect'): void {
    this.dialogRef.close(reason);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
