/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog/dialog-ref';
import { ComponentType } from '@angular/cdk/portal';
import { Observable } from 'rxjs';
import { FacHttpClientService } from '@foglight/angular-common';
import { SubscriptionData } from '../../constants';
import { SubscriptionPendingComponent } from './subscription-pending.component';
import { Store } from '@ngrx/store';
import { subscriptionInfo } from 'src/app/store/app.selectors';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPendingService {
  constructor(
    private dialog: MatDialog,
    private http: FacHttpClientService,
    private store: Store,
    private router: Router,
    private titleService: Title
  ) {}

  checkExitingSubscription(
    viewId: string,
    instanceType: string,
    monitorDomain: string,
    aui?: boolean
  ) {
    this.store.select(subscriptionInfo).pipe(take(1)).subscribe((subscriptionInfo) => {
      if (!subscriptionInfo) {
        return;
      }
      let subscriptionDetails = subscriptionInfo.find((subscription) =>
        subscription.name.includes(instanceType)
      );
      if (!subscriptionDetails) {
        let dialogData = {
          instanceType: instanceType,
          monitorDomain: monitorDomain,
        };
        this.showDialog(dialogData);
        return;
      }
      if (aui == true) {
        this.redirectTo(viewId, monitorDomain);
      } else {
        this.redirectTo(
          'wcf?name=general-view-aui-wrapper&viewId=' + viewId,
          monitorDomain
        );
      }
    });
  }

  redirectTo(url: string, title: string) {
    this.titleService.setTitle(`${title} - Foglight`);

    this.router.navigateByUrl(url);
  }

  showDialog(data: any) {
    this.dialog.open(SubscriptionPendingComponent, {
      panelClass: 'fc-border-dialog-panel',
      autoFocus: false,
      data,
    });
  }

  /**
   *
   * @param data subscriptions payload is empty
   * @returns
   */
  getSubscriptionsDetails(): Observable<SubscriptionData> {
    return this.http.post(`/api/v1/foglight-cloud/subscriptions`, {});
  }
}
