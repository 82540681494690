<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->
<div style="width: 100%" [ngClass]="theme">
    <div class="idle-user-container">
      <div class="idle-user-header-title">Page about to expire</div>
  
      <div class="idle-user-content">
        <img class="idle-user-info-icon" [attr.src]="svgPath" />
        <span class="idle-user-message"
          >This page is about to expire. The system has noticed a period of
          inactivity and will automatically disconnect this page in:
          <div class="idle-user-timeout-count">{{ timer$ | async }} Seconds</div>
        </span>
      </div>
  
      <div class="idle-user-footer">
        <fac-buttons
          [color]="'secondary'"
          [size]="'medium'"
          (click)="close('disconnect')"
          [buttonText]="'Disconnect Now'"
        ></fac-buttons>
        <span class="idle-user-connect-button"
          ><fac-buttons
            [color]="'accent'"
            [size]="'medium'"
            (click)="close('restart')"
            [buttonText]="'Stay Connected'"
          ></fac-buttons>
        </span>
      </div>
    </div>
  </div>
  