/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'domainMapping'
})
export class DomainMappingPipe implements PipeTransform {

  transform(value: string): string {
    return getDomainDisplayName(value);
  }

}

export const getDomainDisplayName = (domainName: string): string => {
  const domainMapping = {
    SSAS: 'SQL Server Analysis Services',
    SSRS: 'SQL Server Reporting Services',
    SSIS: 'SQL Server integration Services',
    ASM: 'Oracle Automatic Storage Management',
    CRS: 'Oracle Cluster Ready Services',
    Exadata: 'Oracle Exadata Database Machine',
    DBTwo: 'DB2',
    MSSQL: 'SQL Server',
    Oracle: 'Oracle',
    SAPASE: 'SAP (Sybase) Adaptive Server Enterprise',
    SAPRS: 'SAP (Sybase) Replication Server',
    AzureSQLDatabase: 'Azure SQL Database'
  };
  return domainMapping?.[domainName] ?? domainName;
};

export const getDomainIconName = (domainName: string): string => {
  return getDomainDisplayName(domainName).toLowerCase().split(' ').join('-');
};

export const shouldDisplayDomain = (domainName: string): boolean => {
  if (!domainName) {
    return false;
  }

  // these domains should not be displayed on foglight-cloud
  // JIRA https://qsft.atlassian.net/browse/FOG-7702
  const domainMapping = [
    'SSAS',
    'SSRS',
    'SSIS',
  ];

  // if the name includes in the list so return false to not displey the tab
  return !domainMapping.includes(domainName);
}
