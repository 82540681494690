/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { LayoutComponent } from './layout/layout.component';
import { AvailabilityGuard } from './shared/guards/availability.guard';
import { RoleGuard } from './shared/guards/Role.guard';
import { AlarmDetailsComponent } from './views/alarm-details/alarm-details.component';
import { NotificationManagementResolver } from './views/notification-management/notification-manegement-resolver.service';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { UnavailableComponent } from './views/unavailable/unavailable.component';
import { PageTemplateComponent } from './views/page-template/page-template.component';
import { HomePageComponent } from './views/home-page/home-page.component';
import { StaSaasAcceptanceScreenResolver } from '@services/sta-saas-acceptance-screen.resolver';
import { SaasAcceptanceGuard } from './shared/guards/saas.acceptance.guard';
import { WCFauthGuard } from './shared/guards/wcfauth.guard';
import { AdminPageComponent } from './views/admin-page/admin-page.component';
import { SingleSpaAppLoaderComponent } from './views/single-spa-app-loader/single-spa-app-loader.component';
import { AdminPageGuard } from './shared/guards/admin-page.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('@foglight/angular-common').then((m) => m.FacAuthModule),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'alarm-template',
        loadChildren: () =>
          import('./views/alarm-template/alarm-template.module').then(
            (m) => m.AlarmTemplateModule
          ),
        canActivate: [RoleGuard, SaasAcceptanceGuard],
      },
      {
        path: 'audit-log',
        loadChildren: () =>
          import('./views/audit-log/audit-log.module').then(
            (m) => m.AuditLogModule
          ),
        canActivate: [RoleGuard, SaasAcceptanceGuard],
      },
      {
        path: 'notification-management',
        loadChildren: () =>
          import(
            './views/notification-management/notification-management.module'
          ).then((m) => m.NotificationManagementModule),
        resolve: { currentTab: NotificationManagementResolver },
        canActivate: [RoleGuard, SaasAcceptanceGuard],
      },
      {
        path: 'manage-users',
        loadChildren: () =>
          import('./views/users-management/users-management.module').then(
            (m) => m.UsersManagementModule
          ),
        canActivate: [RoleGuard, AvailabilityGuard, SaasAcceptanceGuard],
      },

      {
        path: 'settings',
        loadChildren: () =>
          import('./views/account-settings/account-settings.module').then(
            (m) => m.AccountSettingsModule
          ),
      },
      {
        path: 'alarm-details',
        children: [
          {
            path: ':id',
            component: AlarmDetailsComponent,
          },
        ],
        canActivate: [SaasAcceptanceGuard],
      },
      {
        path: 'wcf',
        loadChildren: () =>
          import('@foglight/angular-common').then(
            (m) => m.FacWcfIframeWrapperModule
          ),
        canActivate: [SaasAcceptanceGuard, WCFauthGuard],
      },
      {
        path: 'system-aui-administration',
        component: AdminPageComponent,
        canActivate: [SaasAcceptanceGuard, AdminPageGuard],
      },
      {
        // added demo routing for check page-template
        path: 'page-template',
        children: [
          {
            path: '',
            component: PageTemplateComponent,
          },
          {
            path: ':templateLayout',
            component: PageTemplateComponent,
          },
        ],
        canActivate: [SaasAcceptanceGuard],
      },
      {
        path: 'foglight-sql-profiler/home',
        redirectTo: '/home',
        pathMatch: 'full',
      },

      {
        path: 'home',
        children: [
          {
            path: '',
            component: HomePageComponent,
          },
        ],
        resolve: { data: StaSaasAcceptanceScreenResolver }       
      },

      {
        path: 'common-component',
        loadChildren: () =>
          import(
            './views/common-component-testing/common-component-testing.module'
          ).then((m) => m.CommonComponentTestingModule),
        canActivate: [SaasAcceptanceGuard],
      },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: 'unavailable', component: UnavailableComponent },
      {
        path: 'foglight-sql-profiler/query-insights',
        component: SingleSpaAppLoaderComponent,
        canActivate: [SaasAcceptanceGuard],
      },
      {
        path: 'foglight-db-postgres-pi/postgres-pi',
        component: SingleSpaAppLoaderComponent,
        canActivate: [SaasAcceptanceGuard],
      },
      { path: '**', component: EmptyRouteComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
