/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarService } from '../../services/sidebar.service';
import { NavItem } from '../../models/nav-item';
import lod from 'lodash';
import { SidebarHttpService } from '../../services/sidebar-http.service';

@UntilDestroy()
@Component({
  selector: 'fc-edit-mode-panel',
  templateUrl: './edit-mode-panel.component.html',
  styleUrls: ['./edit-mode-panel.component.scss']
})

export class EditModePanelComponent implements OnInit, OnDestroy {
  navItems: NavItem[] = [];
  originNavItems: NavItem[] = [];
  bookmarksItem: NavItem;
  private _disabledItems: string[] = [];
  private menuItemsArray = [];

  @Output() adjustSidebarWidth = new EventEmitter<void>();
  @Output() onEditSave = new EventEmitter();
  @Output() onEditCancel = new EventEmitter();

  constructor(public sidebarService: SidebarService,
              private sidebarHttpService: SidebarHttpService,
              private ref: ChangeDetectorRef,
              private _elementRef: ElementRef) {
  }

  ngOnInit() {

    this.adjustSidebarWidth.emit();

    this.sidebarService.navItems$.pipe(untilDestroyed(this)).subscribe(items => {
      this.navItems = lod.cloneDeep(items);
      this.originNavItems = lod.cloneDeep(items);
    });

    this.sidebarService.menuItems$.pipe(untilDestroyed(this)).subscribe((items) => {
      this.menuItemsArray = this.menuItemsArray.filter(item => item.id !== items.id);
      this.menuItemsArray.push(items);
    });

    this.sidebarService.bookmarksItem$.subscribe(bookmarks => {
      this.bookmarksItem = lod.cloneDeep(bookmarks)
    })

    this.sidebarService.deletedBookmark.pipe(untilDestroyed(this)).subscribe(id => {
      this.bookmarksItem.children = this.bookmarksItem.children?.filter(bookmark => bookmark.id !== id);
      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    this.sidebarService.updateNavItems(this.originNavItems);
  }

  save() {
    this.copyManuItemsToNavItems();
    this._prepareDisabledItems();
    this.sidebarService.updateNavItems(this.navItems);
    this.sidebarService.updateBooksmarksItem(this.bookmarksItem)
    this.sidebarService.setEditState(false);
    this.onEditSave.emit();

    this.sidebarService.disableMenus(this._disabledItems).pipe(untilDestroyed(this))
      .subscribe(value => {
        console.log(value);
      });

    this.menuItemsArray = [];
  }

  copyManuItemsToNavItems() {
    this.menuItemsArray.forEach(item => {
      this.navItems = this.navItems.map(navItem => {
        if (this.findParentWithId(item, navItem.id)) {
          if (item.children.length > 0) {
            const idMap = item.children.reduce((map, obj) => {
              map[obj.id] = obj;
              return map;
            }, {});

            const resultArray: any = navItem.children.map(obj => idMap[obj.id] ? idMap[obj.id] : obj);
            navItem.children = lod.cloneDeep(resultArray);
            return navItem;
          }
          return item;
        }
        return navItem;
      });
    });
  }

  findParentWithId(obj, id) {
    if (obj.id === id) {
      return obj;
    }
    if (!obj.parent) {
      return undefined;
    }
    return this.findParentWithId(obj.parent, id);
  }

  revert() {
    this.originNavItems.map((obj, i) => {
      obj.isExpanded = this.navItems[i].isExpanded;
    });
    this.sidebarService.updateNavItems(this.originNavItems);
    this.sidebarService.updateBooksmarksItem(this.bookmarksItem)
    this.sidebarService.setEditState(false);
    this.onEditCancel.emit();
  }

  private _prepareDisabledItems() {
    this._disabledItems = [];
    this.navItems.forEach(item => {
      this._checkDisabled(item);
    });
  }

  private _checkDisabled(item: NavItem) {
    if (!item.visible) {
      this._disabledItems.push(item.id);
    } else {
      if (item.children && item.children.length > 0) {
        item.children.forEach(child => {
          this._checkDisabled(child);
        });
      }
    }
  }
}
