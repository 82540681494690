/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



export const sidebarIcons: Record<string, string> = {
    'system:active20directory': 'activedirectory',
    'system:administration': 'fg-administration',
    'alarm-template': 'fg-alarm-template',
    'alarm-Management': 'alarmmanagement',
    'system:aws-hosts':'fg-aws-hosts',
    'system:bmcadmin':'fg-bmc-remedy',
    'system:capacity': 'fg-capacity-director',
    'system:cloud-manager': 'fg-cloudmanager',
    'foglight-global-view': 'fg-global-view',
    'notification-management': 'fg-notification-management',
    'system:core-alarms': 'fg-alarms',
    'system:apache': 'fg-apache',
    'system:apm-app-ops': 'fg-apm-app-ops',
    'system:cassandra': 'fg-cassandra',
    'system:consolidation-advisor': 'fg-cons-advisor',
    'system:cloudmigration': 'fg-cloudmigration',
    'system:docker': 'fg-docker',
    'system:costdirector': 'fg-costdirector',
    'system:dbwc': 'fg-dbwc',
    'system:apm-dependency': 'fg-dependency-mapping',
    'system:enduser': 'fg-enduser',
    'system:selector': 'fg-selector',
    'system:exchange': 'fg-exchange',
    'system:HyperV': 'fg-HyperV',
    'system:ibm-cloud': 'fg-ibm-cloud',
    'system:infrastructure': 'fg-infrastructure',
    'system:javaeeroot-jmx': 'fg-javaeeroot-jmx',
    'system:mysql': 'fg-mysql',
    'system:mongodb': 'fg-mongodb',
    'system:netvault': 'fg-netvault',
    'system:office365': 'fg-office365',
    'system:ome-dashboard': 'fg-ome-dashboard',
    'system:openstack': 'fg-openstack',
    'system:operating': 'fg-operating',
    'system:pagerDuty': 'fg-pagerDuty',
    'system:rapid20recovery': 'fg-rapid20recovery',
    'system:pso': 'fg-pso',
    'system:foglight-reports': 'fg-reports',
    'system:foglight-services': 'fg-services',
    'system:storage': 'fg-storage',
    'system:sybasemda': 'fg-sybasemda',
    'system:vcloud': 'fg-vcloud',
    'system:virtualvmw': 'fg-virtualvmw',
    'system:websphere20mq': 'fg-websphere20mq',
    'system:vmwareview': 'fg-vmwareview',
    'system:web-monitor': 'fg-web-monitor',
    'system:xendesktop': 'fg-xendesktop',
    'system:postgresql': 'fg-postgress',
    'system:default':  'custom'
} as const;


