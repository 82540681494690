/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/

import { Injectable } from '@angular/core';
import { FacAuthService } from '@foglight/angular-common';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { ServerInfo } from './idle-user.model';
import { serverInfoSelector } from '../../../app/store/app.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { DialogService } from '@services/dialog/dialog.service';
import { IdleUserDialogComponent } from '../../shared/dialogs/idle-user/idle-user-dialog.component';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@UntilDestroy()
export class IdleUserService {
  /** warning user before logout time in seconds */
  private readonly WARN_TIMEOUT = 30;
  /** time for the user to be idle before popup shows default value is 10 minutes */
  private readonly DEFAULT_IDLE_TIME = 3600;
  idleTime: number;
  isDialogOpen = false;
  ignoreMessage = false;

  constructor(
    private idle: Idle,
    private authService: FacAuthService,
    private dialogService: DialogService,
    private store: Store,
  ) {
    this.initService();

    const sub = this.store
      .select<ServerInfo>(serverInfoSelector)
      .pipe(untilDestroyed(this))
      .subscribe(serverInfo => {
        if (!serverInfo || !Object.keys(serverInfo).length) {
          return;
        }

        serverInfo?.userSessionSettings ? this.initIdleTimer(serverInfo.userSessionSettings) :
          console.error('serverInfo.userSessionSettings is', serverInfo.userSessionSettings);
        this.unsubscribe(sub);
      });
  }

  stopWatching(): void {
    this.idle.stop();
  }

  restart(): void {
    this.startWatching();
  }

  private unsubscribe(sub: Subscription): void {
    sub?.unsubscribe();
  }

  private initIdleTimer({ sessionNeverTimeOut, numberOfSeconds }): void {
    if (sessionNeverTimeOut) {
      console.warn('idle timer NOT started - userSessionSettings.sessionNeverTimeOut:', sessionNeverTimeOut);
      return;
    } else if (numberOfSeconds > 0) {
      const idleTime = numberOfSeconds - this.WARN_TIMEOUT;
      this.setIdleTime(idleTime);
    } else {
      this.setIdleTime(this.DEFAULT_IDLE_TIME);
      console.warn('userSessionSettings from serverInfo is missing - setting idle time to default...');
    }

    this.startWatching();
  }

  private startWatching(): void {
    this.idle.watch();
  }

  private setIdleTime(idleTime: number): void {
    this.idleTime = idleTime;
    this.idle.setIdle(idleTime);
  }

  private onTimeoutWarning(): void {
    if (this.isDialogOpen) {
      return;
    }

    this.stopWatching();

    const ref = this.dialogService.open(IdleUserDialogComponent,
      { warnTime: this.WARN_TIMEOUT }, { panelClass: 'idle-user-panel', disableClose: true });

    ref.afterClosed()
      .subscribe((reason: 'restart' | 'disconnect') => {
        switch (reason) {
          case 'restart':
            this.restart();
            break;
          case 'disconnect':
            this.disconnect();
            break;
          default:
            break;
        }
        this.isDialogOpen = false;
      });

    this.isDialogOpen = true;
  }

  private disconnect(): void {
    this.authService.logout();
  }

  private initService(): void {
    this.idle.setTimeout(this.WARN_TIMEOUT);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    window.addEventListener('message', e => {
      if (this.ignoreMessage) {
        return;
      }

      if (e.data === 'userActive') {
        this.idle.interrupt(true);
        this.ignoreMessage = true;
        window.setTimeout(() => this.ignoreMessage = false, 1000);
      }
    });

    this.idle.onTimeoutWarning
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onTimeoutWarning();
      });

    this.idle.onTimeout
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.disconnect();
      });
  }
}
