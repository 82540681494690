/**
 * QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 * This software is confidential. Quest Software Inc., or one of its
 * subsidiaries, has supplied this software to you under terms of a
 * license agreement, nondisclosure agreement or both.
 *
 * You may not copy, disclose, or use this software except in accordance with
 * those terms.
 *
 *
 * Copyright 2023 Quest Software Inc.
 * ALL RIGHTS RESERVED.
 *
 * QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 * WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 * EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 * TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR
 * NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 * LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 * AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 * THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { Component, OnInit, ChangeDetectionStrategy,Input, ChangeDetectorRef } from '@angular/core';
import { SubscriptionSaleService } from '../_service/subscription-sale.service';
@Component({
  selector: 'fc-sale-support',
  templateUrl: './sale-support.component.html',
  styleUrls: ['./sale-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaleSupportComponent implements OnInit {

  @Input() isOwnerOrAdmin: boolean;
  @Input() username: string;
  @Input() theme: string;
  subscriptionType:string;
  constructor(private subscriptionSaleService:SubscriptionSaleService,  private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscriptionSaleService.subscriptionType$.subscribe(subscriptionType=>{
      this.subscriptionType=subscriptionType;
      this.cd.markForCheck();

    })
   
  }

  supportOnClick() {
    window.open('https://support.quest.com/foglight-cloud');
  }


  salesOnClick() {  
      
    this.subscriptionSaleService.generateMailToSupportTeam(this.subscriptionType,this.username);    
  }
}
