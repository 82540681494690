<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->

<fc-edit-mode-panel (onEditSave)="onEditSaveAndCancel()" (onEditCancel)="onEditSaveAndCancel()" (adjustSidebarWidth)="setSidebarWidthAccordingToSessionStorage()" *ngIf="(sidebarService.editMode$ | async); else navMenuPanel"></fc-edit-mode-panel>
<ng-template #navMenuPanel>
  <fc-nav-menu-panel
    [isPinned]="pinned"
    [isLoading]="isLoading"
    (pinClick)="onPinClick()"
    (tooltipHover)="onHover()"
    (editModeClick)="onConfigure()"
    [isError]="sidebarError"></fc-nav-menu-panel>
</ng-template>
