/*
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/

import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  FacGraphqlService,
  FacHttpClientService,
  FacQueryParameter,
  FacQueryStatement,
  FacTimeRange
} from '@foglight/angular-common';
import {
  AlarmTemplateCreateData,
  AlarmTemplateData,
  AlarmTemplateDetailInfo,
  ControllingDomainRoot,
  DefaultTemplateSettings,
  DomainObjectCount,
  DomainRootTypes,
  DomainRuleCount,
  DomainTemplate,
  DomainTemplateDetail,
  MultiSeverityRuleConfig,
  NotificationSettingsConfig,
  RuleConfigUpdateInfo,
  TargetObject,
  TargetTemplateInfo,
  TopologySubDomain
} from '../_models/alarm-template';
import moment from 'moment';
import { Connection } from '../_models/connection';
import { map, switchMap } from 'rxjs/operators';
import _ from 'lodash';

@Injectable()
export class AlarmTemplateHttpService {

  constructor(
    private http: FacHttpClientService,
    private graphqlService: FacGraphqlService,
  ) {
  }

  addNewAlarmTemplate(data: AlarmTemplateCreateData): Observable<AlarmTemplateData[]> {
    return this.http.post('/api/v1/alarm-templates', data);
  }

  updateAlarmTemplate(id: string, data: AlarmTemplateCreateData): Observable<any> {
    return this.http.put('/api/v1/alarm-templates/' + id, data);
  }

  deleteAlarmTemplate(id: string): Observable<void> {
    return this.http.delete('/api/v1/alarm-templates/' + id);
  }

  cloneAlarmTemplate(id: string, data: AlarmTemplateCreateData): Observable<AlarmTemplateData> {
    return this.http.post(`/api/v1/alarm-templates/${id}/clone`, data);
  }

  getTemplateDomains(): Observable<TopologySubDomain[]> {
    const queryParameter = this._createQueryParameter();
    const domainStatement = FacQueryStatement.standard()
      .withField('TopologyDomain')
      .withSimpleSubFields('uniqueId', 'name', 'displayName', 'fullyQualifiedName')
      .withQueryParameter(queryParameter);

    const excludedDomainList = ['forge.dependency', 'vCloud.Dependencie'];
    return combineLatest([
      this.graphqlService.query<TopologySubDomain>(domainStatement).pipe(map(it => it?.instances)),
      this.getDomainRuleCounts()
    ]).pipe(
      map(([
             domain,
             domainRuleCounts
           ]) => [...domain].filter(obj => {
          const isContainRule = domainRuleCounts.findIndex(it =>
            it.domainName === obj.fullyQualifiedName && it.ruleCount > 0) >= 0;
          return isContainRule && !excludedDomainList.includes(obj.fullyQualifiedName);
        })
      ));
  }

  getDomainRuleCounts(): Observable<DomainRuleCount[]> {
    const domainRuleCounts = this.http.get<Record<string, DomainRuleCount[]>>('/api/v1/alarm-templates/domains/rule-count');
    if (domainRuleCounts) {
      return domainRuleCounts.pipe(
        map(it => it?.domainCountInfoList)
      );
    }
  }

  private _createQueryParameter(): FacQueryParameter {
    const queryParameter = new FacQueryParameter(
      new FacTimeRange(moment().subtract(1, 'hour').toISOString(),
        moment().add(1, 'hour').toISOString())
    ).appendLimit(100000);
    return queryParameter;
  }

  getAlarmTemplateData(): Observable<AlarmTemplateData[]> {
    return this.http.get<AlarmTemplateData[]>('/api/v1/alarm-templates');
  }

  getAlarmTemplateDetail(templateId: string): Observable<AlarmTemplateDetailInfo> {
    return this.http.get<AlarmTemplateDetailInfo>('/api/v1/alarm-templates/' + templateId);
  }

  getDomainTargets(domain: string): Observable<Connection[]> {
    const queryParameter = this._createQueryParameter();
    const excludedTargetAnnotation = 'AlarmTemplateTargetFiltered';
    return this.getDomainRootTypes(domain).pipe(
      switchMap(obj => combineLatest(obj.rootTypes.map(type => {
        const statement = FacQueryStatement.standard().withField(type.typeName)
          .withQueryParameter(queryParameter)
          .withSimpleSubFields('uniqueId', 'name', 'topologyTypeName', 'annotations')
          .startWithObjectSubField('monitoredHost').withSimpleSubFields('name').endObjectSubField();
        return this.graphqlService.query<Connection>(statement).pipe(
          map(it => it?.instances?.filter(target => {
            target.topologyTypeDisplayName = type.typeDisplayName;
            return !target?.annotations?.includes(excludedTargetAnnotation);
          }))
        );
      }))),
      map(it => _.flatMap(it))
    );
  }

  assignTargets(templateId: string, ids: string[]): Observable<AlarmTemplateData> {
    return this.http.post(`/api/v1/alarm-templates/${templateId}/assign-targets`, {objectIds: ids});
  }

  unassignedTargets(templateId: string, ids: string[]): Observable<AlarmTemplateData> {
    return this.http.post(`/api/v1/alarm-templates/${templateId}/unassign-targets`, {objectIds: ids});
  }

  setDefaults(defaultTemplateSettings: DefaultTemplateSettings[]): Observable<void> {
    return this.http.post('/api/v1/alarm-templates/set-defaults', defaultTemplateSettings);
  }

  updateRuleConfig(alarmTemplateId: string, domainName: string,
                   ruleId: string, data: MultiSeverityRuleConfig): Observable<any> {
    return this.getTemplateDomains().pipe(
      map(it => it?.find(obj => obj.name.toLowerCase() === domainName)),
      switchMap(it =>
        this.http.put(`/api/v1/alarm-templates/${alarmTemplateId}/${it.fullyQualifiedName}/${ruleId}`, data)
      )
    );
  }

  updateNotificationSettingsforAlarmTemplate(alarmTemplateId: string, channelId: string,
    data: NotificationSettingsConfig): Observable<any> {
    return this.http.put(`/api/v1/alarm-templates/${alarmTemplateId}/channels/${channelId}`, data);
  }

  updateNotificationSettingsforDomain(alarmTemplateId: string, channelId: string, domainName: string,
    data: any): Observable<any> {
    return this.http.put(`/api/v1/alarm-templates/${alarmTemplateId}/channels/${channelId}/domains/${domainName}`, data);
  }

  deleteNotificationSettingsfromAlarmTemplate(alarmTemplateId: string, channelId: string): Observable<any> {
    return this.http.delete(`/api/v1/alarm-templates/${alarmTemplateId}/channels/${channelId}`);
  }

  deleteNotificationSettingsfromDomain(alarmTemplateId: string, channelId: string, domainName: string): Observable<void> {
    return this.http.delete(`/api/v1/alarm-templates/${alarmTemplateId}/channels/${channelId}/domains/${domainName}`);
  }

  updateRuleConfigStatus(alarmTemplateId: string, domainName: string,
                         ruleConfigUpdateInfos: RuleConfigUpdateInfo[]): Observable<DomainTemplate> {
    return this.getTemplateDomains().pipe(
      map(it => it?.find(obj => obj.name.toLowerCase() === domainName)),
      switchMap(it =>
        this.http.post<DomainTemplate>(
          `/api/v1/alarm-templates/${alarmTemplateId}/${it.fullyQualifiedName}/update`,
          {ruleConfigs: ruleConfigUpdateInfos}
        )
      )
    );
  }

  getFactoryTemplateDomain(domainName: string): Observable<DomainTemplateDetail> {
    return this.http.get<DomainTemplateDetail>(`/api/v1/alarm-templates/${domainName}/factory-template`);
  }

  getAllFactoryTemplateDomains(domainNames: string[]): Observable<DomainTemplateDetail[]> {
    return combineLatest(domainNames.map(domainName => this.getFactoryTemplateDomain(domainName)));
  }

  getDomainRootTypes(domain: string): Observable<DomainRootTypes> {
    return this.http.get<DomainRootTypes>(`/api/v1/alarm-templates/domains/${domain}/root-types`);
  }

  getTargetsCurrentAlarmTemplates(targetIds: string[]): Observable<TargetTemplateInfo[]> {
    return this.http.post<Record<string, TargetTemplateInfo[]>>('/api/v1/alarm-templates/targets/current-template',
      {objectIds: targetIds}).pipe(map(it => it?.TargetTemplateInfos));
  }

  getAssignedTargets(alarmTemplateId: string, domainName: string): Observable<TargetObject[]> {
    return this.http.get<Record<string, TargetObject[]>>(`/api/v1/alarm-templates/${alarmTemplateId}/${domainName}/assigned-targets`)
      .pipe(
        map(it => it.targets)
      );
  }

  getFullAssignedTargets(alarmTemplateId: string, domainName: string): Observable<[]> {
    return this.http.get(`/api/v1/alarm-templates/${alarmTemplateId}/${domainName}/assigned-targets`)
  }

  getRuleConfigs(alarmTemplateId: string, domainName: string): Observable<MultiSeverityRuleConfig[]> {
    return this.http.get<Record<string, MultiSeverityRuleConfig[]>>(`/api/v1/alarm-templates/${alarmTemplateId}/${domainName}/rule-configs`)
      .pipe(
        map(it => it?.ruleConfigs)
      );
  }

  getControllingDomainRoot(targetIds: string[]): Observable<ControllingDomainRoot[]> {
    return this.http.post<Record<string, ControllingDomainRoot[]>>(
      `/api/v1/alarm-templates/targets/controlling-domain-root`,
      {objectIds: targetIds}
    ).pipe(
      map(it => it?.controllingDomainRootList)
    );
  }

  getDomainObjectCounts(domains: string[]): Observable<DomainObjectCount[]> {
    return this.http.post<Record<string, DomainObjectCount[]>>(
      '/api/v1/alarm-templates/domains/object-count',
      {domainNames: domains}
    ).pipe(
      map(it => it?.domainCountInfoList)
    );
  }
}

