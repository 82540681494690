/*
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, filter } from 'lodash';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { SnackBarDeleteComponent } from 'src/app/shared/snack-bar-delete/snack-bar-delete.component';
import { NavItem } from '../../models/nav-item';
import { SidebarHttpService } from '../../services/sidebar-http.service';
import { SidebarService } from '../../services/sidebar.service';

@UntilDestroy()
@Component({
  selector: 'fc-delete-bookmark-dialog',
  templateUrl: './delete-bookmark-dialog.component.html',
  styleUrls: ['./delete-bookmark-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteBookmarkDialogComponent implements OnInit {
  bookmarkName = '';
  bookmarksItem: NavItem;
  constructor(
    @Inject(MAT_DIALOG_DATA) public bookmark: any,
    private dialogRef: MatDialogRef<DeleteBookmarkDialogComponent>,
    private sidebarHttpService: SidebarHttpService,
    private sidebarService: SidebarService,
    private snackService: SnackBarService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.bookmarkName = this.bookmark.displayName;
  }

  deleteBookmark() {
    this.sidebarHttpService.deleteBookmark(this.bookmark.id).pipe(untilDestroyed(this)).subscribe(
      () => {
        this.snackBar();
        this.sidebarService.deletedBookmark.next(this.bookmark.id);
        this.ref.detectChanges();
        this.dismissDialog('delete');
      },
      (error) => {
        this.dismissDialog('error');
      }
    );
  }

  dismissDialog(message: string) {
    this.dialogRef.close(message);
  }

  snackBar() {
    this.snackService.openSnackBar(
      SnackBarDeleteComponent,
      'delete-snack-bar',
      'right',
      'top',
      3000,
      {text: 'Bookmark was deleted'}
    );
  }
}
