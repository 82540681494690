/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { Observable } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FacLocalTableController, ListDataRefresher } from '@foglight/angular-common';
import { LegalNoticeListDataRefresherService } from './legal-notice-list-data-refresher.service';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


export class LegalNoticeItem {
  constructor(public componentName: string,
              public componentVersion: string,
              public license: string,
              public licenseUrl: string) { }
}
@UntilDestroy()
@Component({
  selector: 'fc-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalNoticeComponent
  extends FacLocalTableController<LegalNoticeItem>
  implements OnInit
{
  searchFormControl = new FormControl('');
  displayedColumns: string[] = ['component', 'version', 'legalNotice'];

  constructor(public refresher: LegalNoticeListDataRefresherService) {
    super(refresher);
  }

  ngOnInit(): void {
    setTimeout(() => {
      super.ngOnInit();

      this.searchFormControl.valueChanges
        .pipe(debounceTime(300), untilDestroyed(this))
        .subscribe((value) => {
          this.refresher.filter(value);
        });
    }, 50);
  }
}
