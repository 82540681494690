/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



export interface NavItem {
  id: string;
  displayName: string;
  description: string;
  visible?: boolean;
  iconName: string;
  link?: string;
  children?: NavItem[];
  parent?: NavItem;
  level?: number;
  isExpanded?: boolean;
  type?: string;
  tooltip?: string;
  source?: string;
}

export const WcfLinkToAuiLinkMapping : any = {
  'system:administration.22':'system-aui-administration'
}

export const NoWcfLinkToAuiLinkMapping : any = {
  'system:administration':'system-aui-administration'
}

export const AUIPages : any = ['query-insights','home','system-aui-administration','account-settings'];

export const PagesNotInLeftMenu : any = ['home','account-settings','frameId=wcf.userSettings'];