/**
 * QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 * This software is confidential. Quest Software Inc., or one of its
 * subsidiaries, has supplied this software to you under terms of a
 * license agreement, nondisclosure agreement or both.
 *
 * You may not copy, disclose, or use this software except in accordance with
 * those terms.
 *
 *
 * Copyright 2023 Quest Software Inc.
 * ALL RIGHTS RESERVED.
 *
 * QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 * WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 * EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 * TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR
 * NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 * LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 * AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 * THIS SOFTWARE OR ITS DERIVATIVES.
 */

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { FacAuthService, FacUserService } from '@foglight/angular-common';
import { Store } from '@ngrx/store';
import { UtilService } from '@services/util.service';
import { userInfo } from 'src/app/store/app.selectors';
import { AccountSettingHttpService } from './_service/account-setting-http.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoleMapping } from './_models/account-setting';
import { HttpErrorResponse } from '@angular/common/http';

@UntilDestroy()
@Component({
  selector: 'fc-account-settings',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSettingComponent implements OnInit {
  breadcrumbItems;
  theme = 'light-theme';
  constructor(
    private store: Store,
    private router: Router,
    private cd: ChangeDetectorRef,
    private authService: FacAuthService,
    private utilService: UtilService,
    private accountSettingHttpService: AccountSettingHttpService
  ) {}
  userRoles = '';
  userName: string;
  userRoleList: string[];
  isOwnerOrAdmin: boolean = false;

  ngOnInit(): void {
    this.utilService.clearBreadCrumbHistory();
    if (localStorage.getItem('theme')) {
      this.theme = localStorage.getItem('theme');
    }

    this.getUserRoles();
    this.setBreadcrumbItems();
    this.addListenerForThemeChange();
    this.cd.markForCheck();
  }

  private getUserRoles() {
    this.store.select(userInfo).subscribe((user) => {
      if (user && user.roles) {
        this.userRoleList = user.roles;
        this.userName = user.name;
        this.setCloudUserRole();
        this.cd.markForCheck();
      }
    });
  }

  private setCloudUserRole() {
    this.accountSettingHttpService
      .getCloudUserDetails()
      .pipe(untilDestroyed(this))
      .subscribe((cloudUserDetails) => {
        if (cloudUserDetails && cloudUserDetails.length > 0) {
          let currentUser = cloudUserDetails.find(
            (user) => user.email.toLowerCase() == this.userName.toLowerCase()
          );
          if (currentUser) {
            this.userRoles = RoleMapping[currentUser.role];
            this.cd.markForCheck();
          }
        }
        this.setIsOwnerOrAdmin(this.userRoles);
        this.cd.markForCheck();
      }, (error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.userRoles = 'Foglight Cloud User';
          this.setIsOwnerOrAdmin(this.userRoles);
          this.cd.markForCheck();
        }
      });
  }

  private setIsOwnerOrAdmin(userRoles: string) {
    if (
      userRoles.includes('Foglight Cloud Owner') ||
      userRoles.includes('Foglight Cloud Administrator')
    ) {
      this.isOwnerOrAdmin = true;
    } else {
      this.isOwnerOrAdmin = false;
    }
  }

  private setBreadcrumbItems() {
    this.breadcrumbItems = {
      name: 'Account Settings',
      label: 'Account Settings',
      type: 'label',
      target: '_self',
      link: this.router.url,
      itemType: 'AUI',
    };
  }

  addListenerForThemeChange() {
    window.addEventListener('themeChanged', (event: CustomEvent) => {
      if (event.detail == 'dark-theme') {
        this.theme = 'dark-theme';
      } else {
        this.theme = 'light-theme';
      }
      this.cd.detectChanges();
    });
  }

  onLogoutClick() {
    this.authService.logout();
    window.sessionStorage.removeItem('dates');
    window.sessionStorage.removeItem('search_value');
    window.sessionStorage.removeItem('page_limit');
    window.sessionStorage.removeItem('direction');
    window.sessionStorage.removeItem('lastIframeURLBeforeLogout');
    window.sessionStorage.removeItem('selectedItem');
    window.sessionStorage.removeItem('itemLink');
    window.sessionStorage.removeItem('breadcrumbHistory');
  }
}
