/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ListDataRefresher } from '@foglight/angular-common';
import { LegalNoticeListDataRefresherService } from './legal-notice/legal-notice-list-data-refresher.service';
import { Store } from '@ngrx/store';
import { serverInfoSelector } from 'src/app/store/app.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'fc-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    LegalNoticeListDataRefresherService,
    {
      provide: ListDataRefresher,
      useExisting: LegalNoticeListDataRefresherService,
    }
  ]
})
export class AboutDialogComponent implements OnInit {
version:string = '';
isCloudFlag : boolean = false;
  constructor(public dialogRef: MatDialogRef<AboutDialogComponent>,
              public refresher: LegalNoticeListDataRefresherService,
              private store: Store) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.refresher.queryOnce();
    this.store.select(serverInfoSelector).pipe(untilDestroyed(this)).subscribe((serverInfo)=>{
    this.version = serverInfo.version;
    this.isCloudFlag = serverInfo.isCloud;
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  supportClick(): void {
    window.open('https://www.quest.com/company/contact-us.aspx', '_self');
  }
}
