/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { Injectable } from '@angular/core';
import { FacHttpClientService, FacDropDownSearchResponse } from '@foglight/angular-common';
import { iteratee } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class FacNavbarService {
  userSelectedTheme$ = new Subject<string>();

  constructor(private httpClient: FacHttpClientService) { }

  getGlobalSearchResult(searchKey: string, maxResults: number = 100): Observable<FacDropDownSearchResponse> {
    return this.httpClient.post<FacDropDownSearchResponse>(`/aui/api/v1/portal/global-search`, {
      searchKey,
      maxResults
    });
  }

  updateServerInstanceName(name: string): Observable<any> {
    return this.httpClient.patch('/aui/api/v1/portal/product', 
    {
      serverInstance: {
        name: name
      }
    }) 
  }

  getRunningServerInfo(): Observable<any> {
    return this.httpClient.get(`/aui/api/v1/portal/product`);
  }

  getListofDownloadableFglams(): Observable<any> {
    return this.httpClient.get(`/aui/api/v1/portal/downloads/installers`);
  }

  setUserSelectedTheme(selectedTheme): Observable<any> {
    return this.httpClient.patch(`/aui/api/v1/portal/user`,
    {
      userSettings: {
        themes: {
          main: selectedTheme,
        }
    }
  });
  }
}
