/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

/**
 * Copyright 2018 Quest Software and/or its affiliates
 * and other contributors as indicated by the @author tags.
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export interface IconRegistryItem {
  name: string;
  literal?: string;
  url?: string;
  namespace?: string;
}

export const ICON_REGISTRY_CONFIG: IconRegistryItem[] = [
  // left-side navigation bar
  {
    name: 'fg-alarms',
    url: 'assets/images/icons/alarms.svg',
  },
  {
    name: 'fg-alarm-template',
    url: 'assets/images/icons/alarm template.svg',
  },
  {
    name: 'alarmmanagement',
    url: 'assets/images/icons/alarms.svg',
  },
  {
    name: 'fg-aws-hosts',
    url: 'assets/images/icons/aws_host.svg',
  },
  {
    name: 'fg-bmc-remedy',
    url: 'assets/images/icons/bmc_remedy.svg',
  },
  {
    name: 'fg-capacity-director',
    url: 'assets/images/icons/capacity_director.svg',
  },
  {
    name: 'fg-cloudmanager',
    url: 'assets/images/icons/cloud manager.svg',
  },
  {
    name: 'fg-global-view',
    url: 'assets/images/icons/global view.svg',
  },
  {
    name: 'fg-notification-management',
    url: 'assets/images/icons/notification_channels.svg',
  },
  {
    name: 'fg-apm-dependency',
    url: 'assets/images/icons/amp.svg',
  },
  {
    name: 'fg-cassandra',
    url: 'assets/images/icons/cassandra.svg',
  },
  {
    name: 'fg_cloud_cartridge',
    url: 'assets/images/icons/cloud-cartridge.svg',
  },
  {
    name: 'fg-cons-advisor',
    url: 'assets/images/icons/cons_advisor.svg',
  },
  {
    name: 'fg-cloudmigration',
    url: 'assets/images/icons/cloud_migration.svg',
  },
  {
    name: 'fg_container_cartridge',
    url: 'assets/images/icons/container-cartridge.svg',
  },
  {
    name: 'fg-costdirector',
    url: 'assets/images/icons/cost director.svg',
  },
  {
    name: 'fg-databases',
    url: 'assets/images/icons/databases.svg',
  },
  {
    name: 'fg-db-cartridge',
    url: 'assets/images/icons/db-cartridge.svg',
  },
  {
    name: 'fg-apm-app-ops',
    url: 'assets/images/icons/apm.svg',
  },
  {
    name: 'fg-development-tools',
    url: 'assets/images/icons/development-tools.svg',
  },
  {
    name: 'fg-enduser',
    url: 'assets/images/icons/end_user.svg',
  },
  {
    name: 'fg-exchange',
    url: 'assets/images/icons/exchange.svg',
  },
  {
    name: 'fg-infrastructure',
    url: 'assets/images/icons/infrastructure.svg',
  },
  {
    name: 'fg-ibm-cloud',
    url: 'assets/images/icons/ibmcloud.svg',
  },
  {
    name: 'fg-javaeeroot-jmx',
    url: 'assets/images/icons/jmx.svg',
  },
  {
    name: 'fg-ome-dashboard',
    url: 'assets/images/icons/ome.svg',
  },
  {
    name: 'fg-openstack',
    url: 'assets/images/icons/openstack.svg',
  },
  {
    name: 'fg_os_cartridge',
    url: 'assets/images/icons/os-cartridge.svg',
  },
  {
    name: 'fg-reports',
    url: 'assets/images/icons/reports.svg',
  },
  {
    name: 'fg-pso',
    url: 'assets/images/icons/pso-utilities.svg',
  },
  {
    name: 'fg-selector',
    url: 'assets/images/icons/environment_overview.svg',
  },
  {
    name: 'fg-services',
    url: 'assets/images/icons/services.svg',
  },
  {
    name: 'fg-sybasemda',
    url: 'assets/images/icons/sybase_mda.svg',
  },
  {
    name: 'fg-virtual-machine',
    url: 'assets/images/icons/virtual-machine.svg',
  },
  {
    name: 'fg-bookmarks',
    url: 'assets/images/icons/bookmarks.svg',
  },
  {
    name: 'fg-administration',
    url: 'assets/images/icons/administration.svg',
  },
  {
    name: 'fg-dependency-mapping',
    url: 'assets/images/icons/dependency-mapping.svg',
  },
  {
    name: 'fg-apache',
    url: 'assets/images/icons/apache.svg',
  },
  {
    name: 'fg-dbwc',
    url: 'assets/images/icons/databases.svg',
  },
  {
    name: 'fg-docker',
    url: 'assets/images/icons/container.svg',
  },
  {
    name: 'fg-HyperV',
    url: 'assets/images/icons/hyperv.svg',
  },
  {
    name: 'fg-netvault',
    url: 'assets/images/icons/netvault.svg',
  },
  {
    name: 'fg-office365',
    url: 'assets/images/icons/365.svg',
  },
  {
    name: 'fg-pagerDuty',
    url: 'assets/images/icons/pager-duty.svg',
  },
  {
    name: 'fg-rapid20recovery',
    url: 'assets/images/icons/data_protection.svg',
  },
  {
    name: 'fg-storage',
    url: 'assets/images/icons/storage & san.svg',
  },
  {
    name: 'fg-virtualvmw',
    url: 'assets/images/icons/vm.svg',
  },
  {
    name: 'fg-vmwareview',
    url: 'assets/images/icons/vmware-view.svg',
  },
  {
    name: 'fg-web-monitor',
    url: 'assets/images/icons/web-monitor.svg',
  },
  {
    name: 'fg-websphere20m',
    url: 'assets/images/icons/MQ.svg',
  },
  {
    name: 'fg-vcloud',
    url: 'assets/images/icons/vcloud-director.svg',
  },
  {
    name: 'fg-xendesktop',
    url: 'assets/images/icons/xendesk.svg',
  },
  {
    name: 'fg-mongodb',
    url: 'assets/images/icons/mongodb.svg',
  },
  {
    name: 'fg-mysql',
    url: 'assets/images/icons/mysql.svg',
  },
  {
    name: 'fg-postgress',
    url: 'assets/images/icons/postgress.svg',
  },
  // alarm template

  {
    name: 'cassandra',
    url: 'assets/images/icons/cassandra.svg',
  },
  {
    name: 'db2',
    url: 'assets/images/icons/db2.svg',
  },
  {
    name: 'mysql',
    url: 'assets/images/icons/mysql.svg',
  },
  {
    name: 'oracle',
    url: 'assets/images/icons/oracle.svg',
  },
  {
    name: 'sql-server',
    url: 'assets/images/icons/sql.svg',
  },
  {
    name: 'sql-server-analysis-services',
    url: 'assets/images/icons/sql-server-analysis-services.svg',
  },
  {
    name: 'sql-server-integration-services',
    url: 'assets/images/icons/sql-server-integration-services.svg',
  },
  {
    name: 'sql-server-reporting-services',
    url: 'assets/images/icons/sql-server-reporting-services.svg',
  },
  {
    name: 'infrastructure',
    url: 'assets/images/icons/infrastructure.svg',
  },
  {
    name: 'custom',
    url: 'assets/images/icons/custom.svg',
  },
  {
    name: 'vmware',
    url: 'assets/images/icons/vm.svg',
  },
  {
    name: 'hyperv',
    url: 'assets/images/icons/hyperv.svg',
  },
  {
    name: 'aws',
    url: 'assets/images/icons/aws.svg',
  },
  {
    name: 'exchange',
    url: 'assets/images/icons/exchange.svg',
  },
  {
    name: 'exchange2',
    url: 'assets/images/icons/exchange2.svg',
  },
  {
    name: 'synthetic',
    url: 'assets/images/icons/synthetic.svg',
  },
  {
    name: 'optimizer',
    url: 'assets/images/icons/optimizer.svg',
  },
  {
    name: 'optimizerautomation',
    url: 'assets/images/icons/optimizer_automation.svg',
  },
  {
    name: 'changemanager',
    url: 'assets/images/icons/change_manager.svg',
  },
  {
    name: 'protection',
    url: 'assets/images/icons/data_protection.svg',
  },
  {
    name: 'dockerswarm',
    url: 'assets/images/icons/DockerSwarm.svg',
  },
  {
    name: 'azure-sql-database',
    url: 'assets/images/icons/azure_sql.svg',
  },
  {
    name: 'azure',
    url: 'assets/images/icons/azure.svg',
  },
  {
    name: 'activedirectory',
    url: 'assets/images/icons/active_directory.svg',
  },
  {
    name: 'googlecloud',
    url: 'assets/images/icons/google.svg',
  },
  {
    name: 'kubernetes',
    url: 'assets/images/icons/containers.svg',
  },
  {
    name: 'dependency',
    url: 'assets/images/icons/dependency-mapping.svg',
  },
  {
    name: 'oracle-exadata-database-machine',
    url: 'assets/images/icons/oracle-exadata-database-machine.svg',
  },
  {
    name: 'oracle-automatic-storage-management',
    url: 'assets/images/icons/oracle-automatic-storage-management.svg',
  },
  {
    name: 'oracle-cluster-ready-services',
    url: 'assets/images/icons/oracle-cluster-ready-services.svg',
  },
  {
    name: 'host',
    url: 'assets/images/icons/host.svg',
  },
  {
    name: 'storage',
    url: 'assets/images/icons/storage.svg',
  },
  {
    name: 'sap-(sybase)-adaptive-server-enterprise',
    url: 'assets/images/icons/sap-server.svg',
  },
  {
    name: 'sap-(sybase)-replication-server',
    url: 'assets/images/icons/sap-replic2.svg',
  },
  {
    name: 'vmwareview',
    url: 'assets/images/icons/vmware-view.svg',
  },
  {
    name: 'vclouddirector',
    url: 'assets/images/icons/vcloud-director.svg',
  },
  {
    name: 'xendesktop',
    url: 'assets/images/icons/xendesk.svg',
  },
  {
    name: 'enduser',
    url: 'assets/images/icons/end_user.svg',
  },
  {
    name: 'mongodb',
    url: 'assets/images/icons/mongodb.svg',
  },
  {
    name: 'postgresql',
    url: 'assets/images/icons/postgress.svg',
  },
  {
    name: 'snowflake',
    url: 'assets/images/icons/snowflake.svg',
  },
  {
    name: 'saphana',
    url: 'assets/images/icons/sap_hana.svg',
  },
  {
    name: 'redshift',
    url: 'assets/images/icons/redshift.svg',
  },
  {
    name: 'redis',
    url: 'assets/images/icons/reddis.svg',
  },

  // statuses
  {
    name: 'status-fatal',
    url: 'assets/images/icons/status-fatal.svg',
  },
  {
    name: 'status-critical',
    url: 'assets/images/icons/status-critical.svg',
  },
  {
    name: 'status-warning',
    url: 'assets/images/icons/status-warning.svg',
  },

  //header
  {
    name: 'user-logo',
    url: 'assets/images/icons/user-logo.svg',
  },
  {
    name: 'header-menu',
    url: 'assets/images/icons/header-menu.svg',
  },
  {
    name: 'user-logo-full',
    url: 'assets/images/icons/user-logo-full.svg',
  },
  {
    name: 'header-menu-full',
    url: 'assets/images/icons/header-menu-full.svg',
  },
  {
    name: 'account-settings-user',
    url: 'assets/images/icons/account-settings-user.svg',
  },

  {
    name: 'account-settings-chart',
    url: 'assets/images/icons/account-settings-chart.svg',
  },
  {
    name: 'account-settings-chart-dark',
    url: 'assets/images/icons/account-settings-chart-dark.svg',
  },
  {
    name: 'none-status',
    url: 'assets/images/icons/none-status.svg',
  },
  {
    name: 'minor-status',
    url: 'assets/images/icons/minor-status.svg',
  },
  {
    name: 'major-status',
    url: 'assets/images/icons/major-status.svg',
  },
  {
    name: 'critical-status',
    url: 'assets/images/icons/critical-status.svg',
  },
  {
    name: 'maintenance-status',
    url: 'assets/images/icons/maintenance-status.svg',
  }
];
