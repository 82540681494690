/**
 * QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 * This software is confidential. Quest Software Inc., or one of its
 * subsidiaries, has supplied this software to you under terms of a
 * license agreement, nondisclosure agreement or both.
 *
 * You may not copy, disclose, or use this software except in accordance with
 * those terms.
 *
 *
 * Copyright 2023 Quest Software Inc.
 * ALL RIGHTS RESERVED.
 *
 * QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 * WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 * EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 * TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR
 * NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 * LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 * AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 * THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { Injectable } from '@angular/core';
import {
  SubscriptionDisplayDetails,
  SubscriptionsInfo,
  SubscriptionsPayLoad,
  UsageInfo,
  expiredSoonSubscription,
} from '../../_models/subscriptions-details';
import { SubscriptionTypes } from '../../_models/subscriptions-details';
import moment from 'moment';

@Injectable()
export class SubscriptionsDetailsService {
  public _expiredSubscriptions: string[];
  get getExpiredSubscriptions(): string[] {
    return this._expiredSubscriptions;
  }
  set setExpiredSubscriptions(expiredSubscriptions: string[]) {
    this._expiredSubscriptions = expiredSubscriptions;
  }

  public _expiredSoonSubscriptions: expiredSoonSubscription[];
  get getExpiredSoonSubscriptions(): expiredSoonSubscription[] {
    return this._expiredSoonSubscriptions;
  }
  set setExpiredSoonSubscriptions(
    expiredSoonSubscriptions: expiredSoonSubscription[]
  ) {
    this._expiredSoonSubscriptions = expiredSoonSubscriptions;
  }

  constructor() {}

  /**
   * Create SubscriptionDetailList with all relevant information to display on UI screen
   * @param subscriptionList API out put
   * @returns Subscription Display Details Data
   */
  modifySubscriptionData(
    subscriptionList: SubscriptionsInfo[]
  ): SubscriptionDisplayDetails[] {
    let subscriptionDetailList: SubscriptionDisplayDetails[] = [];
    this._expiredSoonSubscriptions = [];
    this._expiredSubscriptions = [];
    SubscriptionTypes.forEach((subscriptionType) => {
      let subscriptionDetails: SubscriptionDisplayDetails = {};
      let subscriptionsInfo = subscriptionList.find((subscription) =>
        subscription.name.toUpperCase().includes(subscriptionType.toUpperCase())
      );
      if (subscriptionsInfo) {
        let expirationDate = moment(subscriptionsInfo.expirationDate);
        let currentDate = moment();
        let dayLeft = expirationDate.diff(currentDate, 'days');
        this.identifyExpiredSubscriptions(dayLeft, subscriptionType);

        subscriptionDetails.name = subscriptionsInfo.name;
        subscriptionDetails.type = subscriptionType;
        subscriptionDetails.expirationDate =
          expirationDate.format('MMMM Do, YYYY');
        subscriptionDetails.activeStatus = currentDate.isSameOrBefore(
          expirationDate
        )
          ? 'Active'
          : 'InActive';

        subscriptionDetails.dayLeft = dayLeft < 0 ? 0 : dayLeft;
        subscriptionDetails.committedQuantity =
          subscriptionsInfo.committedQuantity;
        subscriptionDetails.isSubscribe = true;
        subscriptionDetails.overageQuantity = this.findOverageQuantity(
          subscriptionsInfo.usage,
          subscriptionsInfo.committedQuantity
        );
      } else {
        subscriptionDetails.name = 'Foglight Cloud for ' + subscriptionType;
        subscriptionDetails.type = subscriptionType;
        subscriptionDetails.isSubscribe = false;
        subscriptionDetails.activeStatus = 'InActive';
      }
      subscriptionDetailList.push(subscriptionDetails);
    });
    return this.arrangeOrderOfSubscriptionType(subscriptionDetailList);
  }

  /**
   * Identify expired Subscriptions/ About to expired
   * @param day Day left for Subscriptions end
   * @param subscriptionType Subscriptions type
   * @returns
   */
  identifyExpiredSubscriptions(day: number, subscriptionType: string) {
    if (day <= 60 && day > 0) {
      this._expiredSoonSubscriptions.push({
        type: subscriptionType,
        dayLeft: day,
      });
      return;
    }
    if (day < 1) {
      this._expiredSubscriptions.push(subscriptionType);
    }
  }

  /**
   * Rearrange subscription Detail for Active subscription should come first
   * @param subscriptionDetailList
   * @returns
   */
  arrangeOrderOfSubscriptionType(
    subscriptionDetailList: SubscriptionDisplayDetails[]
  ): SubscriptionDisplayDetails[] {
    const isActive = subscriptionDetailList.find(
      (subscription) => subscription.activeStatus === 'Active'
    );
    if (isActive) {
      subscriptionDetailList.sort((a, b) =>
        a.activeStatus.localeCompare(b.activeStatus)
      );
    }
    return subscriptionDetailList;
  }

  /**
   * Calculate total overage Quantity for give data
   * @param usageInfo usage Info
   * @returns total overage Quantity
   */
  findOverageQuantity(
    usageInfo: UsageInfo[],
    committedQuantity: number
  ): number {
    let overageQuantity = 0;

    let quantityList = usageInfo.map((usage) => usage.quantity);
    let maxQuantity = Math.max(...quantityList);
    if (maxQuantity > committedQuantity) {
      overageQuantity = maxQuantity - committedQuantity;
    }
    return overageQuantity;
  }

  /**
   * Find Active subscription name list
   * @param subscriptionDisplayDetails
   * @returns Active subscription name List
   */

  findActiveSubscriptions(
    subscriptionDisplayDetails: SubscriptionDisplayDetails[]
  ): string[] {
    let activeSubscriptions = subscriptionDisplayDetails.filter(
      (subscription) => subscription.activeStatus === 'Active'
    );
    return activeSubscriptions.map((subscription) => subscription.name);
  }
}
