/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { FacHttpClientService, FacUserService } from '@foglight/angular-common';
import { Store } from '@ngrx/store';
import { cond } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FacNavbarService } from 'src/app/layout/navbar/navbar.service';
import { isCloudFlagSelector } from 'src/app/store/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityGuard implements CanActivate {
  unavailableMessage;
  isCloud;
  constructor(
    private router: Router,
    private httpClient: FacHttpClientService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.httpClient.get(`/aui/api/v1/portal/product`).pipe(
      map((serverInfo) => {      
        let canLoad = false;
        // users management availability
        if (route.url.toString().includes('manage-users')) {
          canLoad = serverInfo['isCloud']
          if (!canLoad) {
            this.unavailableMessage =
              'This page is not available for this deployment type';
            sessionStorage.setItem('lastErrorMessage', this.unavailableMessage);
            this.router.navigateByUrl('unavailable');
          }
        }

        if (route.url.toString().includes('home')) {
          canLoad = serverInfo['isCloud']
          if (!canLoad) {
            this.unavailableMessage =
              'This page is not available for this deployment type';
            sessionStorage.setItem('lastErrorMessage', this.unavailableMessage);
            this.router.navigateByUrl('unavailable');
          }
        }

        if (route.url.toString().includes('system-aui-administration')) {          
          canLoad = serverInfo['isCloud']
          if (!canLoad) {
            this.unavailableMessage =
              'This page is not available for this deployment type';
            sessionStorage.setItem('lastErrorMessage', this.unavailableMessage);
            this.router.navigateByUrl('unavailable');
          }
        }

        return canLoad;
      }),
      catchError((error) => of(false))
    );
  }
}
