import { Env } from './env';

const baseHref = '/aui';
export const environment: Env = {
  production: true,
  foglightBaseUrl: window.location.origin,
  angularBaseUrl: `${window.location.origin}${baseHref}`,
  networkTimeout: 60,
  baseHref: '/aui'
};
