/**
 * QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 * This software is confidential. Quest Software Inc., or one of its
 * subsidiaries, has supplied this software to you under terms of a
 * license agreement, nondisclosure agreement or both.
 *
 * You may not copy, disclose, or use this software except in accordance with
 * those terms.
 *
 *
 * Copyright 2023 Quest Software Inc.
 * ALL RIGHTS RESERVED.
 *
 * QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 * WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 * EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 * TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR
 * NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 * LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 * AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 * THIS SOFTWARE OR ITS DERIVATIVES.
 */

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  isCloudFlagSelector,
  isCloudUser,
  isSaasAcceptedSelector,
} from 'src/app/store/app.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { skipEmpty } from 'src/app/shared/utils/skip-empty.operator';

@UntilDestroy()
@Component({
  selector: 'fc-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent implements OnInit {
  isSaasAccepted$: Observable<any>;
  saasAccepted: boolean;
  showPage: boolean = false;
  isCloudEnv$ : Observable<boolean>;
  constructor(private store: Store, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isCloudEnv$ = this.store.select(isCloudFlagSelector);

    this.isCloudEnv$.pipe(untilDestroyed(this)).subscribe((isCloud)=>{
       if(isCloud){
        this.store.select(isCloudUser).pipe(untilDestroyed(this)).subscribe((cloudUser)=>{
          if(cloudUser){
           this.isSaasAccepted$ = this.store.select(isSaasAcceptedSelector);
           this.isSaasAccepted$
          .pipe(untilDestroyed(this),skipEmpty())
          .subscribe((saasAccepted) => {
            this.saasAccepted = saasAccepted;
            this.showPage = true;
            this.cd.markForCheck();
          });
          }
          else{
            this.saasAccepted = true;
            this.showPage = true;
            this.cd.markForCheck();
          }
        })
       }
       else{
        this.showPage = true;
        this.cd.markForCheck();
       }
    })    

  }
}
