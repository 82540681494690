/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FacHttpClientService } from '@foglight/angular-common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WCFauthGuard implements CanActivate {

  constructor(
    private httpClient: FacHttpClientService,
    private router: Router
  ) {}
  
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
      let viewId=route.queryParamMap.get("viewId");
      sessionStorage.setItem('viewId', viewId);
      if(viewId){
        let requestHeader={"viewId": viewId};
       return this.httpClient.post(`/aui/api/v1/portal/navigation/is-authorized`,requestHeader).pipe(map((res)=>{
          if(!res['isAuthorized']){
            let unauthorizedMessage = "You do not have the required permissions to access this page";  
            sessionStorage.setItem('lastErrorMessage', unauthorizedMessage);
            this.router.navigateByUrl('unauthorized');
          }  
          return res['isAuthorized'];
        })) 
      }else{  
          return true;
      }
  }
  
}
