/*
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SnackBarService } from '../../services/snack-bar.service';

@UntilDestroy()
@Component({
  selector: 'fc-snack-bar-success',
  templateUrl: './snack-bar-success.component.html',
  styleUrls: ['./snack-bar-success.component.scss']
})
export class SnackBarSuccessComponent implements OnInit {

  constructor(
    private snackService: SnackBarService,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
  }

  ngOnInit(): void {

  }

  dismissSnack() {
    this.snackService.closeSnackBar();
  }
}
