/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { SidebarService } from './sidebar/services/sidebar.service';
import { FacNavbarService } from './navbar/navbar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LayoutService } from '@services/layout.service';
import { AUIPages } from './sidebar/models/nav-item';
import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

@UntilDestroy()
@Component({
  selector: 'fc-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('routingAnimation', [
      transition('* => *', [
        style({ position: 'relative' }),
        query(':enter', [style({ opacity: 0 })], { optional: true }),
        query(
          ':leave',
          [
            style({
              opacity: 1,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
            }),
            animate('0.2s', style({ opacity: 0 })),
          ],
          { optional: true }
        ),
        query(
          ':enter',
          [
            style({
              opacity: 0,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
            }),
            animate('0.2s', style({ opacity: 1 })),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class LayoutComponent implements OnInit {
  theme: string;
  theme_content_background: string;
  showSidebar: boolean = false;
  pinnedState$ = this.sidebarService.pinnedState$;

  constructor(
    private sidebarService: SidebarService,
    private navbarService: FacNavbarService,
    private cdRef: ChangeDetectorRef,
    private layoutService: LayoutService,
  ) {}

  checkTheme() {
    let fullPath: string = window.location.href;
    this.theme_content_background = 'light';
    this.theme = localStorage.getItem('theme');
    const AUIPagesWithTheme = AUIPages.some((page) => fullPath.includes(page));

    if (fullPath.includes('wcf') || AUIPagesWithTheme) {
      if (this.theme == 'dark-theme') {
        this.theme_content_background = 'dark';
      } else {
        this.theme_content_background = 'light';
      }
    } else {
      this.theme_content_background = 'light';
    }

    this.cdRef.detectChanges();
  }

  ngDoCheck(): void {
    this.checkTheme();
  }

  ngOnInit(): void {
    this.navbarService.userSelectedTheme$
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.theme = res;
        this.checkTheme();
        this.cdRef.detectChanges();
      });
    this.layoutService.verifyCloudAndSaasAcceptance();
    this.layoutService.showSideBar
      .pipe(untilDestroyed(this))
      .subscribe((showSidebar) => {
        this.showSidebar = showSidebar;
        this.cdRef.detectChanges();
      });
  }

  unfoldTemporarily() {
    this.pinnedState$.next(false);
  }

  foldTemporarily() {
    this.pinnedState$.next(true);
  }
}
