/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarHttpService } from './services/sidebar-http.service';
import { NavigationMenuPanelComponent } from './components/nav-menu-panel/nav-menu-panel.component';
import { MenuListItemComponent } from './components/menu-list/menu-list-item/menu-list-item.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { EditModePanelComponent } from './components/edit-mode-panel/edit-mode-panel.component';
import { MenuListComponent } from './components/menu-list/menu-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddBookmarkDialogComponent } from './components/add-bookmark-dialog/add-bookmark-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FacFormModule } from '@foglight/angular-common';
import { DeleteBookmarkDialogComponent } from './components/delete-bookmark-dialog/delete-bookmark-dialog.component';
import { SnackBarService } from 'src/app/services/snack-bar.service';

const MATERIAL_MODULES = [
  // material modules
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatTooltipModule,
  MatInputModule,
  MatCheckboxModule
];

@NgModule({
  declarations: [
    SidebarComponent,
    NavigationMenuPanelComponent,
    MenuListComponent,
    EditModePanelComponent,
    MenuListItemComponent,
    AddBookmarkDialogComponent,
    DeleteBookmarkDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MATERIAL_MODULES,
    FormsModule,
    ReactiveFormsModule,
    FacFormModule
  ],
  exports: [
    SidebarComponent,
    NavigationMenuPanelComponent
  ],
  providers: [
    SidebarHttpService,
    SnackBarService
  ]
})
export class SidebarModule {
}
