/**
 * QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 * This software is confidential. Quest Software Inc., or one of its
 * subsidiaries, has supplied this software to you under terms of a
 * license agreement, nondisclosure agreement or both.
 *
 * You may not copy, disclose, or use this software except in accordance with
 * those terms.
 *
 *
 * Copyright 2023 Quest Software Inc.
 * ALL RIGHTS RESERVED.
 *
 * QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 * WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 * EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 * TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR
 * NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 * LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 * AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 * THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { userInfo } from 'src/app/store/app.selectors';
import { appModuleActions } from '../../users-management/store/action-types';
import { FacUserService } from '@foglight/angular-common';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'fc-saas-adendum-page',
  templateUrl: './saas-adendum-page.component.html',
  styleUrls: ['./saas-adendum-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaasAdendumPageComponent implements OnInit {
  isChecked: boolean = false;
  isOwner: boolean = false;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(userInfo)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        if (user.roles.includes('Foglight Cloud Owner')) {
          this.isOwner = true;
        } else {
          this.isOwner = false;
        }
      });
  }

  onCheckboxValueChange(event) {
    this.isChecked = event.checked;
  }

  loadWelcomePage(event) {
    this.store.dispatch(appModuleActions.createSaasAddendumAcceptance());
  }
}
