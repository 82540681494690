<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->

<div class="search-container">
    <div class="legal-notice-desc">Third party components. Learn more about <a class="link" href="https://www.quest.com/legal/third-party-licenses.aspx" target="_blank">Quest third party licenses</a>.</div>
    <div><fac-search-input [formControl]="searchFormControl" placeholder="Search"></fac-search-input></div>
</div>
<div class="legal-notice-table-container">
    <fac-table [dataSource]="dataSource"
        [showPaginator]="false"
        [fixedHeader]="true"
        trackByProperty="componentName"
        >
        <fac-table-column header="Component" [cellProperty]="'componentName'" [cellRef]="componentNameRef">
          <ng-template #componentNameRef let-row>
            <p class="component-name"
            [matTooltip]="row.componentName"
            matTooltipClass="tooltip"
            [matTooltipPosition]="'below'"
            fcShowIfTruncated>{{row.componentName}}</p>
        </ng-template>
        </fac-table-column>

        <fac-table-column header="Version" class="version-header"
                    [cellProperty]="'componentVersion'"></fac-table-column>

        <fac-table-column header="Legal Notice"
                    cellProperty="license"
                    [cellRef]="legalNoticeRef"></fac-table-column>
        <ng-template #legalNoticeRef let-row>
            <a class="link" [href]="row.licenseUrl" target="_blank">
              <p class="license-name"
              [matTooltip]="row.license"
              matTooltipClass="tooltip"
              [matTooltipPosition]="'below'"
              fcShowIfTruncated>{{row.license}}</p></a>
        </ng-template>
    </fac-table>
</div>
