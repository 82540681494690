

/*
 * QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 * This software is confidential.  Quest Software Inc., or one of its
 * subsidiaries, has supplied this software to you under terms of a
 * license agreement, nondisclosure agreement or both.
 *
 * You may not copy, disclose, or use this software except in accordance with
 * those terms.
 *
 *
 * Copyright 2023 Quest Software Inc.
 * ALL RIGHTS RESERVED.
 *
 * QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 * WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 * EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 * TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR
 * NON-INFRINGEMENT.  QUEST SOFTWARE SHALL NOT BE
 * LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 * AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 * THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { Injectable } from '@angular/core';
// import { NotificationChannel } from '../models/notification-management.model';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Action, Store } from '@ngrx/store';
// import * as fromUsersManagement from './reducers/index';
import { Actions, ofType } from '@ngrx/effects';
// import * as NotificationChannelActions
//   from '../../notification-management/notification-management-channels/store/notification-management-channels.actions';
import { filter, first, take } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { AppState } from 'src/app/reducers';


@Injectable({
  providedIn: 'root',
})
export class UsersManagementService {

  domainCheckboxes = new Subject<boolean>();


}
