/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap,} from 'rxjs/operators';
import { FacHttpClientService } from '@foglight/angular-common';
import { appModuleActions } from 'src/app/views/users-management/store/action-types';
import { isCloudUser } from 'src/app/store/app.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from '@services/snack-bar.service';
import { SnackBarDeleteComponent } from '../snack-bar-delete/snack-bar-delete.component';
import { WcfLinkToAuiLinkMapping } from 'src/app/layout/sidebar/models/nav-item';
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SaasAcceptanceGuard implements CanActivate {
  isCloudUser = false;
  constructor(
    private router: Router,
    private store: Store,
    private httpClient: FacHttpClientService,
    private snackService: SnackBarService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.httpClient.get(`/aui/api/v1/portal/product`).pipe(
     switchMap((serverInfo) => {
        let isCloud = serverInfo['isCloud'];        
        if (isCloud) {
          return this.httpClient.get(`/aui/api/v1/portal/user`).pipe(switchMap((user)=>{
            this.store.dispatch(appModuleActions.setUserInfo({userInfo : user}));
            this.store.select(isCloudUser).pipe(untilDestroyed(this)).subscribe((cloudUser)=>{               
              this.isCloudUser = cloudUser;
            })
           if(this.isCloudUser){           
            return this.httpClient.get<any>('/api/v1/foglight-cloud/legal/saas-addendum/acceptance').pipe(catchError((error)=>{             
              if(error.error.status == 404){
                return of(error.error);
              }
              else{
                this.snackService.openSnackBar(
                  SnackBarDeleteComponent,
                  'error-snack-bar',
                  'right',
                  'top',
                  3000,
                  { text: "Something went wrong. Please try again later." }
                );
                return throwError(error);
              }
            }),map((user)=>{                                                   
              if(user.userName){
                this.store.dispatch(appModuleActions.setSaasAddendumAcceptance({saasAccepted : true})); 
                let viewId=route.queryParamMap.get("viewId");                          
                if(viewId && WcfLinkToAuiLinkMapping[viewId]){                                
                  this.router.navigateByUrl(WcfLinkToAuiLinkMapping[viewId]);
                }
                else{                  
                  return true;
                }              
              }
              else if(user.status == 404){                        
                this.store.dispatch(appModuleActions.setSaasAddendumAcceptance({saasAccepted : false}));
                this.router.navigateByUrl('/home');
              }
            }))  
           }
           else{            
              return of(true);
           }
          }))              
        }
        else{         
          return of(true);
        }
      })
    );
  }
}
