/*
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FacHttpClientService } from '@foglight/angular-common';
import { NavItem } from '../models/nav-item';

export interface Bookmark {
  id?: string;
  name: string;
  link: string;
  displayName?: string;
  description?: string;
  visible?: boolean;
  icon?: string;
  children?: string[];
  type?: string;
}

export interface LeftSideMenuData {
  displayName: string;
  icon: string;
  description: string;
  children: LeftSideMenuData[];
  srcPath: string;
  parentId: string;
  moduleId: string;
  route: string;
  appName: string;
}

@Injectable()
export class SidebarHttpService {
  constructor(private http: FacHttpClientService) {}

  getBookmark(id: string): Observable<Bookmark> {
    return this.http.get('/aui/api/v1/portal/navigation/bookmarks/' + id);
  }

  getBookmarks(): Observable<Bookmark[]> {
    return this.http.get('/aui/api/v1/portal/navigation/bookmarks/');
  }

  createBookmark(bookmark: Bookmark): Observable<Bookmark> {
    return this.http.post('/aui/api/v1/portal/navigation/bookmarks/', bookmark);
  }

  editBookmark(id: string, bookmark: Bookmark): Observable<Bookmark> {
    return this.http.put('/aui/api/v1/portal/navigation/bookmarks/' + id, bookmark);
  }

  deleteBookmark(id: string): Observable<void> {
    return this.http.delete('/aui/api/v1/portal/navigation/bookmarks/' + id);
  }

  getLeftSideMenu() {
    return this.http.get<LeftSideMenuData[]>('/aui/api/v1/portal/navigation');
  }

  disableMenus(disabledMenusIds: string[]) {
    return this.http.put('/aui/api/v1/portal/disableItems', disabledMenusIds);
  }
}
