/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/

import {AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import { NgModel, NgControl } from '@angular/forms';

@Directive({
  selector: '[fcAutoWidth]'
})
export class FcAutoWidthDirective implements OnInit, AfterViewInit {
	@HostListener('keydown.escape', ['$event'])
	revertInputWidth(event: KeyboardEvent) {
		this.updateWidth();
		setTimeout(() => {
			this.element.nativeElement.blur();
		}, 0);
	}


	@Input() useValueProperty = false;
	constructor(
		private element: ElementRef,
		private renderer: Renderer2,
	) {
	}

	get style() {
		return getComputedStyle(this.element.nativeElement, '');
	}

	ngOnInit() {
		this.updateWidth();
	}

	ngAfterViewInit() {
			this.updateWidth();
	}


	@HostListener('input', ['$event.target'])
	public onInput(event: Event): void {
			this.updateWidth();
	}

	private getInputValue(): string {
		let value: string;
		if (this.useValueProperty) {
			value = this._getProperty('value');
		}
		return value || this._getProperty('value') || '';
	}

	setWidth(width: number): void {
		const { nativeElement } = this.element;
			 this.renderer.setStyle(nativeElement, 'width', width + 10 + 'px');
	}

	setWidthUsingText(text: string): void {
		this.setWidth(this.textWidth(text));
	}

	// Check placeholder width settings and set text width
	textForWidth(inputText: string, placeHolderText: string, setPlaceHolderWidth: boolean) {
		return (setPlaceHolderWidth && (inputText.length === 0)) ?
			placeHolderText : inputText;
	}

	textWidth(value: string): number {
		const ctx = this.renderer.createElement('canvas').getContext('2d');
		const { fontStyle, fontVariant, fontWeight, fontSize, fontFamily } = this.style;
		ctx.font = fontStyle + ' ' + fontVariant + ' ' + fontWeight + ' ' + fontSize + ' ' + fontFamily;
		return ctx!.measureText(value).width;
	}

	updateWidth(): void {
		const inputText = this.getInputValue();
		const placeHolderText = this._getProperty('placeholder');
		const inputTextWidth = this.textWidth(inputText);
		const setPlaceHolderWidth = true;
		this.setWidthUsingText(this.textForWidth(inputText, placeHolderText, setPlaceHolderWidth));
	}

	private _getProperty(property: 'value'|'placeholder') {
		return this.element.nativeElement?.[property];
	}

}
