/**
 * QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 * This software is confidential. Quest Software Inc., or one of its
 * subsidiaries, has supplied this software to you under terms of a
 * license agreement, nondisclosure agreement or both.
 *
 * You may not copy, disclose, or use this software except in accordance with
 * those terms.
 *
 *
 * Copyright 2023 Quest Software Inc.
 * ALL RIGHTS RESERVED.
 *
 * QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 * WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 * EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 * TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE, OR
 * NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 * LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 * AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 * THIS SOFTWARE OR ITS DERIVATIVES.
 */

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { HomePageHttpService } from '../_services/home-page-http.service';
import { UtilService } from '@services/util.service';

import { SubscriptionPendingService } from 'src/app/shared/dialogs/subscription-pending/subscription-pending.service';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import {
  isCloudFlagSelector,
  productInfo,
  userInfoRoles,
} from 'src/app/store/app.selectors';
import { NavbarComponent } from 'src/app/layout/navbar/navbar.component';
import { FacNavbarService } from 'src/app/layout/navbar/navbar.service';
import { Observable } from 'rxjs';
import { SidebarService } from 'src/app/layout/sidebar/services/sidebar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'fc-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomePageComponent implements OnInit {
  @ViewChild('agentBtn') agentButton: ElementRef;
  home: any = { icon: 'fac-home' };
  theme = 'light-theme';
  isExternalfglamRequired: boolean = false;
  subscriptionTypes: string[] = [];
  iconStyle = { 'margin-top': '-1px' };
  isCloudUser: boolean = false;
  showWelcomePage: boolean = false;
  isDbcartridgeInstalled: boolean = false;
  isEvolveCartridgeInstalled: boolean = false;
  evolveCartridgesList: Array<string> = [
    'system:virtualvmw',
    'system:HyperV',
    'system:cloud_manager',
    'system:docker',
  ];
  isCloudEnv$: Observable<boolean>;
  isCloud: boolean = false;
  navBarItems: Array<any> = [];
  items = [
    {
      label: 'Foglight Agent Manager for Windows',
      icon: 'fac-windows',
      disabled: false,
      tooltipText: '',
    },
    {
      label: 'Foglight Agent Manager for Linux',
      icon: 'fac-linux',
      disabled: false,
      tooltipText: '',
    },
  ];
  selectedItem;
  arrowState = true;
  constructor(
    private router: Router,
    private homePageHttpService: HomePageHttpService,
    private cd: ChangeDetectorRef,
    private store: Store,
    private utilService: UtilService,
    private subscriptionPendingService: SubscriptionPendingService,
    private titleService: Title,
    private navbarService: FacNavbarService,
    public sidebarService: SidebarService
  ) {}

  clientVersion: any;
  ngOnInit(): void {
    if (localStorage.getItem('theme')) {
      this.theme = localStorage.getItem('theme');
    }

    this.verifyIsCloudEnv();
    this.verifyIsCloudUser();
    this.getFglamClientInstance();

    this.utilService.clearBreadCrumbHistory();
    this.addListenerForThemeChange();
  }

  addListenerForThemeChange() {
    window.addEventListener('themeChanged', (event: CustomEvent) => {
      if (event.detail == 'dark-theme') {
        this.theme = 'dark-theme';
      } else {
        this.theme = 'light-theme';
      }
      this.cd.detectChanges();
    });
  }

  verifyIsCloudEnv() {
    this.isCloudEnv$ = this.store.select(isCloudFlagSelector);
    this.isCloudEnv$.subscribe((isCloudEnv) => {
      this.isCloud = isCloudEnv;
      if (!isCloudEnv) {
        this.getInstalledCartridgeInstances();
      }
    });
  }

  getInstalledCartridgeInstances() {
    this.sidebarService.navItems$
      .pipe(untilDestroyed(this))
      .subscribe((items) => {
        this.navBarItems = items;
        if (this.navBarItems.length) {
          this.isDbcartridgeInstalled = this.navBarItems.some((item) => {
            return item.id == 'system:dbwc';
          });
          this.isEvolveCartridgeInstalled = this.navBarItems.some((item) => {
            return this.evolveCartridgesList.includes(item.id);
          });
          this.cd.detectChanges();
        }
      });
  }

  verifyIsCloudUser() {
    this.store.select(userInfoRoles).subscribe((roles) => {
      if (
        roles?.includes('Foglight Cloud User') &&
        !roles?.includes('Foglight Cloud Owner') &&
        !roles?.includes('Foglight Cloud Operator') &&
        !roles?.includes('Foglight Cloud Administrator')
      ) {
        this.isCloudUser = true;
        this.items.forEach((item) => {
          item.disabled = true;
          item.tooltipText =
            'To download an Agent Manager, contact your Foglight Cloud Administrator to get additional roles assigned to your account';
        });
      }
    });
  }

  setCurrentTab(tabName: string, title: string) {
    this.titleService.setTitle(`${title} - Foglight`);

    localStorage.setItem('currentTab', tabName);
    this.router.navigate(['/notification-management/' + tabName]);
  }

  /**
   * Checking FglamClientInstance to show download link
   */
  getFglamClientInstance() {
    if (this.isCloud) {
      this.checkFglamClientInstanceForCloud();
    } else {
      this.checkFglamClientInstanceForOnPrem();
    }
  }
  checkFglamClientInstanceForCloud() {
    this.homePageHttpService
      .getFglamClientInstance()
      .subscribe((fglamInstance) => {
        this.showWelcomePage = true;

        if (fglamInstance && fglamInstance.length < 2) {
          this.isExternalfglamRequired = true;
        }
        this.cd.markForCheck();
      });
  }

  checkFglamClientInstanceForOnPrem() {
    this.homePageHttpService
      .checkIsExternalFglamClientInstanceExists()
      .then((isExternalFglamClientInstanceExists) => {
        this.showWelcomePage = true;

        if (isExternalFglamClientInstanceExists === false)
          this.isExternalfglamRequired = true;
        this.cd.markForCheck();
      });
  }

  goToAgentMangers() {
    this.router.navigateByUrl(
      'wcf?name=general-view-aui-wrapper&viewId=system:administration_agents_agenthost.agentHosts'
    );
  }
  goToVideo() {
    window.open(
      'https://docs.foglightcloud.com/gettingstartedfoglightcloud/installationoffoglightagentmanager/video',
      '_blank'
    );
  }

  redirectTo(url, title: string) {
    this.titleService.setTitle(`${title} - Foglight`);

    this.router.navigateByUrl(url);
  }
  redirectToAUI(url, title) {
    this.titleService.setTitle(`${title} - Foglight`);
    this.router.navigate([url]);
  }
  learnMoreClick(url: string) {
    window.open(url, '_blank');
  }

  redirectToMonitor(
    viewId: string,
    instanceType: string,
    monitorDomain: string,
    aui: boolean = false
  ) {
    if (this.isCloud) {
      if (aui) {
        this.subscriptionPendingService.checkExitingSubscription(
          viewId,
          instanceType,
          monitorDomain,
          true
        );
      } else {
        this.subscriptionPendingService.checkExitingSubscription(
          viewId,
          instanceType,
          monitorDomain,
          false
        );
      }
    } else {
      if (aui) {
        this.redirectToAUI(viewId, monitorDomain);
      } else {
        this.redirectTo(
          'wcf?name=general-view-aui-wrapper&viewId=' + viewId,
          monitorDomain
        );
      }
    }
  }

  onItemSelect(item, menu?): any {
    if (menu) {
      menu.toggle(item);
    }
    this.navbarService.getListofDownloadableFglams().subscribe((res) => {
      if (item.label === 'Foglight Agent Manager for Windows') {
        let fileId = this.getVersionFilename(res, 'FglAM', 'windows');
        window.open(
          `${window.location.origin}/console/installer/download?downloadId=${fileId}`,
          '_blank'
        );
      } else {
        let fileId = this.getVersionFilename(res, 'FglAM', 'linux');
        window.open(
          `${window.location.origin}/console/installer/download?downloadId=${fileId}`,
          '_blank'
        );
      }
    });
  }

  getVersionFilename(objects: any[], fileName: string, os: string): string {
    const object = objects.find(
      (obj) => obj.name.includes(fileName) && obj.os === os
    );
    if (object) {
      return object.id;
    } else {
      return '';
    }
  }

  onDropdownShow() {
    this.arrowState = !this.arrowState;
  }
  onDropdownHide(menu?) {
    this.arrowState = !this.arrowState;
    this.selectedItem = {};
    this.agentButton.nativeElement.blur();
  }
}
