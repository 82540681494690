/*
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import { createAction, props } from "@ngrx/store";
import { CloudUser, CloudUserOperation, CloudUserOperationResult } from "../users-management.model";

// get all users action

export const getAllUsers = createAction(
    "[Users Management] Get All Users"
)

// set all users action

export const setAllUsers = createAction(
    "[Users Management] Set All Users", 
    props<{users: CloudUser[]}>()
)

// invite users actions

export const inviteUsers = createAction(
    "[Users Management] Invite User", 
    props<{inviteUsersOperation}>()
)

export const setUsersAfterInvite = createAction(
    "[Users Management] Set Users After Invite", 
    props<{inviteUsersOperationResult: CloudUserOperationResult}>()
)

// delete users invites actions

export const deleteUserInvites = createAction(
    "[Users Management] delete User invites", 
    props<{deleteUserInvitesOperation}>()
)

export const setUsersAfterDelete = createAction(
    "[Users Management] Set Users After Delete", 
    props<{deleteUsersOperationEmails: any}>()
)

// Assign roles to users actions

export const assignUsersRole = createAction(
    "[Users Management] Assign users Role", 
    props<{assignUsersRoleOperation}>()
)

// export const setUsersAfterRoleChange = createAction(
//     "[Users Management] Set Users After Role Change", 
//     props<{roleChangeOperationResult: CloudUserOperationResult}>()
// )

// Cancel users invites actions

export const cancelUsersInvite = createAction(
    "[Users Management] Cancel Users Invite", 
    props<{cancelInviteUsersOperation}>()
)

// export const setUsersAfterCancel = createAction(
//     "[Users Management] Set Users After Cancel", 
//     props<{cancelUsersInviteOperationResult: CloudUserOperationResult}>()
// )

// Reinvite users actions

export const ReinviteUsers = createAction(
    "[Users Management] Reinvite users", 
    props<{ReinviteUsersOperation}>()
)

// export const setUsersAfterReinvite = createAction(
//     "[Users Management] Set Users After Reinvite", 
//     props<{ReinviteUsersOperationResult: CloudUserOperationResult}>()
// )