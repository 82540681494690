/*
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { AnimationBuilder } from '@angular/animations';
import { MediaObserver } from '@angular/flex-layout';
import { SidebarService } from '../../services/sidebar.service';
import { MatchMediaService } from '../../services/match-media.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { NavItem } from '../../models/nav-item';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'fc-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, OnDestroy {
  private _shouldRedirect = true;
  isHover: boolean;

  /**
   * Constructor
   *
   * @param {AnimationBuilder} _animationBuilder
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {ElementRef} _elementRef
   * @param {MatchMediaService} _matchMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {Renderer2} _renderer
   * @param {SidebarService} sidebarService
   */
  constructor(
    private _animationBuilder: AnimationBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _matchMediaService: MatchMediaService,
    private _mediaObserver: MediaObserver,
    private _renderer: Renderer2,
    public sidebarService: SidebarService,
    private router: Router
  ) {
    // Set the defaults
    this.foldedWidth = 40;
    this.opened = false;

    // Set the private defaults
    this._animationsEnabled = false;
    this._folded = false;

    this.isHover = false;
  }

  @Output() temporaryUnfold = new EventEmitter();
  @Output() temporaryFold = new EventEmitter();

  /**
   * Folded
   *
   * @param {boolean} value
   */
  @Input()
  set folded(value: boolean) {
    // Set the folded
    this._folded = value;

    // Return if the sidebar is closed
    if (!this.opened) {
      return;
    }

    // Programmatically add/remove padding to the element
    // that comes after or before based on the position
    let sibling;
    let styleRule;

    const styleValue = this.foldedWidth + 'px';

    sibling = this._elementRef.nativeElement.nextElementSibling;
    // styleRule = 'padding-left';

    // If there is no sibling, return...
    if (!sibling) {
      return;
    }

    // If folded...
    if (value) {
      // setTimeout(() => {
      //   this._foldAndSetSiblingsStyle(sibling, styleValue, styleRule);
      // }, 200);
    }
    // If unfolded...
    else {
      // Unfold the sidebar
      this.unfold();

      // Remove the folded width
      this._renderer.removeStyle(this._elementRef.nativeElement, 'width');
      this._renderer.removeStyle(this._elementRef.nativeElement, 'min-width');
      this._renderer.removeStyle(this._elementRef.nativeElement, 'max-width');

      // Remove the style and class
      // this._renderer.removeStyle(sibling, styleRule);
      this._renderer.removeClass(this._elementRef.nativeElement, 'folded');
    }
  }

  get folded(): boolean {
    return this._folded;
  }

  // Public
  sidebarError = false;
  isLoading = true;
  pinned = false;
  // Name
  @Input()
  name: string;

  // Open
  @HostBinding('class.open')
  opened: boolean;

  // Locked Open
  @Input()
  lockedOpen: string;

  // isLockedOpen
  @HostBinding('class.locked-open')
  isLockedOpen: boolean;

  // Folded width
  @Input()
  foldedWidth: number;

  // Folded unfolded
  @HostBinding('class.unfolded')
  unfolded: boolean;

  @HostBinding('class.fc-sidebar-overlay')
  sidebarOverlay: boolean;

  // Private
  private _folded: boolean;
  private _wasActive: boolean;
  private _wasFolded: boolean;

  @HostBinding('class.animations-enabled')
  private _animationsEnabled: boolean;
  sidebarStateFlag = false;
  /**
   * On init
   */
  ngOnInit(): void {
    this._getMenuItems();

    this.sidebarService.menuShouldBeFolded$
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe((sbState) => {
        sbState == true ? this.foldTemporarily() : this.unfoldTemporarily();
      });
    const windowInnerWidth = window.innerWidth;
    const sidebarState = localStorage.getItem('sidebarState');
    if (sidebarState && JSON.parse(sidebarState)) {
      this.sidebarStateFlag = true;
      this.setupSidebarByState();
    } else if (windowInnerWidth >= 1900) {
      //The large resolution should have left nav panel opened by default
      this.sidebarStateFlag = true;
      this.setupSidebarByState();
      localStorage.removeItem('sidebarState');
    } else {
      // Setup visibility
      this._setupVisibility();

      // Setup position
      this._setupPosition();

      // Setup lockedOpen
      this._setupLockedOpen();

      // Setup folded
      this._setupFolded();
    }

    // set width according to current session
    this.setSidebarWidthAccordingToSessionStorage();
  }

  setSidebarWidthAccordingToSessionStorage() {
    if (localStorage.getItem('sidebarWidth')) {
      const sidebarWidth = localStorage.getItem('sidebarWidth');
      this._renderer.setStyle(
        this._elementRef.nativeElement,
        'width',
        sidebarWidth
      );
    }
  }

  setupSidebarByState(): void {
    this.onPinClick();
    this._setupLockedOpen();
    this.sidebarService.setFoldedState(this.folded);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // If the sidebar is folded, unfold it to revert modifications
    if (this.folded) {
      this.unfold();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setup the visibility of the sidebar
   *
   * @private
   */
  private _setupVisibility(): void {
    // Remove the existing box-shadow
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'box-shadow',
      'none'
    );

    // Make the sidebar invisible
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'visibility',
      'hidden'
    );
  }

  /**
   * Setup the sidebar position
   *
   * @private
   */
  private _setupPosition(): void {
    this._renderer.addClass(this._elementRef.nativeElement, 'left-positioned');
  }

  /**
   * Setup the lockedOpen handler
   *
   * @private
   */
  private _setupLockedOpen(): void {
    // Return if the lockedOpen wasn't set
    if (!this.lockedOpen) {
      // Return
      return;
    }

    // Set the wasActive for the first time
    this._wasActive = false;

    // Set the wasFolded
    this._wasFolded = this.folded;

    // Show the sidebar
    this._showSidebar();

    // Act on every media change
    this._matchMediaService.onMediaChange
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        // Get the active status
        const isActive = this._mediaObserver.isActive(this.lockedOpen);

        // If the both status are the same, don't act
        if (this._wasActive === isActive) {
          return;
        }

        // Activate the lockedOpen
        if (isActive) {
          // Set the lockedOpen status
          this.isLockedOpen = true;

          // Show the sidebar
          this._showSidebar();

          // Force the the opened status to true
          this.opened = true;

          // If the sidebar was folded, forcefully fold it again
          if (this._wasFolded) {
            // Enable the animations
            this._enableAnimations();

            // Fold
            this.folded = true;

            // Mark for check
            this._changeDetectorRef.markForCheck();
          }
        }
        // De-Activate the lockedOpen
        else {
          // Set the lockedOpen status
          this.isLockedOpen = false;

          // Unfold the sidebar in case if it was folded
          this.unfold();

          // Force the the opened status to close
          this.opened = false;

          // Hide the sidebar
          this._hideSidebar();
        }

        // Store the new active status
        this._wasActive = isActive;
      });
  }

  /**
   * Setup the initial folded status
   *
   * @private
   */
  private _setupFolded(): void {
    // Return, if sidebar is not folded
    if (!this.folded) {
      return;
    }

    // Return if the sidebar is closed
    if (!this.opened) {
      return;
    }

    // Programmatically add/remove padding to the element
    // that comes after or before based on the position
    let sibling;
    let styleRule;

    const styleValue = this.foldedWidth + 'px';

    sibling = this._elementRef.nativeElement.nextElementSibling;
    // styleRule = 'padding-left';

    // If there is no sibling, return...
    if (!sibling) {
      return;
    }

    this._foldAndSetSiblingsStyle(sibling, styleValue, styleRule);
  }

  /**
   * Change some properties of the sidebar
   * and make it visible
   *
   * @private
   */
  private _showSidebar(): void {
    // Remove the box-shadow style
    this._renderer.removeStyle(this._elementRef.nativeElement, 'box-shadow');

    // Make the sidebar invisible
    this._renderer.removeStyle(this._elementRef.nativeElement, 'visibility');

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Change some properties of the sidebar
   * and make it invisible
   *
   * @private
   */
  private _hideSidebar(): void {
    // Add a delay so close animation can play
    setTimeout(() => {
      // Remove the box-shadow
      this._renderer.setStyle(
        this._elementRef.nativeElement,
        'box-shadow',
        'none'
      );

      // Make the sidebar invisible
      this._renderer.setStyle(
        this._elementRef.nativeElement,
        'visibility',
        'hidden'
      );
    }, 100);

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Enable the animations
   *
   * @private
   */
  private _enableAnimations(): void {
    // Return if animations already enabled
    if (this._animationsEnabled) {
      return;
    }

    // Enable the animations
    this._animationsEnabled = true;

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  private _disableAnimations(): void {
    // Return if animations already disabled
    if (!this._animationsEnabled) {
      return;
    }

    // Disable the animations
    this._animationsEnabled = false;

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  private _foldAndSetSiblingsStyle(
    sibling: Element,
    styleValue: string,
    styleRule: string
  ) {
    // Fold the sidebar
    this.fold();

    // Set the folded width
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'width',
      styleValue
    );
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'min-width',
      styleValue
    );
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'max-width',
      styleValue
    );

    // Set the style and class
    this._renderer.setStyle(sibling, styleRule, styleValue);
    this._renderer.addClass(this._elementRef.nativeElement, 'folded');
  }

  private _redirectDefaultLink(menuItems: NavItem[], homepage: NavItem) {
    if (!this._shouldRedirect) {
      return;
    }
    this._shouldRedirect = false;
    // Redirect to alarm template for development environment.
    if (!environment.production) {
      this.sidebarService.redirectLink$.next('/alarm-template/default');
      return;
    }
    // Try to redirect to default link (or homepage if exists) in production environment.
    if ((this.router.url === '/' && environment.production) || (this.router.url === '/home' && environment.production)) {
      if(homepage.visible && homepage.link == 'wcf?name=general-view-aui-wrapper&viewId=system:administration_home.159'){
        this.router.navigateByUrl('home');
        return;
      }
      else if (homepage.visible && homepage.link) {
        this.router.navigateByUrl(homepage.link);
        return;
      }
      const link = this._findMenuLink(menuItems);
      this.sidebarService.redirectLink$.next(link);
    }
  }

  private _findMenuLink(menuItems: NavItem[]): string | null {
    for (const index in menuItems) {
      const item = menuItems[index];
      if (item.link && item.visible) {
        return item.link;
      }
      const link = this._findMenuLink(item.children);
      if (link) {
        return link;
      }
    }
    return null;
  }

  private _getMenuItems() {
    this.sidebarService
      .getSidebarItems()
      .pipe(untilDestroyed(this))
      .subscribe(
        (items: any) => {
          const menuItems = items.menus;
          // menuItems.push({id:'notification-management',displayName:'Notification Management',link:'notification-management',description: null, visible:true,children:[],icon:null,iconName:'fg-notification-management',isExpanded:false,parent:undefined});
          localStorage.setItem('homePage', JSON.stringify(items.homepage));
          this._redirectDefaultLink(menuItems, items.homepage);

          if (menuItems && menuItems.length > 0) {
            this.sidebarService.updateNavItems(menuItems);
          }

          const bookmarksItem = items.bookmark;

          if (bookmarksItem && bookmarksItem.children.length > 0) {
            this.sidebarService.updateBooksmarksItem(bookmarksItem);
          }
          if (items?.configurationItem?.children) {
            this.sidebarService.updateConfigItems(
              items.configurationItem.children
            );
          }
        },
        () => {
          this.sidebarError = true;
        },
        () => {
          this.isLoading = false;
          this._changeDetectorRef.markForCheck();
        }
      );
  }

  /**
   * Mouseenter
   */
  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.sidebarService.menuShouldBeFolded$.next(false);
  }

  /**
   * Mouseleave
   */
  @HostListener('mouseleave')
  onMouseLeave(): void {
    let isHoverMenuTooltip: boolean;
    this.sidebarService.isHover$.subscribe(
      (data) => (isHoverMenuTooltip = data)
    );
    if (this.isHover || isHoverMenuTooltip) {
      this.isHover = false;
      isHoverMenuTooltip = false;
      this.sidebarService.updateHoverState(false);
    }
    this.sidebarService.menuShouldBeFolded$.next(true);
  }

  /**
   * Fold the sidebar permanently
   */
  fold(): void {
    // Only work if the sidebar is not folded
    if (this.folded) {
      return;
    }

    // Enable the animations
    this._enableAnimations();

    // Fold
    this.folded = true;

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Unfold the sidebar permanently
   */
  unfold(): void {
    // Only work if the sidebar is folded
    if (!this.folded) {
      return;
    }

    // Enable the animations
    this._enableAnimations();

    // Unfold
    this.folded = false;
    this.setSidebarWidthAccordingToSessionStorage();

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Toggle the sidebar fold/unfold permanently
   */
  toggleFold(): void {
    if (this.folded || this.sidebarStateFlag) {
      this.unfold();
    } else {
      this.fold();
    }
    // this.sidebarService.setFoldedState(this.folded);
  }

  /**
   * Fold the temporarily unfolded sidebar back
   */
  foldTemporarily(): void {
    // Only work if the sidebar is folded
    if (!this.folded) {
      return;
    }

    // Enable the animations
    this._enableAnimations();

    // Fold the sidebar back
    this.unfolded = false;
    this.sidebarOverlay = false;

    // Set the folded width
    const styleValue = this.foldedWidth + 'px';

    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'width',
      styleValue
    );
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'min-width',
      styleValue
    );
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'max-width',
      styleValue
    );

    this.temporaryFold.emit();

    this.sidebarService.setFoldedState(true);
    const event = new CustomEvent('sidebarIsPinned', { detail: this.pinned });
    window.dispatchEvent(event)
    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Unfold the sidebar temporarily
   */
  unfoldTemporarily(): void {
    // Only work if the sidebar is folded
    if (!this.folded) {
      return;
    }

    // Enable the animations
    this._enableAnimations();

    // Unfold the sidebar temporarily
    this.unfolded = true;
    this.sidebarOverlay = true;

    // Remove the folded width
    this._renderer.removeStyle(this._elementRef.nativeElement, 'width');
    this._renderer.removeStyle(this._elementRef.nativeElement, 'min-width');
    this._renderer.removeStyle(this._elementRef.nativeElement, 'max-width');

    this.temporaryUnfold.emit();

    this.sidebarService.setFoldedState(false);

    this.setSidebarWidthAccordingToSessionStorage();
    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Change sidebar pinned state (ignore mouseleave if true)
   */
  onPinClick(): void {
    this.pinned = !this.pinned;
    this.sidebarOverlay = this.pinned ? false : true;   
    sessionStorage.setItem('isPinned', '' + this.pinned);
    this.setSidebarState(this.pinned);
    this.sidebarService.pinnedState$.next(this.pinned);
    this.toggleFold();
    this.sidebarStateFlag = false;
    this._disableAnimations();
  }

  onHover() {
    this.isHover = true;
  }

  onConfigure() {
    this.pinned = true;
    this.sidebarService.setEditState(true);
    this.folded = false;
  }

  onEditSaveAndCancel(){
   this.pinned = (sessionStorage.getItem('isPinned') === 'true');
   this.folded = this.pinned ? false : true;
   this.setSidebarWidthAccordingToSessionStorage();
  }
  
  setSidebarState(isPinned: boolean): void {
    localStorage.setItem('sidebarState', isPinned.toString());
  }
}
