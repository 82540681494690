/**
 *QUEST SOFTWARE PROPRIETARY INFORMATION
 *
 *This software is confidential. Quest Software Inc., or one of its
 *subsidiaries, has supplied this software to you under terms of a
 *license agreement, nondisclosure agreement or both.
 *
 *You may not copy, disclose, or use this software except in accordance with
 *those terms.
 *
 *
 *Copyright 2023 Quest Software Inc.
 *ALL RIGHTS RESERVED.
 *
 *QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
 *WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
 *EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 *TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
 *FITNESS FOR A PARTICULAR PURPOSE, OR
 *NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
 *LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
 *AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
 *THIS SOFTWARE OR ITS DERIVATIVES.
 */
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { isCloudFlagSelector } from 'src/app/store/app.selectors';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'fc-subscription-pending',
  templateUrl: './subscription-pending.component.html',
  styleUrls: ['./subscription-pending.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionPendingComponent implements OnInit {
  isCloudFlag: boolean;
  theme: string;
  instanceType: string;
  monitorDomain: string;
  constructor(
    private router: Router,
    private store: Store,
    public dialogRef: MatDialogRef<SubscriptionPendingComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('theme')) {
      this.theme = localStorage.getItem('theme');
    }
    this.instanceType = this.data['instanceType'];
    this.monitorDomain = this.data['monitorDomain'];

    this.setCloudFlag();
  }

  setCloudFlag() {
    this.store
      .select(isCloudFlagSelector)
      .pipe(untilDestroyed(this))
      .subscribe((isCloud) => {
        this.isCloudFlag = isCloud;
      });
  }

  goToAccountSettings() {
    this.router.navigate(['/settings/account-settings']);
    this.closeDialog();
  }

  supportOnClick() {
    if (this.isCloudFlag) {
      window.open('https://support.quest.com/foglight-cloud');
    } else {
      window.open(
        `${environment.foglightBaseUrl}/console/page/system:administration_setupsupport_supportbundles.supportDashboard`
      );
    }
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
