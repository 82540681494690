/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { AlarmTemplateData } from '../_models/alarm-template';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { ListDataRefresher } from '@foglight/angular-common';
import { MockDataStoreService } from './mock-data-store.service';
import { AlarmTemplateHttpService } from 'src/app/views/alarm-template/_services/alarm-template-http.service';
import { DomainMappingPipe } from '../alarm-template-content/domain-mapping.pipe';

@Injectable()
export class AlarmTemplateDataRefresherService extends ListDataRefresher<AlarmTemplateData[], AlarmTemplateData, string> {
  searchFilter$ = new BehaviorSubject<string>(null);
  totalAlarms$ = new BehaviorSubject(0);
  totalConnection$ = new BehaviorSubject(0);
  tabsSelectIndex$ = new BehaviorSubject(0);
  currentAlarmTemplate$ = new BehaviorSubject<AlarmTemplateData>(null);
  domain$ = new BehaviorSubject<string>(null);
  alarmTemplateId$ = new BehaviorSubject<string>(null);
  softRefresh$ = new Subject();
  duplicateFactoryTemplate$ = new Subject();
  updateDomainTabDefaultIcons$ = new Subject<AlarmTemplateData>();
  currentlySelectedButton = 0;

  constructor(
    private mockDataStoreService: MockDataStoreService,
    private httpService: AlarmTemplateHttpService,
  ) {
    super();
  }

  protected get isMockData(): boolean {
    return false;
  }

  protected filterRowDataHandler(data: AlarmTemplateData, filters: string): boolean {
    return data.name.toLowerCase().includes(filters.toLowerCase());
  }

  protected mockSourceData(): Observable<AlarmTemplateData[]> {
    return of([]);
  }

  protected parsedData(data: AlarmTemplateData[]): AlarmTemplateData[] {
    return this._sortAlarmTemplates(this.sortDomains(data));
  }

  sortData() {
    this.sortDomains(this.sourceData$.value);
  }

  sortParsedData() {
    this.parsedData(this.sourceData$.value);
  }

  private _sortAlarmTemplates(data: AlarmTemplateData[]): AlarmTemplateData[] {
    const compareFunc = (a: AlarmTemplateData, b: AlarmTemplateData): number => {
      if (a.isFactory || b.isFactory) {
        return a.isFactory ? -1 : 1;
      }
      return a.name.localeCompare(b.name);
    };
    return data?.sort(compareFunc);
  }

  sortDomains(data: AlarmTemplateData[]): AlarmTemplateData[] {
    if (data === null) return;
    data.forEach(it => {
      this.sortDomain(it);
    });
    return data;
  }

  sortDomain(data: AlarmTemplateData) {
    const pipe = new DomainMappingPipe();
    data.domainTemplates = data.domainTemplates.sort((a, b) =>
      b.isDefault && !a.isDefault ? 1 : pipe.transform(a.name).localeCompare(pipe.transform(b.name))
    );
  }

  addAlarmTemplate(value: AlarmTemplateData) {
    const data = this._sortAlarmTemplates(
      [...this.sourceData$.value, value]
    );
    this.updateAllData(data);
  }

  protected sourceData(): Observable<AlarmTemplateData[]> {
    return this.httpService.getAlarmTemplateData();
  }
}
