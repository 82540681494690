<!--
-QUEST SOFTWARE PROPRIETARY INFORMATION
-*
-This software is confidential. Quest Software Inc., or one of its
-subsidiaries, has supplied this software to you under terms of a
-license agreement, nondisclosure agreement or both.
-*
-You may not copy, disclose, or use this software except in accordance with
-those terms.
-*
-*
-Copyright 2023 Quest Software Inc.
-ALL RIGHTS RESERVED.
-*
-QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
-WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
-EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
-TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
-FITNESS FOR A PARTICULAR PURPOSE, OR
-NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
-LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
-AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
-THIS SOFTWARE OR ITS DERIVATIVES.
-->
<div class="usage-statistics" *ngIf="showUsage">
  <div class="content-heading">Usage Statistics</div>
  <ng-container *ngIf="isSubscribe">
    <div class="tabs">
      <fac-tab-line
        [tabItems]="tabList"
        (tabClick)="onTabClick($event)"
      ></fac-tab-line>
    </div>
    <div class="months-list-container">
      <div class="months">
        <fac-input-dropdown
          [appearance]="'bordernone'"
          [items]="monthsList"
          [selectedItem]="selectedMonth"
          (onSelectedItem)="onMonthChange($event)"
        ></fac-input-dropdown>
      </div>
    </div>
    <div class="usage-chart-container">
      <fac-chart [chartOpt]="chartLine"></fac-chart>
    </div>
  </ng-container>
  <ng-container *ngIf="!isSubscribe">
    <div class="usage-chart-container">
      <div class="no-data-container">
        <div class="no-found">
          <mat-icon
            svgIcon="fac-chart"
            *ngIf="!isLargeScreen"
            class="chat-mat-icon"
          ></mat-icon>
          <mat-icon
            svgIcon="fac-empty-chart"
            *ngIf="isLargeScreen"
            class="chat-mat-icon"
          ></mat-icon>
        </div>
      </div>
      <div class="no-data">
        <span class="no-data-label"> We have no data to show</span>
      </div>
    </div>
  </ng-container>
</div>
