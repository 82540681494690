/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/



import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { FcIconRegistry } from '../shared/icon';
import { IconRegistryItem } from '../icon-registry-config';
import { APP_BASE_HREF } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryInitService {
  constructor(private iconService: FcIconRegistry, private domSanitize: DomSanitizer,
              @Inject(APP_BASE_HREF) private baseHref: string) {
    this.iconService.setDefaultFontSetClass('far');
  }

  init(registry: IconRegistryItem[]) {
    if (!Array.isArray(registry)) {
      throw new TypeError('Invalid input for IconRegistryInitService#init');
    }

    registry.forEach(item => {
      if (item.literal) {
        if (item.namespace) {
          this.iconService.addSvgIconLiteralInNamespace(
            item.namespace,
            item.name,
            this.domSanitize.bypassSecurityTrustHtml(item.literal),
          );
        } else {
          this.iconService.addSvgIconLiteral(item.name, this.domSanitize.bypassSecurityTrustHtml(item.literal));
        }
      }

      if (item.url) {
        if (item.namespace) {
          this.iconService.addSvgIconInNamespace(
            item.namespace,
            item.name,
            this.domSanitize.bypassSecurityTrustResourceUrl(item.url),
          );
        } else {
          this.iconService.addSvgIcon(item.name,
            this.domSanitize.bypassSecurityTrustResourceUrl(`${this.baseHref}/${item.url}`));
        }
      }
    });
  }
}
