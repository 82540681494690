/**
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/


export interface SubscriptionData {
    subscriptions:SubscriptionsInfo[]
}

export interface SubscriptionsPayLoad {
    usageStartTime?: string,
    usageEndTime?: string
}
  
export interface SubscriptionsInfo {
    name?: string,
    expirationDate?: string,
    committedQuantity?: number,
    usage?:UsageInfo[]
}

export interface UsageInfo {
    date: string,
    quantity: number
}

export interface SubscriptionDisplayDetails{
    name?: string,
    type?:string,
    expirationDate?: string,
    dayLeft?:number,
    committedQuantity?: number,
    overageQuantity?:number,
    activeStatus?:string,
    isSubscribe?:boolean
}

export interface expiredSoonSubscription {
    type?:string,
    dayLeft?:number
}

export const SubscriptionTypes:string[]=['Databases','Infrastructure']

