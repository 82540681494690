/*
*QUEST SOFTWARE PROPRIETARY INFORMATION
*
*This software is confidential. Quest Software Inc., or one of its
*subsidiaries, has supplied this software to you under terms of a
*license agreement, nondisclosure agreement or both.
*
*You may not copy, disclose, or use this software except in accordance with
*those terms.
*
*
*Copyright 2023 Quest Software Inc.
*ALL RIGHTS RESERVED.
*
*QUEST SOFTWARE INC. MAKES NO REPRESENTATIONS OR
*WARRANTIES ABOUT THE SUITABILITY OF THE SOFTWARE,
*EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
*TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
*FITNESS FOR A PARTICULAR PURPOSE, OR
*NON-INFRINGEMENT. QUEST SOFTWARE SHALL NOT BE
*LIABLE FOR ANY DAMAGES SUFFERED BY LICENSEE
*AS A RESULT OF USING, MODIFYING OR DISTRIBUTING
*THIS SOFTWARE OR ITS DERIVATIVES.
*/

import { Injectable} from '@angular/core';
import { Store } from '@ngrx/store';
import { isCloudFlagSelector, isCloudUser, isSaasAcceptedSelector } from '../store/app.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { skipEmpty } from '../shared/utils/skip-empty.operator';

@UntilDestroy()
@Injectable()
export class LayoutService {

private _showSidebar :BehaviorSubject<boolean>= new BehaviorSubject<boolean>(false);
public showSideBar = this._showSidebar.asObservable();

  constructor(private store: Store) {}

  public verifyCloudAndSaasAcceptance(){    
    this.store.select(isCloudFlagSelector).pipe(untilDestroyed(this)).subscribe((isCloud)=>{       
        if(isCloud){ 
          this.store.select(isCloudUser).pipe(untilDestroyed(this)).subscribe((cloudUser)=>{            
            if(cloudUser){              
              this.store.select(isSaasAcceptedSelector).pipe(untilDestroyed(this),skipEmpty()).subscribe((saasAccepted)=>{                        
                this._showSidebar.next(saasAccepted); 
              })
            }
            else{              
            this._showSidebar.next(true);
            }
          })                 
        }
        else{                      
         this._showSidebar.next(true);                     
        }
     })
  }
 

}

